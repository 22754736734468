import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, Spinner} from '../../../../components'
import ApprovalLog from '../../ApprovalLog'
import DetailHeader from '../../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../../models/SentralModel';
import {toFullDate} from '../../../../utils/dateHandler'

const {Text} = Typography

const Detail = () => {
    const {requestId} = useParams()
    const [leaves, setLeaves] = useState(null)

    const fetchLeaves = (id) => {
       SentralModel.get('SubmissionLeave', {}, id).then((res)=>{
           setLeaves(res.data)
       })
    }

    useEffect(() => {
        fetchLeaves(requestId)
    }, [requestId]);

    const statusUpdated = () =>{
        setLeaves(null)
        fetchLeaves(requestId)
        window.location.reload();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Leave Request" breadcrumbs={[['Submission List','/submission-list'],['Detail']]}></PageTitle>
            </div>
            {
                (leaves) ? 
                <div>
                    <DetailHeader 
                        name={leaves.employee.name} 
                        date={leaves.created_at}
                        status={leaves.cancellation_status}/>
                    <Card bordered  className="mb-4">
                        <Text strong>LEAVE DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{leaves.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{leaves.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{leaves.employee.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Leave Date</th> 
                                            <td>
                                                {
                                                    (leaves.total_days===1) ? toFullDate(leaves.from_date) : <span>{toFullDate(leaves.from_date)} - {toFullDate(leaves.to_date)}</span>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Leave Type</th><td>{leaves.leave.description}</td>
                                        </tr>
                                        <tr>
                                            <th>Leave Information</th><td>{leaves.information.description}</td>
                                        </tr>
                                        <tr>
                                            <th>Necessity</th><td>{leaves.reason}</td>
                                        </tr>
                                        <tr>
                                            <th>Responsible Employee</th><td>{(leaves.responsible) ? leaves.responsible.name : '-'}</td>
                                        </tr>
                                        <tr>
                                            <th>Attachment</th>
                                            <td>
                                                {
                                                    (leaves.attachment) ?
                                                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE+'/'+leaves.attachment}>
                                                        Show Attachment
                                                    </a> : '-'
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Cancellation Reason</th><td>{leaves.cancellation_reason}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="LEAVECANCELLATION" 
                            requestId={leaves.sub_leave_id} 
                            approvers={leaves.approver_cancellation}
                            onSubmitted={() => statusUpdated()}/>
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
