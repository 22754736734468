import { useEffect } from 'react'
import { DataTable, EditButton, ShowButton } from '../../../../components';
import { Button, Row, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { toFullDate } from '../../../../utils/dateHandler';
import { useDispatch, useSelector } from 'react-redux';
import {
    addDailyActivity,
    editDaily,
    editDailyInfra,
    fetchCompany,
    fetchDailyActivities,
    fetchMeasurement
} from '../../../../redux/slices/Dashboard/myDailyActivitySlice';
import moment from 'moment';
import ModalEditProgressDaily from './Modal/DailyActivity/ModalEditDaily';
import ModalAddProgress from './Modal/DailyActivity/ModalAddProgress';
import ModalAddDaily from './Modal/DailyActivity/ModalAddDaily';
import currentUser from '../../../../helpers/authData';
import ModalAddDailyInfra from './Modal/DailyActivity/ModalAddDailyInfra';
import ModalEditProgressDailyInfra from './Modal/DailyActivity/ModalEditDailyInfra';

const MyDailyActivity = () => {
    const dispatch = useDispatch()
    const loaded = useSelector((state) => state.myDailyActivity.loaded)
    const datas = useSelector((state) => state.myDailyActivity.datas)
    const showModalAddDailyActivity = useSelector((state) => state.myDailyActivity.showModalAddDailyActivity)
    const editProgressDailyModal = useSelector((state) => state.myDailyActivity.editProgressDailyModal)
    const editProgressDailyInfraModal = useSelector((state) => state.myDailyActivity.editProgressDailyInfraModal)
    const showModalAddProgress = useSelector((state) => state.myDailyActivity.showModalAddProgress)

    useEffect(() => {
        dispatch(fetchDailyActivities({ start_date: null, end_date: null }))
        dispatch(fetchMeasurement())
        dispatch(fetchCompany())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        },
        {
            title: 'Task Origin',
            dataIndex: 'data_from',
            key: 'data_from',
        },
        {
            title: 'Description',
            render: (row) => (
                row.description !== null ?
                    row.description.length > 150 ? `${row?.description.substring(0, 150)}...` : row?.description
                    : "-"
            ),
            key: 'description',
        },
        {
            title: 'Deadline',
            key: 'deadline',
            render: (row) => (
                toFullDate(row?.due_date)
            )
        },
        {
            title: 'Finished Date',
            render: (row) => (
                row?.finished_date === null || row?.finished_date === "0000-00-00 00:00:00" ? '-' : moment(row?.finished_date).format('DD MMMM YYYY')
            ),
            key: 'finished_date',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            filters: [
                {
                    text: 'In Progress',
                    value: 'In Progress'
                },
                {
                    text: 'Finished',
                    value: 'Finished'
                }
            ],
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    {
                        row?.status === "Finished" ?
                            <EditButton disabled onEdit={() => dispatch(editDaily({ data: row }))} />
                            :
                            row?.division_id === 25 || row?.division_id === 34 ?
                                <EditButton onEdit={() => dispatch(editDailyInfra({ data: row }))} />
                                :
                                <EditButton onEdit={() => dispatch(editDaily({ data: row }))} />
                    }
                    {
                        row?.division_id === 25 || row?.division_id === 34 ?
                            <ShowButton onShow={() => dispatch(editDailyInfra({ data: row, stat: 'show' }))} />
                            :
                            <ShowButton onShow={() => dispatch(editDaily({ data: row, stat: 'show' }))} />
                    }
                </Space>
            ),
            key: 'action'
        }
    ];

    return (
        <>
            <div>
                <Row style={{ margin: "5px 0 5px 0" }}>
                    <Button type="primary" color="#ededed" onClick={() => dispatch(addDailyActivity())} icon={<PlusCircleOutlined />}>
                        Add Daily
                    </Button>
                </Row>
                {
                    loaded ?
                        <DataTable columns={columns} dataSource={datas} bordered size="small" />
                        : null
                }
            </div>
            {
                showModalAddDailyActivity ?
                    (currentUser.division_id === 34 && (currentUser.position_id === 66 || currentUser.position_id === 123)) ?
                        <ModalAddDailyInfra /> :
                        <ModalAddDaily />
                    : null
            }

            {
                editProgressDailyModal ?
                    <ModalEditProgressDaily />
                    : null
            }

            {
                editProgressDailyInfraModal ?
                    <ModalEditProgressDailyInfra />
                    : null
            }

            {
                showModalAddProgress ?
                    <ModalAddProgress />
                    : null
            }

        </>
    )
}

export default MyDailyActivity