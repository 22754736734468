import React from 'react';
import {Card,Tabs} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {DataTable, ShowButton} from '../../../components'
import {toFullDate} from '../../../utils/dateHandler'

const {TabPane} = Tabs;

const Index = (props) => {
    const recruitmentColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },{
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject'
        },{
            title: 'Division',
            dataIndex: 'division',
            key: 'division'
        },{
            title: 'Position',
            dataIndex: 'position',
            key: 'position'
        },{
            title: 'Recruitment Date',
            render:(row) => (
                <div className="text-center">
                    {row.from_date} - {row.to_date}
                </div>
            )
        },{
            title: 'Requester',
            dataIndex: 'name',
            key: 'name',
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/recruitment/'+row.id} />
                </div>
            )
        }
    ];
    const recruitmentData = [
        ...props.data.map((el) => {
            return{
                id:el.request_id,
                name:el.name,
                subject:el.subject,
                division: el.rec_division_name,
                position: el.rec_position_name,
                from_date: toFullDate(el.from_date),
                to_date: toFullDate(el.to_date),
                status: el.status,
                submitted_at: el.submitted_at
            }
        })
    ]

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={recruitmentColumn} dataSource={recruitmentData.filter(el => el.status==='WAITING')} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={recruitmentColumn} dataSource={recruitmentData.filter(el => el.status==='APPROVED')} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={recruitmentColumn} dataSource={recruitmentData.filter(el => el.status==='REJECTED')} bordered/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
