import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, PrintPdfButton, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import {toIdr} from '../../../utils/currencyHandler'
import {toFullDate} from '../../../utils/dateHandler'

const {Text} = Typography

const Detail = () => {
    const {cashId} = useParams()
    const [cashAdvance, setCashAdvance] = useState(null)

    const fetchCashAdvance = (id) => {
       SentralModel.get('SubmissionCashAdvance', {}, id).then((res)=>{
           setCashAdvance(res.data)
       })
    }

    useEffect(() => {
        fetchCashAdvance(cashId)
    }, [cashId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/cash-advance/' + cashId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Cash Advance Realization" breadcrumbs={[['Cash Advance', '/cash-advance'], ['Realization Detail']]}></PageTitle>
            </div>
            {
                (cashAdvance) ? 
                <div>
                    <Row className="mb-3">
                        <Col span={24} >
                            <PrintPdfButton right onPrint={printData} />
                        </Col>
                    </Row>
                    <DetailHeader 
                        name={cashAdvance.employee.name} 
                        date={cashAdvance.created_at}
                        status={cashAdvance.status_realization}/>
                    <Card bordered className="mb-4">
                        <Text strong>CASH ADVANCE DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{cashAdvance.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{cashAdvance.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{cashAdvance.employee.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Date</th><td>{toFullDate(cashAdvance.from_date)} - {toFullDate(cashAdvance.to_date)}</td>
                                        </tr>
                                        <tr>
                                            <th>Purpose</th><td>{cashAdvance.purpose}</td>
                                        </tr>
                                        {/* <tr>
                                            <th>Destination</th><td>{(cashAdvance.type.detail_code==="TTD") ? cashAdvance.city.city_name : cashAdvance.country.country_name}</td>
                                        </tr> */}
                                        <tr>
                                            <th>Total Amount</th><td>{toIdr(cashAdvance.total_amount_realization)}</td>
                                        </tr>
                                        <tr>
                                            <th>Planning</th><td>{cashAdvance.plan}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered className="mb-4">
                        <Text strong>COST PLANNING</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Item Name</th>
                                            <th>Amount</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cashAdvance.costs.map((el, key) => (
                                                <tr key={key}>
                                                   <td>{el.description}</td>
                                                   <td>{toIdr(el.amount)}</td>
                                                   <td>{el.quantity}</td>
                                                   <td>{toIdr(el.total)}</td>
                                                </tr>
                                            ))
                                        }
                                        
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered className="mb-4">
                        <Text strong>COST REALIZATION</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Item Name</th>
                                            <th>Amount</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            cashAdvance.costs.map((el, key) => (
                                                <tr key={key}>
                                                   <td>{el.description}</td>
                                                   <td>{toIdr(el.amount_realization)}</td>
                                                   <td>{el.quantity_realization}</td>
                                                   <td>{toIdr(el.total_realization)}</td>
                                                </tr>
                                            ))
                                        }
                                        
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="CASHADVANCEREALIZATION" 
                            approvers={cashAdvance.approver_realization} />
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
