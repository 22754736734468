import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tabs, Typography, Space } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, Spinner, ShowButton } from '../../../../components'
import authData from '../../../../helpers/authData'
import { SentralModel } from '../../../../models/SentralModel'
import { toIdr } from '../../../../utils/currencyHandler'

const { Text } = Typography
const { TabPane } = Tabs;

const Index = () => {
    const [commissions, setCommissions] = useState(null)
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)

    const fetchCommission = () => {
        let payload = {
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionCommission', payload).then(res => {
            setCommissions(res.data)
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction', { filter: [['code', 'SBCM']] }, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission', 'checkSchema', { category: 'REIMBURSEMENT', division_id: authData.division_id }).then((res) => {
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchCommission()
        fetchInstruction()
        checkApprovalSchema()
    }, []);

    const commissionData = (commissions) ? [
        ...commissions.map(el => {
            return {
                id: el.sub_commission_id,
                description: el.title,
                total_amount: toIdr(el.total_amount),
                status: el.status,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ] : [];

    const rmbColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
    }, {
        title: 'Total Amount',
        dataIndex: 'total_amount',
        key: 'total_amount',
    }, {
        title: 'Action',
        align: 'center',
        render: (row) => (
            <Space>
                <ShowButton link to={'/commission/' + row.id} />
            </Space>
        )
    }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Commission" breadcrumbs={[['Commission']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Request Commission</Text>
                        <NewButton disabled={!available} right to="/commission/create" title={(available) ? "Request Commission" : 'Approval not available'} />
                    </div>
                }>
                    <Row>
                        <Col span={24} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                </Card>
                <br />
                {
                    (commissions) ?
                        <Tabs defaultActiveKey="waiting" type="card">
                            <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                                <DataTable size="small" columns={rmbColumn} dataSource={commissionData.filter(el => el.status === 'WAITING')} bordered />
                            </TabPane>
                            <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                                <DataTable size="small" columns={rmbColumn} dataSource={commissionData.filter(el => el.status === 'APPROVED')} bordered />
                            </TabPane>
                            <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                                <DataTable size="small" columns={rmbColumn} dataSource={commissionData.filter(el => el.status === 'REJECTED')} bordered />
                            </TabPane>
                        </Tabs>
                        : <Card><Spinner /></Card>
                }
            </Card>
        </div>
    );
}

export default Index;
