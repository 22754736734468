import React, { useEffect, useState } from 'react';
import '../../../assets/spt.css'
import { SentralModel } from '../../../models/SentralModel';
import {formatNumber} from '../../../utils/currencyHandler'
const SptNonFinal = (props) => {
    const {pemotong=null, period=null} = props
    const [spt, setSpt] = useState(null)
    const [employees, setEmployees] = useState([])

    const fetchSptDetail = (period) => {
        SentralModel.action('Spt','getSptDetail', {period:period}).then((res)=>{
            setSpt(res.data)
        })
    }

    const fetchEmployee = (period) => {
        SentralModel.action('Spt','getSptBulananList',{period_id:period},0).then((res) => {
            setEmployees(res.data)
        })
    }

    useEffect(() => {
        fetchSptDetail(period)
        fetchEmployee(period)
    }, [period]);

    const getTotal = (type) => {
        let total=0;
        employees.forEach(el => {
            total+=el.summary[type]
        })
        return formatNumber(total)
    }
    return (
        <div>
            <div style={{position:'relative', overflowX:'auto'}}>
                <img style={{width:1150, border:'1px solid #ededed'}} src={process.env.PUBLIC_URL+'/images/spt/tidakfinal.jpg'} alt="SPT Masa 1"/>
                <span className="spt-item-masa" style={{top:101,left:307}}>{(spt) ? spt.month_period : "-"}</span>
                <span className="spt-item-masa" style={{top:101,left:352}}>{(spt) ? spt.year_period : "-"}</span>

                <span className="spt-item-masa" style={{top:101,left:552}}>{pemotong.npwp_no.substring(0,12)}</span>
                <span className="spt-item-masa" style={{top:101,left:755}}>{pemotong.npwp_no.substring(13,16)}</span>
                <span className="spt-item-masa" style={{top:101,left:815}}>{pemotong.npwp_no.substring(17,20)}</span>
                {
                    employees.map((el, key) => (
                        <div key={key}>
                            <div className="spt-item-bulanan" style={{top:(210 + (key * 23)),left:65,width:'145px'}}>{el.employee.npwp_no}</div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key * 23)),left:213,width:'145px'}}>{el.employee.name}</div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key * 23)),left:380,width:'140px'}}></div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key * 23)),left:530,width:'140px'}}></div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key * 23)),left:675,width:'85px'}}>21-100-01</div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key * 23)),left:763,width:'135px'}}>{formatNumber(el.summary.bruto)}</div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key * 23)),left:918,width:'110px'}}>{formatNumber(el.summary.pph)}</div>
                            <div className="spt-item-bulanan" style={{top:(210 + (key * 23)),left:1075,width:'40px'}}>IND</div>
                        </div>
                    ))
                }
                

                <div className="spt-item-bulanan" style={{top:697,left:763,width:'135px'}}>{getTotal('bruto')}</div>
                <div className="spt-item-bulanan" style={{top:697,left:918,width:'110px'}}>{getTotal('pph')}</div>
            </div>
        </div>
    );
}

export default SptNonFinal;
