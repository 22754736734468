import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Card, Form, Input, Typography, Space, message, Select} from 'antd'
import {EditButton, DeleteButton, FormModal, AddButton, DataTable, Spinner} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'
import authData from '../../../../helpers/authData'

const {Text} = Typography
const {Option} = Select

const Index = (props) => {
    const {resignId} = useParams();
    const [loaded, setLoaded] = useState(false);
    const [witnessForm] = Form.useForm()
    const [title, setTitle]= useState(null)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)
    const [employees, setEmployees] = useState([]);

    const fetchEmployees = () => {
        setLoaded(false)
        SentralModel.list('Employee', {filter: [['employee_code', '!=', authData.employee_code], ['is_active','=','1']], orderBy: 'name', order: 'asc'}).then(res => {
            setLoaded(true)
            setEmployees(res.data)
        })
    }

    useEffect(() => {
        fetchEmployees();
    }, []);

    const dataSource = [
        ...props.data.map( el => {
            return {
                id: el.sub_resign_witness_id,
                sub_resign_id: el.sub_resign_id,
                employee_code: el.employee_code,
                employee: el.employee.name
            }
        })
    ];

    const dataColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
            width: '10%'
        },
        {
            title: 'Employee',
            dataIndex: 'employee',
            key: 'employee',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editData(row)} />
                        <DeleteButton onConfirm={() => deleteData(row.id)} />
                    </Space>
                </div>
            )
        }
    ];

    const addData = () => {
        setTitle('Add Witness')
        witnessForm.setFieldsValue({
            sub_resign_witness_id: 0,
            sub_resign_id: resignId,
        })
        setFormModal(true)
    }

    const editData = (v) => {
        setTitle('Edit Witness')
        witnessForm.setFieldsValue({
            sub_resign_witness_id: v.id,
            sub_resign_id: resignId,
            employee_code: v.employee_code,
        })
        setFormModal(true)
    }

    const saveData = (v) => {
        setSaving(true)
        let payload = {
            sub_resign_id: v.sub_resign_id,
            employee_code: v.employee_code,
        }
        SentralModel.save('SubmissionResignWitness', payload, v.sub_resign_witness_id).then((res) => {
            setFormModal(false)
            props.onFetch();
            message.success('Witness saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteData = (id) =>{
        message.loading('Deleting witness')
        SentralModel.deleteById('SubmissionResignWitness', id).then((res) => {
            message.destroy();
            message.success('Witness deleted', 2);
            props.onFetch();
        })
    }

    return (
        <div>
            <Card title={
                <div className="full-width">
                    <Text strong style={{float:'left'}}>Witness List</Text>
                    <AddButton right onAdd={addData} title="New Witness"/>
                </div> }>

                <DataTable size="small" columns={dataColumn} dataSource={dataSource} bordered/>
            </Card>

            {
                (loaded) ?
                    (formModal) ?
                        <FormModal form={witnessForm} title={title} submitForm={(v)=>saveData(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Form.Item name="sub_resign_witness_id" hidden>
                                <Input placeholder="Witness ID" />
                            </Form.Item>
                            <Form.Item name="sub_resign_id" hidden>
                                <Input placeholder="Resign ID" />
                            </Form.Item>
                            <Form.Item label="Employee" name="employee_code" rules={[{required:true}]}>
                                <Select showSearch allowClear placeholder="Select employee" optionFilterProp="children" style={{ width: "100%" }} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {
                                        employees.map((el, key) => (
                                            <Option key={key} value={el.employee_code}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </FormModal>
                    : null
                : <Spinner />
            }
        </div>
    );
}

export default Index;
