import { useState, useEffect } from 'react'
import { BasicModal, DataTable } from '../../../components'
import { Button, Card, Col, Divider, Row, Tag, Typography } from 'antd'
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import moment from 'moment'
import { useHistory } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

const { Title } = Typography

const Index = () => {
    const history = useHistory()
    const [projects, setProjects] = useState([])
    const [customerName, setCustomerName] = useState([])
    const [qtyData, setQtyData] = useState([])
    const [percentageData, setPercentageData] = useState([])
    const [ticketData, setTicketData] = useState([])
    const [modalTicket, setModalTicket] = useState(false)
    const [totalNoSn, setTotalNoSn] = useState(0)

    const fetchProjectData = () => {
        fetch(`${process.env.REACT_APP_BOOST_API}/dashboard-project`, {
            method: "GET",
            mode: 'cors',
        }).then((response) => response.json())
            .then((res) => {
                setProjects(res.data.datas)
                let arrCustomer = []
                let arrQty = []
                let arrPercentage = []
                const sum = res.data.devices.reduce((acc, o) => acc + parseInt(o.printer_qty), 0)
                res.data.devices.forEach((el) => {
                    el.percentage = parseFloat(((el.printer_qty / sum) * 100).toFixed(2))
                    arrCustomer.push(el.customer_name)
                    arrQty.push(el.printer_qty)
                    arrPercentage.push(parseFloat(((el.printer_qty / sum) * 100).toFixed(2)))
                })

                setCustomerName(arrCustomer)
                setQtyData(arrQty)
                setPercentageData(arrPercentage)
            })
    }

    useEffect(() => {
        fetchProjectData()

    }, [])

    const monthDiff = (d1, d2) => {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    const dataPercentage = {
        labels: customerName,
        datasets: [
            {
                label: 'Percentage Device',
                data: percentageData,
                backgroundColor: [
                    '#e74c3c',
                    '#e67e22',
                    '#f1c40f',
                    '#8e44ad',
                    '#2980b9',
                    '#27ae60',
                    '#34495e',
                    '#95a5a6'
                ],
                borderColor: [
                    '#e74c3c',
                    '#e67e22',
                    '#f1c40f',
                    '#8e44ad',
                    '#2980b9',
                    '#27ae60',
                    '#34495e',
                    '#95a5a6'
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataQty = {
        labels: customerName,
        datasets: [
            {
                label: 'Total Device',
                data: qtyData,
                backgroundColor: [
                    '#e74c3c',
                    '#e67e22',
                    '#f1c40f',
                    '#8e44ad',
                    '#2980b9',
                    '#27ae60',
                    '#34495e',
                    '#95a5a6'
                ],
                borderColor: [
                    '#e74c3c',
                    '#e67e22',
                    '#f1c40f',
                    '#8e44ad',
                    '#2980b9',
                    '#27ae60',
                    '#34495e',
                    '#95a5a6'
                ],
                borderWidth: 1,
            },
        ],
    };

    const onShowModalTicket = (ticket, no_sn) => {
        if (ticket.length > 0 || no_sn > 0) {
            setModalTicket(true)
        } else {
            setModalTicket(false)
        }

        setTicketData(ticket)
        setTotalNoSn(no_sn)
    }

    const getDatesBetween = (startDate, endDate) => {
        let totalDay = 0
        const currentDate = new Date(startDate.getTime());
        const dates = [];
        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        dates.forEach((el) => {
            if (new Date(el).getDay() !== 5 || new Date(el).getDay() !== 6) {
                totalDay++
            }
        })
        return totalDay;
    }

    const columns = [
        {
            title: "No",
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'Customer',
            key: 'customer',
            render: (row) => (
                row?.customer?.name ?? "-"
            )
        },
        {
            title: 'Expired Contract',
            key: 'expired_contract',
            render: (row) => (
                <>
                    <p>{moment(row?.end_date).format('DD MMMM YYYY')}</p>
                    {
                        <Tag color={monthDiff(new Date(), new Date(row?.end_date)) <= 0 ? "red" : "green"}>
                            {`${monthDiff(new Date(), new Date(row?.end_date))} Month`}
                        </Tag>
                    }
                </>
            ),
            align: 'center',
            width: '10%'
        },
        {
            title: 'Printer Qty',
            key: 'printer_qty',
            render: (row) => (
                <Tag color='green'>
                    {row?.project_device?.length}
                </Tag>
            ),
            align: 'center',
            width: '10%'
        },
        {
            title: 'Printer Problem',
            key: 'printer_problem',
            render: (row) => (
                <Tag style={{ cursor: 'pointer' }} onClick={() => onShowModalTicket(row?.ticket, row?.total_no_sn)} color={
                    (parseInt(row?.ticket?.length) + parseInt(row?.total_no_sn)) > 0 ? 'red' : 'green'
                } >
                    {parseInt(row?.ticket?.length) + parseInt(row?.total_no_sn)}
                </Tag >
            ),
            width: '5%',
            align: 'center'
        },
        {
            title: 'Printer Normal',
            key: 'printer_normal',
            render: (row) => (
                parseInt(row?.project_device?.length) - (parseInt(row?.ticket?.length) + parseInt(row?.total_no_sn))
            ),
            width: '10%',
            align: 'center'
        }
    ]

    const columnTicket = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'No Ticket',
            dataIndex: 'ticket_number',
            key: 'ticket_number'
        },
        {
            title: 'Serial Number',
            key: 'serial_number',
            render: (row) => (
                <ol>
                    {
                        row?.ticket_items.map((el, key) => (
                            <li key={key}>
                                {el.serial_number}
                            </li>
                        ))
                    }
                </ol>
            )
        },
        {
            title: 'SLA',
            key: 'sla',
            render: (row) => (
                getDatesBetween(row?.assign_at === null ? new Date(row?.created_at) : new Date(row?.assign_at), new Date(row?.target_completion))
            ),
            align: 'center'
        }
    ]

    return (
        <div>
            <Card style={{ margin: '15px 10px 0 10px' }} className="content-container">
                <Row justify='space-between' style={{ marginBottom: '10px' }}>
                    <Col span={6}>
                        <Card>
                            <div style={{ marginBottom: 30 }}>
                                <Title level={4}>DASHBOARD PROJECT BOOST</Title>
                            </div>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Row style={{ marginBottom: 10 }}>
                                <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/action-plan-home')}>ACTION PLAN</Button>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Button disabled style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => null}>DAILY ACTIVITY</Button>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/working-location-dashboard')}>WORKING LOCATION</Button>
                            </Row>
                            <Row style={{ marginBottom: 10 }}>
                                <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/digitalisasi-home')}>DIGITALISASI DOKUMEN</Button>
                            </Row>
                            <Row>
                                <Button style={{ width: 160, fontSize: 11, fontWeight: 'bold' }} type='primary' onClick={() => history.push('/dashboard-project')}>PROJECT BOOST</Button>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row justify='space-around' style={{ marginBottom: '20px' }}>
                    <Col span={7}>
                        <Card title="Total Percentage Device">
                            <Pie data={dataPercentage} />
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card title="Total Quantity Device">
                            <Pie data={dataQty} />
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <DataTable columns={columns} dataSource={projects} pagination={false} search={false} />
                </Row>

                {
                    modalTicket ?
                        <BasicModal title="Printer Problem" showModal={modalTicket} onCloseModal={() => setModalTicket(false)} width={700}>
                            <Row justify='space-around' style={{ marginBottom: 15 }}>
                                <Col span={11}>
                                    <Card style={{ background: '#e74c3c', borderRadius: 5 }}>
                                        <h1 style={{ fontSize: '20px', marginBottom: 5, color: 'white' }}>Problem By Ticket</h1>
                                        <Divider style={{ margin: 0, padding: 0, }} />
                                        <h1 style={{ fontSize: '20px', marginBottom: 0, color: 'white' }}>{ticketData.length} Device</h1>
                                    </Card>
                                </Col>
                                <Col span={11}>
                                    <Card style={{ background: '#f1c40f', borderRadius: 5 }}>
                                        <h1 style={{ fontSize: '20px', marginBottom: 5, color: 'white' }}>Printer No SN</h1>
                                        <Divider style={{ margin: 0, padding: 0 }} />
                                        <h1 style={{ fontSize: '20px', marginBottom: 0, color: 'white' }}>{totalNoSn} Device</h1>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    ticketData.length > 0 ?
                                        <DataTable columns={columnTicket} dataSource={ticketData} pagination={false} search={false} />
                                        : null
                                }
                            </Row>
                        </BasicModal>
                        : null
                }
            </Card>
        </div>
    )
}

export default Index