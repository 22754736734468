import React from 'react';
import {Badge, Card,Tabs, Tag} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {ShowButton, DataTable} from '../../../components'
import { toFullDate, formatDateTime } from '../../../utils/dateHandler';
import {toIdr} from '../../../utils/currencyHandler'

const {TabPane} = Tabs;

const Index = (props) => {

    const requestData = [
        ...props.data.requests.map(el =>{
            return{
                id: el.request_id,
                from_date: el.from_date,
                to_date: el.to_date,
                purpose: el.purpose,
                total_amount: toIdr(el.total_amount),
                status: el.status,
                created_at: el.created_at,
            }
        })
    ]

    const realizationData = [
        ...props.data.realizations.map(el =>{
            return{
                id: el.request_id,
                from_date: el.from_date,
                to_date: el.to_date,
                purpose: el.purpose,
                total_amount: toIdr(el.total_amount_realization),
                status: el.status,
                created_at: el.created_at,
            }
        })
    ]

    const getStatus = (status) => {
        if (status === "WAITING") {
            return <Tag style={{ color: '#808080', width: 120 }}>Waiting Approval</Tag>
        } else if (status === "APPROVED") {
            return <Tag color="green" style={{ width: 120 }}>APPROVED</Tag>
        } else if (status === "REJECTED") {
            return <Tag color="red" style={{ width: 120 }}>REJECTED</Tag>
        }
    }

    const requestColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },{
            title: 'Date',
            render: (row) => (
                <span>{toFullDate(row.from_date)} - {toFullDate(row.to_date)}</span>
            )
        },{
            title: 'Purpose',
            dataIndex: 'purpose',
            key: 'purpose',
        },{
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },{
            title: 'Request Date',
            dataIndex: 'created_at',
            render: (text) => (
                formatDateTime(text)
            )
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/cash-advance/'+row.id} />
                </div>
            )
        }
    ];

    const realizationColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },{
            title: 'Date',
            render: (row) => (
                <span>{toFullDate(row.from_date)} - {toFullDate(row.to_date)}</span>
            )
        },{
            title: 'Purpose',
            dataIndex: 'purpose',
            key: 'purpose',
        },{
            title: 'Total Amount',
            dataIndex: 'total_amount',
            key: 'total_amount',
        },{
            title: 'Request Date',
            dataIndex: 'created_at',
            render: (text) => (
                formatDateTime(text)
            )
        },{
            title: 'Status',
            dataIndex: 'status',
            render: (text) => (
                <div className="text-center">
                    { getStatus(text) }
                </div>
            )
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/cash-advance-realization/'+row.id} />
                </div>
            )
        }
    ];

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={requestColumn} dataSource={requestData.filter(el => el.status === "WAITING")} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={requestColumn} dataSource={requestData.filter(el => el.status === "APPROVED")} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={requestColumn} dataSource={requestData.filter(el => el.status === "REJECTED")} bordered/>
                </TabPane>
                <TabPane tab={
                    <span>
                        <FieldTimeOutlined style={{color:'green'}}/> 
                        Realization {
                            (props.realizationNotif) ? 
                            <Badge dot className="blinking-badge"></Badge>
                            : null
                        }
                    </span>
                    } key="realization">
                    <DataTable size="small" columns={realizationColumn} dataSource={realizationData} bordered/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
