import { useEffect, useState } from 'react'
import { Button, Card, Col, DatePicker, Divider, Row } from 'antd'
import { PageTitle } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import Monthly from './tab/monthly'
import Semester from './tab/semester'
import Daily from './tab/daily'
import Weekly from './tab/weekly'
import Quarterly from './tab/quarterly'
import dayjs from 'dayjs'

const chunkArrayInGroups = (arr, size) => {
    var myArray = [];
    for (var i = 0; i < arr.length; i += size) {
        myArray.push(arr.slice(i, i + size));
    }
    return myArray;
}

const Index = () => {
    const [loadedDaily, setLoadedDaily] = useState(false)
    const [loadedWeekly, setLoadedWeekly] = useState(false)
    const [defaultCategory, setDefaultCategory] = useState("Daily")
    const [dashboardDaily, setDashboardDaily] = useState([])
    const [dashboardWeekly, setDashboardWeekly] = useState([])
    const [dashboardDataMonthly, setDashboardDataMonthly] = useState([])
    const [dashboardDataQuarterly, setDashboardDataQuarterly] = useState([])
    const [dashboardDataSemester, setDashboardDataSemester] = useState([])
    const [defaultDateDaily, setDefaultDateDaily] = useState(Date.now())
    const [defaultDateWeekly, setDefaultDateWeekly] = useState(Date.now())

    const fetchDashboardDaily = (v) => {
        setLoadedDaily(false)
        SentralModel.action("ItInfra", "dashboardDataDaily", { division_id: 34, position_id: 66, month: dayjs(v).format('MMMM') }, null).then((res) => {
            setDashboardDaily(res.data)
            setLoadedDaily(true)
        })
    }

    const fetchDashboarWeekly = (v) => {
        setLoadedWeekly(false)
        SentralModel.action("ItInfra", "dashboardDataWeekly", { division_id: 34, position_id: 66, month: dayjs(v).format('MMMM') }, null).then((res) => {
            const v = res.data
            const week = chunkArrayInGroups(res.data.date, 5)
            v.week = week

            week.forEach((w, key) => {
                v.datas.forEach((i) => {
                    i.employee.forEach((e) => {
                        e.job.forEach((j) => {
                            if (w.includes(dayjs(j.start_date).format('YYYY-MM-DD'))) {
                                j.week = `Week ${key + 1}`
                            }
                        })
                    })
                })
            })

            setDashboardWeekly(v)
            setLoadedWeekly(true)
        })
    }

    const fetchDashboardDataMonthly = () => {
        SentralModel.action("ItInfra", "dashboardData", null, null).then((res) => {
            setDashboardDataMonthly(res.data.monthly_data)
            setDashboardDataQuarterly(res.data.quarter_data)
            setDashboardDataSemester(res.data.semester_data)
        })
    }

    useEffect(() => {
        fetchDashboardDaily(Date.now())
        fetchDashboarWeekly(Date.now())
        fetchDashboardDataMonthly()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeDataDaily = (v) => {
        setDefaultDateDaily(v)
        fetchDashboardDaily(v)
    }

    const onChangeDataWeekly = (v) => {
        setDefaultDateWeekly(v)
        fetchDashboarWeekly(v)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Dashboard IT Infra" breadcrumbs={[['Dashboard IT Infra']]}></PageTitle>
                <div style={{ marginTop: 10, marginBottom: 15 }}>
                    <Button onClick={() => setDefaultCategory("Daily")}>Daily</Button>
                    <Button onClick={() => setDefaultCategory("Weekly")}>Weekly</Button>
                    <Button onClick={() => setDefaultCategory("Monthly")}>Monthly</Button>
                    {/* <Button onClick={() => setDefaultCategory("Quarterly")}>Quarterly</Button> */}
                    <Button onClick={() => setDefaultCategory("Semester")}>Semester</Button>
                </div>
                <Divider />

                {
                    defaultCategory === "Daily" ?
                        <div>
                            <div style={{ marginBottom: 10 }}>
                                <Card title={
                                    <Row justify='space-between'>
                                        <Col>
                                            Daily Dashboard
                                        </Col>
                                        <Col>
                                            <DatePicker picker="month" value={dayjs(defaultDateDaily)} onChange={(v) => onChangeDataDaily(v)} />
                                        </Col>
                                    </Row>
                                }>
                                    {
                                        loadedDaily ?
                                            <Daily data={dashboardDaily} />
                                            : null
                                    }
                                </Card>
                            </div>
                        </div>
                        :
                        defaultCategory === "Weekly" ?
                            <div>
                                <div style={{ marginBottom: 10 }}>
                                    <Card title={
                                        <Row justify='space-between'>
                                            <Col>
                                                Weekly Dashboard
                                            </Col>
                                            <Col>
                                                <DatePicker picker="month" value={dayjs(defaultDateWeekly)} onChange={(v) => onChangeDataWeekly(v)} />
                                            </Col>
                                        </Row>
                                    }>
                                        {
                                            loadedWeekly ?
                                                <Weekly data={dashboardWeekly} />
                                                : null
                                        }
                                    </Card>
                                </div>
                            </div>
                            :
                            defaultCategory === "Monthly" ?
                                <div>
                                    <PageTitle title="Monthly"></PageTitle>
                                    <div>
                                        <Monthly data={dashboardDataMonthly} />
                                    </div>
                                </div>
                                :
                                defaultCategory === "Quarterly" ?
                                    <div>
                                        <Quarterly data={dashboardDataQuarterly} />
                                    </div>
                                    : defaultCategory === "Semester" ?
                                        <Semester data={dashboardDataSemester} />
                                        : null
                }
            </Card>
        </div>
    )
}

export default Index