import { useState, useEffect } from 'react'
import { Card, Tabs } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import ActionPlan from './tabs/actionPlan'
import ActionProgress from './tabs/actionProgress'

const { TabPane } = Tabs

const ReportActionPlan = () => {
    const [loadedReport, setLoadedReport] = useState(false)
    const [loadedProgress, setLoadedProgress] = useState(false)
    const [reportActionPlan, setReportActionPlan] = useState([])
    const [actionPlanProgress, setActionPlanProgress] = useState([])

    const fetchActionPlan = () => {
        SentralModel.action('ActionPlan', 'getActionPlanByDivision', 0).then((res) => {
            setReportActionPlan(res.data)
            setLoadedReport(true)
        })
    }

    const fetchActionPlanProgress = () => {
        SentralModel.action('ActionPlan', 'actionPlanDivisionProgress', 0).then((res) => {
            setActionPlanProgress(res.data)
            setLoadedProgress(true)
        })
    }

    useEffect(() => {
        fetchActionPlan()
        fetchActionPlanProgress()

    }, []);

    return (
        <Card className="content-container">
            <PageTitle title="Report Action Plan" breadcrumbs={[['Report Action Plan']]}></PageTitle>
            <Tabs type='card' defaultActiveKey='action-plan-report'>
                <TabPane tab="Action Plan Report" key="action-plan-report">
                    {
                        loadedReport ?
                            <ActionPlan data={reportActionPlan} />
                            : <Spinner />
                    }
                </TabPane>
                <TabPane tab="Action Plan Quality Report" key="action-plan-quality-report">
                    {
                        loadedProgress ?
                            <ActionProgress data={actionPlanProgress} />
                            : <Spinner />
                    }
                </TabPane>
            </Tabs>
        </Card>
    )
}

export default ReportActionPlan