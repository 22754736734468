import React, { useState, useEffect } from 'react';
import { List, Divider, Typography } from 'antd';
import { SentralModel } from '../../models/SentralModel';
import moment from 'moment';

const { Text } = Typography

const Probation = () => {
    const [loaded, setLoaded] = useState(false)
    const [probationOneMonth, setProbationOneMonth] = useState([])
    const [probationOneWeek, setProbationOneWeek] = useState([])
    const [probationTwoWeek, setProbationTwoWeek] = useState([])

    const fetchEmployeeStatus = () => {
        let payload = {
            date: moment(Date.now()).format('YYYY-MM-DD')
        }
        setLoaded(false)
        SentralModel.action('HumanResource', 'getEmployeeProbation', payload).then((res) => {
            setProbationOneMonth(res.data.probationOneMonth)
            setProbationOneWeek(res.data.probationOneWeek)
            setProbationTwoWeek(res.data.probationTwoWeek)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchEmployeeStatus()
    }, []);

    return (
        <div style={{ marginTop: 5 }}>
            {
                loaded ?
                    (probationOneMonth.length === 0 && probationOneWeek.length === 0 && probationTwoWeek.length === 0) ?
                        <span className="text-muted">No employee probation for today</span>
                        :
                        <>
                            {
                                (probationOneWeek.length !== 0) ?
                                    <div>
                                        <List className='scrollbar-custom' style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                            {
                                                probationOneWeek.map((el, key) => (
                                                    <div key={'leave_' + key}>
                                                        <List.Item style={{ padding: 0, margin: 0, border: 0, cursor: 'pointer' }}>
                                                            {el.name}<br />
                                                            {moment(el.probation_start).format('DD MMM YYYY')} - {moment(el.probation_end).format('DD MMM YYYY')}<br />
                                                            {
                                                                <Text strong>Probation will end in 7 days</Text>
                                                            }
                                                        </List.Item>
                                                        <Divider />
                                                    </div>
                                                ))
                                            }
                                        </List>
                                    </div>
                                    : null
                            }

                            {
                                (probationTwoWeek.length !== 0) ?
                                    <div>
                                        <List className='scrollbar-custom' style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                            {
                                                probationTwoWeek.map((el, key) => (
                                                    <div key={'leave_' + key}>
                                                        <List.Item style={{ padding: 0, margin: 0, border: 0, cursor: 'pointer' }}>
                                                            {el.name}<br />
                                                            {moment(el.probation_start).format('DD MMM YYYY')} - {moment(el.probation_end).format('DD MMM YYYY')}<br />
                                                            {
                                                                <Text strong>Probation will end in 14 days</Text>
                                                            }
                                                        </List.Item>
                                                        <Divider />
                                                    </div>
                                                ))
                                            }
                                        </List>
                                    </div>
                                    : null
                            }

                            {
                                (probationOneMonth.length !== 0) ?
                                    <div>
                                        <List className='scrollbar-custom' style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                            {
                                                probationOneMonth.map((el, key) => (
                                                    <div key={'leave_' + key}>
                                                        <List.Item style={{ padding: 0, margin: 0, border: 0, cursor: 'pointer' }}>
                                                            {el.name}<br />
                                                            {moment(el.probation_start).format('DD MMM YYYY')} - {moment(el.probation_end).format('DD MMM YYYY')}<br />
                                                            {
                                                                <Text strong>Probation will end in 30 days</Text>
                                                            }
                                                        </List.Item>
                                                        <Divider />
                                                    </div>
                                                ))
                                            }
                                        </List>
                                    </div>
                                    : null
                            }
                        </>
                    : null
            }
        </div>
    );
}

export default Probation;
