import React, {useState, useEffect} from 'react';
import {Row, Col, Card, Form, Input, Typography, DatePicker, Button} from 'antd';
import moment from 'moment'
import { toIdr, extractNumber } from '../../../utils/currencyHandler';
import {SubmitButton, AddButton} from '../../../components'
// import { SentralModel } from '../../../models/SentralModel';
import {DeleteOutlined} from '@ant-design/icons'
const {TextArea} = Input
const {Text} = Typography
const {RangePicker} = DatePicker
// const {Option} = Select

function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const RequestForm = (props) => {
    const forceUpdate = useForceUpdate()
    const [form] = Form.useForm()
    // const [selectedTypes, setSelectedTypes] = useState(null)
    const [totalCostPlanning, setTotalCostPlanning] = useState(null)
    const [costItem, setCostItem] = useState([{description:'', quantity:null, uom:'', amount:'', total:null}])
    // const [types, setTypes] = useState([])

    // const fetchType = () =>{
    //     SentralModel.list('GeneralDetail', {filter: [["general_code","TT"]]}).then((res)=>{
    //         setTypes(res.data)
    //     })
    // }
    
    useEffect(() => {
        // fetchType()
    }, []);

    const addCostItem = () =>{
        let x = costItem
        let key = costItem.length
        // console.log(key)
        x.push({description: '', quantity: null, uom: '', amount: '', total: null})
        setCostItem(x)
        forceUpdate()
        form.setFieldsValue({
            ['cost_item_'+key] : null,
            ['cost_price_'+key] : null,
            ['cost_total_'+key] : null,
            ['cost_qty_'+key] : null,
            ['cost_uom_'+key] : null,
        })
    }

    const changeItemPrice = (v, key) => {
        let x = costItem;
        if (v.target.value) {
            const newPrice = extractNumber(v.target.value)
            x[key].amount = newPrice
            const qty = x[key].quantity
            form.setFieldsValue({['cost_price_'+key] : toIdr(newPrice)})
            if (qty) {
                x[key].total = newPrice * qty
                form.setFieldsValue({['cost_total_'+key] : toIdr(newPrice*qty)})
            }
            setCostItem(x)
            changeTotalCost()
        } else {
            x[key].total=0
            form.setFieldsValue({['cost_total_'+key] : 0})
            setCostItem(x)
            changeTotalCost()
        }
    }
    const changeItemQty = (v, key) => {
        let x = costItem;
        if(v.target.value){
            const newQty = parseInt(v.target.value)
            const price = x[key].amount
            x[key].quantity = newQty
            if(price){
                x[key].total = price * newQty
                form.setFieldsValue({['cost_total_'+key] : toIdr(price * newQty)})
            }
            setCostItem(x)
            changeTotalCost()
        }else{
            x[key].total = 0
            form.setFieldsValue({['cost_total_'+key] : 0})
            setCostItem(x)
            changeTotalCost()
        }
    }

    const changeTotalCost = () => {
        let total = 0;
        costItem.forEach(el => {
            total += el.total
        });
        setTotalCostPlanning(total)
    }

    const submit = (v) =>{
        let data={
            from_date: moment(v.date[0]).format('YYYY-MM-DD'),
            to_date: moment(v.date[1]).format('YYYY-MM-DD'),
            purpose: v.description,
            plan: v.planning,
            // type: selectedTypes,
            total_days: moment(v.date[1]).diff(moment(v.date[0]), 'days') + 1,
            total_amount: totalCostPlanning,
            cost_planning: costItem,
        }
        props.onSubmit(data)
    }

    const removeItem = (key) =>{
        let item = costItem
        item.splice(key,1)
        setCostItem(item)
        changeTotalCost()
        forceUpdate()
    }
    return (
        <Form form={form} size="middle" layout="vertical" onFinish={submit}>
            <Row justify="center">
                <Col span={22}>
                    <Row justify="space-between">
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item name="date" label="Date" rules={[{required:true}]}>
                                <RangePicker style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item name="description" label="Purpose" rules={[{required:true}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12} style={{ paddingLeft: 10 }}>
                            <Form.Item name="type" label="Type" rules={[{required:true}]}>
                                <Select onChange={(v) => {
                                    setSelectedTypes(v)
                                    form.setFieldsValue({destination:null})
                                }}>
                                    {
                                        types.map((el, key)=>(
                                            <Option key={key} value={el.detail_code}>{el.description}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col span={24}>
                            <Form.Item name="planning" label="Planning" rules={[{required:true}]}>
                                <TextArea autoSize={{minRows:2}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Card title={
                                <div className="full-width">
                                    <Text strong style={{float:'left'}}>Cost Planning</Text>
                                    <AddButton right onAdd={() => addCostItem()} title="Add Cost Item"/>
                                </div>}>
                                
                                <table className="table-item" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Quantity</th>
                                            <th>UoM</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            costItem.map((el, key) =>(
                                                <tr key={'rmb_item'+key}>
                                                    <td>
                                                        <Form.Item name={`cost_item_${key}`} rules={[{required:true}]}>
                                                            <Input placeholder="Item Name" onChange={(v) => {
                                                                let x= costItem;
                                                                x[key].description = v.target.value
                                                                setCostItem(x)
                                                            }}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={`cost_qty_${key}`} rules={[{required:true}]}>
                                                            <Input type="number" placeholder="Quantity" onChange={(v) => changeItemQty(v, key)}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={`cost_uom_${key}`} rules={[{required:true}]}>
                                                            <Input placeholder="UoM" onChange={(v) => {
                                                                let x= costItem;
                                                                x[key].uom = v.target.value
                                                                setCostItem(x)
                                                            }}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={`cost_price_${key}`} rules={[{required:true}]}>
                                                            <Input placeholder="Price" onChange={(v)=>changeItemPrice(v, key)}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td>
                                                        <Form.Item name={`cost_total_${key}`} value={costItem[key].amount} rules={[{required:true}]}>
                                                            <Input placeholder="Total" readOnly/>
                                                        </Form.Item>
                                                    </td>
                                                    <td className="text-center" style={{width:'4em'}}>
                                                        {
                                                            ((key+1)===costItem.length && costItem.length>1) ? 
                                                            <Button type="text" danger onClick={() => removeItem(key)} icon={<DeleteOutlined/>}/>
                                                            : null
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr style={{borderTop: '1px solid #ededed'}}>
                                            <td className="text-right" colSpan={4}>Total : </td>
                                            <td>{toIdr(totalCostPlanning)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={24} className="text-right">
                            <SubmitButton loading={props.saving}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
