import React from 'react';
import LeaveRequest from './request/Index'
// import CancellationRequest from './cancellation/Index'

const Index = (props) => {
    return (
        <div>
            <LeaveRequest data={props.data.requests} cancellations={props.data.cancellations} requestNotif={props.requestNotif} cancellationNotif={props.cancellationNotif}/>
            {/* <Tabs defaultActiveKey="request" >
                <TabPane tab="Leave Request" key="request">
                </TabPane>
                <TabPane tab="Cancellation Request" key="cancellation">
                    <CancellationRequest data={props.data.cancellations}/>
                </TabPane>
            </Tabs> */}
        </div>
    );
}

export default Index;
