import React, { useEffect, useState } from 'react';
import { Card, message, Typography } from 'antd';
import { PageTitle } from '../../../components'
import CreateForm from './Form'
import { SentralModel } from '../../../models/SentralModel';
import { useHistory, useParams } from 'react-router-dom'

const { Text } = Typography

const Edit = () => {
    const { punishmentId } = useParams()
    const history = useHistory()
    const [saving, setSaving] = useState(false)
    const [punishment, setPunishment] = useState(null)

    const fetchPunishment = (punishmentId) => {
        SentralModel.action("RewardPunishment", "getPunishment", {}, punishmentId).then((res) => {
            setPunishment(res.data)
        })
    }

    useEffect(() => {
        fetchPunishment(punishmentId)
    }, [punishmentId]);

    const submitPunishment = (v) => {
        setSaving(true)
        SentralModel.action("RewardPunishment", "updatePunishment", v, punishmentId).then((res) => {
            message.success("Punishment updated", 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/management/punishment');
            }, 1000);
        }).catch((err) => {
            message.error(err.message, 2)
            setSaving(false)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Punishment" breadcrumbs={[['Punishment', '/management/punishment'], ['Create']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Form Punishment</Text>
                </div>
            }>
                <CreateForm data={punishment} onSubmit={(v) => submitPunishment(v)} saving={saving} />
            </Card>
        </Card>
    );
}

export default Edit;
