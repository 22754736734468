import React from 'react'
import { toIdr } from '../../../utils/currencyHandler'

const Sales = ({ data }) => {
    const nullData = [0, null]

    return (
        <div>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th style={{ fontSize: 16, border: "1px solid #eaeaea" }} rowSpan={3}>Period</th>
                        <th style={{ fontSize: 16, border: "1px solid #eaeaea" }} colSpan={3}>Profit</th>
                        <th style={{ fontSize: 16, border: "1px solid #eaeaea" }} colSpan={3}>Sales</th>
                    </tr>
                    <tr>
                        <th style={{ fontSize: 16, border: "1px solid #eaeaea" }}>Amount Profit</th>
                        <th style={{ fontSize: 16, border: "1px solid #eaeaea" }}>Target Profit</th>
                        <th style={{ fontSize: 16, border: "1px solid #eaeaea" }}>Percentage</th>
                        <th style={{ fontSize: 16, border: "1px solid #eaeaea" }}>Amount Sales</th>
                        <th style={{ fontSize: 16, border: "1px solid #eaeaea" }}>Target Sales</th>
                        <th style={{ fontSize: 16, border: "1px solid #eaeaea" }}>Percentage</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map((el, key) => (
                            <tr key={key}>
                                <td style={{ border: "1px solid #eaeaea", textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{el.period}</td>
                                <td style={{ border: "1px solid #eaeaea", textAlign: 'center' }}>{toIdr(el.amount_profit)}</td>
                                <td style={{ border: "1px solid #eaeaea", textAlign: 'center' }}>{toIdr(el.target_profit)}</td>
                                <td style={{ border: "1px solid #eaeaea", textAlign: 'center' }}>{nullData.includes(el.target_profit) || nullData.includes(el.amount_profit) ? 0 : Math.round((el.amount_profit / el.target_profit) * 100)}%</td>
                                <td style={{ border: "1px solid #eaeaea", textAlign: 'center' }}>{toIdr(el.amount_sales)}</td>
                                <td style={{ border: "1px solid #eaeaea", textAlign: 'center' }}>{toIdr(el.target_sales)}</td>
                                <td style={{ border: "1px solid #eaeaea", textAlign: 'center' }}>{nullData.includes(el.target_sales) || nullData.includes(el.amount_sales) ? 0 : Math.round((el.amount_sales / el.target_sales) * 100)}%</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Sales