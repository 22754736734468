import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Select, DatePicker, InputNumber, TimePicker } from 'antd';
import { SubmitButton, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'

const { Option } = Select;
const { RangePicker } = DatePicker

const RequestForm = (props) => {
    const [recForm] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [divisions, setDivisions] = useState([]);
    const [positions, setPositions] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [selectedDivision, setSelectedDivision] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedEducation, setSelectedEducation] = useState('ETS1');
    const [employmentStatus, setEmploymentStatus] = useState('')

    const fetchDivisions = () => {
        SentralModel.list('Division', { orderBy: "division_name", order: "asc" }).then(res => {
            setDivisions(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchPositions = () => {
        SentralModel.list('Position', { orderBy: "position_name", order: "asc", filter: [['is_active', '1']] }).then(res => {
            setPositions(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchProvinces = () => {
        SentralModel.list('Province', { orderBy: "province_name", order: "asc" }).then(res => {
            setProvinces(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchEducationLevels = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ET'], ['detail_code', '!=', 'ETBS'], ['detail_code', '!=', 'ETSD'], ['detail_code', '!=', 'ETSLTP']], orderBy: "order_key", order: "asc" }).then(res => {
            setEducationLevels(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    useEffect(() => {
        fetchDivisions()
        fetchPositions()
        fetchProvinces()
        fetchEducationLevels()
    }, []);

    const changeDivision = e => {
        setSelectedDivision(e)
    }

    const changePosition = e => {
        setSelectedPosition(e)
    }

    const changeProvince = e => {
        setSelectedProvince(e)
    }

    const changeEducation = e => {
        setSelectedEducation(e)
        console.log(e)
    }

    const onChangeExp = e => {
        const { value } = e.target;
        if (isNaN(value)) {
            recForm.setFieldsValue({ min_work_experience: value.replace(/[^0-9]+/g, "") })
        }
    }

    const onChangeAge = e => {
        const { value } = e.target;
        if (isNaN(value)) {
            recForm.setFieldsValue({ age_limit: value.replace(/[^0-9]+/g, "") })
        }
    }

    const onChangeQuota = e => {
        const { value } = e.target;
        if (isNaN(value)) {
            recForm.setFieldsValue({ quota: value.replace(/[^0-9]+/g, "") })
        }
    }

    const selectBefore = (
        <Select placeholder="Select" defaultValue={selectedEducation} onChange={changeEducation}>
            {
                educationLevels.map((el, key) => (
                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                ))
            }
        </Select>
    );

    const submitRecruitment = (v) => {
        let submittedData = {
            division_id: selectedDivision,
            position_id: selectedPosition,
            vacancies_status: v.vacancies_status,
            employment_status: v.employment_status,
            employment_period: v.employment_period,
            gender: v.gender,
            level: v.level,
            work_date: moment(v.work_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            subject: v.subject,
            from_date: moment(v.date[0], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            to_date: moment(v.date[1], 'DD MMMM YYYY').format('YYYY-MM-DD'),
            min_work_experience: v.min_work_experience,
            education_level: selectedEducation,
            min_education: v.min_education,
            age_limit: v.age_limit,
            quota: v.quota,
            work_location: selectedProvince,
            requirement: v.requirement,
            job_description: v.job_description,
            marital_status: v.marital_status,
            salary_type: v.salary_type,
            nominal: v.nominal,
            health_allowance: v.health_allowance,
            transport_allowance: v.transport_allowance,
            food_allowance: v.food_allowance,
            communication_allowance: v.communication_allowance,
            working_days: v.working_days,
            start_working_time: moment(v.working_time[0]).format("HH:mm"),
            end_working_time: moment(v.working_time[1]).format("HH:mm"),
            experience: v.experience,
            skill: v.skill,
        }

        props.onSubmit(submittedData)
    }


    return (
        loaded ?
            <Form size="middle" form={recForm} layout="vertical" onFinish={submitRecruitment}>
                <Form.Item name="rec_vacancy_id" hidden>
                    <Input placeholder="Vacancy ID" />
                </Form.Item>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Division" name="division_id" rules={[{ required: true }]}>
                            <Select showSearch style={{ width: "100%" }} placeholder="Select Division" optionFilterProp="children" onChange={changeDivision} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    divisions.map((el, key) => (
                                        <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Position" name="position_id" rules={[{ required: true }]}>
                            <Select showSearch style={{ width: "100%" }} placeholder="Select Position" optionFilterProp="children" onChange={changePosition} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    positions.map((el, key) => (
                                        <Option key={key} value={el.position_id}>{el.position_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Vacancies Status" name="vacancies_status" rules={[{ required: true }]}>
                            <Select placeholder="Select Vacancies Status">
                                <Option value="Pergantian Tenaga Kerja">Pergantian Tenaga Kerja</Option>
                                <Option value="Penambahan Tenaga Kerja">Penambahan Tenaga Kerja</Option>
                                <Option value="Head Hunter">Head Hunter</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Employment Status" name="employment_status" rules={[{ required: true }]}>
                            <Select placeholder="Select Employment Status" onChange={(v) => setEmploymentStatus(v)}>
                                <Option value="Probation/Tetap">Probation/Tetap</Option>
                                <Option value="Kontrak">Kontrak</Option>
                                <Option value="Magang">Magang</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    employmentStatus === 'Kontrak' || employmentStatus === 'Magang' ?
                        <Row justify='space-around'>
                            <Col span={11}>
                                <Form.Item label="Period Employment (In Months)" name="period_employment">
                                    <InputNumber min={1} style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                            </Col>
                        </Row>
                        : null
                }
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Level" name="level" rules={[{ required: true }]}>
                            <Select placeholder="Select Level">
                                <Option value="Staff">Staff</Option>
                                <Option value="Middle">Middle</Option>
                                <Option value="Senior">Senior</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Work Date" name="work_date" rules={[{ required: true }]}>
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Subject" name="subject" rules={[{ required: true }]}>
                            <Input placeholder="Subject" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Work Experience" name="min_work_experience" rules={[{ required: true }]}>
                            <Input addonBefore="Min" addonAfter="Year(s)" onChange={onChangeExp} placeholder="Work Experience" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Work Location" name="work_location" rules={[{ required: true }]}>
                            <Select showSearch style={{ width: "100%" }} placeholder="Select Location" optionFilterProp="children" onChange={changeProvince} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {
                                    provinces.map((el, key) => (
                                        <Option key={key} value={el.province_id}>{el.province_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Age Limit" name="age_limit" rules={[{ required: true }]}>
                            <Input onChange={onChangeAge} placeholder="Age Limit" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Recruitment Date" name="date" rules={[{ required: true }]}>
                            <RangePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Quota" name="quota" rules={[{ required: true }]}>
                            <Input onChange={onChangeQuota} placeholder="Quota" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Minimum Education" name="min_education" rules={[{ required: true }]}>
                            <Input addonBefore={selectBefore} placeholder="Education" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Marital Status" name="marital_status" rules={[{ required: true }]}>
                            <Select placeholder="Select Marital Status">
                                <Option value="Single">Single</Option>
                                <Option value="Menikah">Menikah</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col span={11}>
                        <Form.Item label="Gender" name="gender" rules={[{ required: true }]}>
                            <Select placeholder="Select Gender">
                                <Option value="GM">Pria</Option>
                                <Option value="GF">Wanita</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11}>
                        <Form.Item label="Requirement" name="requirement" rules={[{ required: true }]}>
                            <Input.TextArea rows={4} placeholder="Requirement" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Job Description" name="job_description" rules={[{ required: true }]}>
                            <Input.TextArea rows={4} placeholder="Job Description" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col span={11}>
                        <Form.Item label="Salary Type" name="salary_type" rules={[{ required: true }]}>
                            <Select placeholder="Select Salary Type">
                                <Option value="Gross">Gross</Option>
                                <Option value="Nett">Nett</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Nominal" name="nominal" rules={[{ required: true }]}>
                            <InputNumber placeholder='0' min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col span={11}>
                        <Form.Item label="Health Allowance" name="health_allowance" rules={[{ required: true }]}>
                            <InputNumber placeholder='0' min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Transport Allowance" name="transport_allowance" rules={[{ required: true }]}>
                            <InputNumber placeholder='0' min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col span={11}>
                        <Form.Item label="Food Allowance" name="food_allowance" rules={[{ required: true }]}>
                            <InputNumber placeholder='0' min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Communication Allowance" name="communication_allowance" rules={[{ required: true }]}>
                            <InputNumber placeholder='0' min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col span={11}>
                        <Form.Item label="Working Days" name="working_days" rules={[{ required: true }]}>
                            <Input placeholder="Senin - Jumat" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Working Time" name="working_time">
                            <TimePicker.RangePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-around'>
                    <Col span={11}>
                        <Form.Item label="Experience" name="experience" rules={[{ required: true }]}>
                            <Input.TextArea rows={4} placeholder="Experience" />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Skill" name="skill" rules={[{ required: true }]}>
                            <Input.TextArea rows={4} placeholder="Skill" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col span={11} />
                    <Col span={11}>
                        <SubmitButton right loading={props.saving} />
                    </Col>
                </Row>
            </Form>
            : <Spinner />
    );
}

export default RequestForm;
