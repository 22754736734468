import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import {toIdr} from '../../../utils/currencyHandler'

const {Text} = Typography

const Detail = () => {
    const {requestId} = useParams()
    const [entertainment, setEntertainment] = useState(null)

    const fetchEntertainment = (id) => {
       SentralModel.get('SubmissionEntertainment', {}, id).then((res)=>{
           setEntertainment(res.data)
       })
    }

    useEffect(() => {
        fetchEntertainment(requestId)
    }, [requestId]);

    const statusUpdated = () =>{
        setEntertainment(null)
        fetchEntertainment(requestId)
        window.location.reload();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Entertainment Request" breadcrumbs={[['Submission List','/submission-list'],['Detail']]}></PageTitle>
            </div>
            {
                (entertainment) ? 
                <div>
                    <DetailHeader 
                        name={entertainment.employee.name} 
                        date={entertainment.created_at}
                        status={entertainment.status}/>
                    <Card bordered className="mb-4">
                        <Text strong>ENTERTAINMENT DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{entertainment.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{entertainment.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{entertainment.employee.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Title</th><td>{entertainment.title}</td>
                                        </tr>
                                        <tr>
                                            <th>Description</th><td>{entertainment.description}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Amount</th><td>{toIdr(entertainment.total_amount)}</td>
                                        </tr>
                                        <tr>
                                            <th>Attachment</th>
                                            <td>
                                                {
                                                    (entertainment.attachment) ?
                                                    <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE+'/'+entertainment.attachment}>
                                                        Show Attachment
                                                    </a> : '-'
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered className="mb-4">
                        <Text strong>ENTERTAINMENT GIFT</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Item Name</th>
                                            <th>Date</th>
                                            <th>Place</th>
                                            <th>Address</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            entertainment.gifts.map((el, key) => (
                                                <tr key={key}>
                                                   <td>{el.gift}</td>
                                                   <td>{el.date}</td>
                                                   <td>{el.place}</td>
                                                   <td>{el.address}</td>
                                                   <td>{toIdr(el.amount)}</td>
                                                </tr>
                                            ))
                                        }
                                        
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered className="mb-4">
                        <Text strong>ENTERTAINMENT RELATION</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Position</th>
                                            <th>Organization</th>
                                            <th>Business Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            entertainment.relations.map((el, key) => (
                                                <tr key={key}>
                                                   <td>{el.name}</td>
                                                   <td>{el.position}</td>
                                                   <td>{el.company}</td>
                                                   <td>{el.business_type}</td>
                                                </tr>
                                            ))
                                        }
                                        
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="ENTERTAINMENT" 
                            requestId={entertainment.sub_entertainment_id} 
                            approvers={entertainment.approver} 
                            onSubmitted={() => statusUpdated()}/>
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
