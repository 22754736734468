import { useState, useEffect } from 'react'
import { Button, Card, Col, Drawer, Row, Space, Tabs, Typography, Form, DatePicker, Select, Tag } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import { DataTable, PageTitle } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'

const { TabPane } = Tabs
const { Title } = Typography
const { RangePicker } = DatePicker

const Index = () => {
    const [form] = Form.useForm()
    const [dailyData, setDailyData] = useState([])
    const [workingData, setWorkingData] = useState([])
    const [division, setDivision] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [defaultTab, setDefaultTab] = useState("daily_activity")
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [showModalFilter, setShowModalFilter] = useState(false)
    const [isExport, setIsExport] = useState(false)

    const fetchDataDaily = (v) => {
        SentralModel.action("Report", "dailyWorkingLocation", v, 0).then((res) => {
            setDailyData(res.data)
        })
    }

    const fetchWorkingLocation = (v) => {
        SentralModel.action("Report", "workingLocation", v, 0).then((res) => {
            setWorkingData(res.data)
        })
    }

    const fetchDivision = () => {
        SentralModel.list('Division', { filter: [["is_active", "1"]], orderBy: "division_name", order: "asc" }).then(res => {
            let arr = []
            const d = res.data
            arr.push({ value: 99, label: "All IT Bizdev" })
            d.forEach((el) => {
                arr.push({ value: el.division_id, label: el.division_name })
            })

            setDivision(arr)
        })
    }

    useEffect(() => {
        fetchDivision()

    }, [])

    const onShowModal = () => {
        setShowModalFilter(true)
    }

    const handleDivision = (v) => {
        setSelectedDivision(v)
    }

    const submitFilter = (v) => {
        const start_date = v.range_date === undefined ? moment(Date.now()).format("YYYY-MM-DD") : moment(v.range_date[0]).format("YYYY-MM-DD")
        const end_date = v.range_date === undefined ? moment(Date.now()).format("YYYY-MM-DD") : moment(v.range_date[0]).format("YYYY-MM-DD")

        setStartDate(start_date)
        setEndDate(end_date)

        fetchDataDaily({ first_date: start_date, end_date: end_date, division_id: selectedDivision })
        fetchWorkingLocation({ first_date: start_date, end_date: end_date, division_id: selectedDivision })
        setShowModalFilter(false)
        setIsExport(true)
    }

    const resetFilter = () => {
        setShowModalFilter(false)
        setIsExport(false)
        setDailyData([])
        setWorkingData([])
    }

    const exportExcelDaily = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/dailyWorking?first_date=${startDate}&end_date=${endDate}&division_id=${selectedDivision}`)
    }

    const exportExcelWorking = () => {
        window.open(process.env.REACT_APP_API_URL + `/report/locationWorking?first_date=${startDate}&end_date=${endDate}&division_id=${selectedDivision}`)
    }

    const dailyColumn = [
        {
            title: "Date",
            key: "date",
            render: (row) => (
                row?.task?.start_date === "0000-00-00 00:00:00" ?
                    moment(row?.assign_at).format("dddd, DD MMMM YYYY")
                    :
                    moment(row?.task?.start_date).format("dddd, DD MMMM YYYY")
            ),
            align: "center"
        },
        {
            title: "Employee Name",
            key: "employee_name",
            render: (row) => (
                row?.task_to?.name
            )
        },
        {
            title: "Job Description",
            key: "job_description",
            render: (row) => (
                row?.task?.description
            )
        },
        {
            title: "Data From",
            key: "data_from",
            dataIndex: "data_from"
        }
    ]

    const workingColumn = [
        {
            title: "Date",
            key: "date",
            dataIndex: "date",
            align: "center"
        },
        {
            title: "Employee Name",
            key: "employee_name",
            render: (row) => (
                row?.employee?.name
            )
        },
        {
            title: "Working Location",
            key: "working_location",
            render: (row) => (
                parseInt(row?.company_id) === 0 ?
                    <Tag color="red">
                        {row?.description}
                    </Tag>
                    :
                    row?.company?.company_name
            ),
            align: "center"
        },
        {
            title: "Time",
            key: "time",
            render: (row) => (
                `${row?.time_start} - ${row?.time_end}`
            )
        }
    ]

    return (
        <div>
            <Card className="content-container">
                <PageTitle title=" Export Daily Activity & Working Location" breadcrumbs={[['Export Daily Activity & Working Location']]}></PageTitle>

                <Tabs type="card" defaultActiveKey={defaultTab} onChange={(v) => setDefaultTab(v)}>
                    <TabPane tab="Daily Activity" key="daily_activity">
                        <Card title={
                            <Row justify='space-between'>
                                <Col>
                                    <Title level={4}>Daily Activity</Title>
                                </Col>
                                <Col>
                                    <Space>
                                        <Button shape='circle' onClick={onShowModal}>
                                            <FilterOutlined />
                                        </Button>
                                        {
                                            isExport ?
                                                <Button type="primary" onClick={exportExcelDaily}>
                                                    Export
                                                </Button>
                                                :
                                                <Button disabled type="primary" onClick={exportExcelDaily}>
                                                    Export
                                                </Button>
                                        }
                                    </Space>
                                </Col>
                            </Row>
                        }>
                            <DataTable columns={dailyColumn} dataSource={dailyData} size="small" bordered />
                        </Card>
                    </TabPane>
                    <TabPane tab="Working Location" key="working_location">
                        <Card title={
                            <Row justify='space-between'>
                                <Col>
                                    <Title level={4}>Working Location</Title>
                                </Col>
                                <Col>
                                    <Space>
                                        <Button shape='circle' onClick={onShowModal}>
                                            <FilterOutlined />
                                        </Button>
                                        {
                                            isExport ?
                                                <Button type='primary' onClick={exportExcelWorking}>
                                                    Export
                                                </Button>
                                                :
                                                <Button type='primary' disabled onClick={exportExcelWorking}>
                                                    Export
                                                </Button>

                                        }
                                    </Space>
                                </Col>
                            </Row>
                        }>
                            <DataTable columns={workingColumn} dataSource={workingData} size="small" bordered />
                        </Card>
                    </TabPane>
                </Tabs>
            </Card>

            {
                showModalFilter ?
                    <Drawer
                        title="Filter Data"
                        width={400}
                        placement="right"
                        closable={false}
                        onClose={() => setShowModalFilter(false)}
                        visible={showModalFilter}
                    >
                        <Form form={form} onFinish={submitFilter} layout='vertical'>
                            <Form.Item label="Range Date" name="range_date">
                                <RangePicker />
                            </Form.Item>
                            <Form.Item label="Select Division" name="division">
                                <Select
                                    showSearch
                                    placeholder="Select Division"
                                    allowClear
                                    options={division}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={handleDivision}
                                />
                            </Form.Item>
                            <Space>
                                <Form.Item>
                                    <Button type='primary' htmlType='submit'>
                                        Submit
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button type='primary' danger onClick={resetFilter} >
                                        Reset
                                    </Button>
                                </Form.Item>
                            </Space>
                        </Form>

                    </Drawer>
                    : null
            }
        </div>
    )
}

export default Index