import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, PrintPdfButton, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import { toFullDate } from '../../../utils/dateHandler'
import { toIdr } from '../../../utils/currencyHandler';

const { Text } = Typography

const Detail = () => {
    const { recruitmentId } = useParams()
    const [recruitment, setRecruitment] = useState(null)

    const fetchRecruitment = (id) => {
        SentralModel.get('SubmissionRecruitment', {}, id).then((res) => {
            setRecruitment(res.data)
        })
    }

    useEffect(() => {
        fetchRecruitment(recruitmentId)
    }, [recruitmentId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/recruitment/' + recruitmentId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Recruitment" breadcrumbs={[['Recruitment', '/recruitment'], ['Detail']]}></PageTitle>
            </div>
            {
                (recruitment) ?
                    <div>
                        <Row className="mb-3">
                            <Col span={24} >
                                <PrintPdfButton right onPrint={printData} />
                            </Col>
                        </Row>
                        <DetailHeader
                            name={recruitment.employee.name}
                            date={recruitment.created_at}
                            status={recruitment.status} />
                        <Card bordered className="mb-4">
                            <Text strong>RECRUITMENT DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Requester</th><td>{recruitment.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Subject</th><td>{recruitment.subject}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{recruitment.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{recruitment.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Vacancies Status</th><td>{recruitment?.vacancies_status ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Employment Status</th><td>{recruitment?.employment_status ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Level</th><td>{recruitment?.level ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Work Date</th><td>{recruitment?.work_date ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Recruitment Date</th>
                                                <td>
                                                    {toFullDate(recruitment.from_date)} - {toFullDate(recruitment.to_date)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Work Experience</th><td>{recruitment.min_work_experience} {recruitment.min_work_experience > 1 ? 'years' : 'year'}</td>
                                            </tr>
                                            <tr>
                                                <th>Education</th><td>{recruitment.education.description} {recruitment.min_education}</td>
                                            </tr>
                                            <tr>
                                                <th>Age Limit</th><td>{recruitment.age_limit} years</td>
                                            </tr>
                                            <tr>
                                                <th>Quota</th><td>{recruitment.quota}</td>
                                            </tr>
                                            <tr>
                                                <th>Work Location</th><td>{recruitment.location.province_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Marital Status</th><td>{recruitment?.marital_status ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Salary Type</th><td>{recruitment?.salary_type ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Nominal</th><td>{toIdr(recruitment?.nominal ?? 0)}</td>
                                            </tr>
                                            <tr>
                                                <th>Health Allowance</th><td>{toIdr(recruitment?.health_allowance ?? 0)}</td>
                                            </tr>
                                            <tr>
                                                <th>Transport Allowance</th><td>{toIdr(recruitment?.transport_allowance ?? 0)}</td>
                                            </tr>
                                            <tr>
                                                <th>Food Allowance</th><td>{toIdr(recruitment?.food_allowance ?? 0)}</td>
                                            </tr>
                                            <tr>
                                                <th>Communication Allowance</th><td>{toIdr(recruitment?.communication_allowance ?? 0)}</td>
                                            </tr>
                                            <tr>
                                                <th>Working Days</th><td>{recruitment?.working_days ?? "-"}</td>
                                            </tr>
                                            <tr>
                                                <th>Working Time</th><td>{`${recruitment?.start_working_time ?? "00:00"} - ${recruitment?.end_working_time ?? "00:00"}`}</td>
                                            </tr>
                                            <tr>
                                                <th>Experience</th><td>{recruitment?.experience ?? 0}</td>
                                            </tr>
                                            <tr>
                                                <th>Skill</th><td>{recruitment?.skill ?? 0}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ verticalAlign: 'top' }}>Requirement</th>
                                                <td style={{ whiteSpace: 'pre-line', textAlign: 'justify' }}>{recruitment.requirement}</td>
                                            </tr>
                                            <tr>
                                                <th style={{ verticalAlign: 'top' }}>Job Description</th>
                                                <td style={{ whiteSpace: 'pre-line', textAlign: 'justify' }}>{recruitment.job_description}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category="RECRUITMENT"
                                approvers={recruitment.approver} />
                        </Card>
                    </div> : <Spinner />
            }

        </Card>
    );
}

export default Detail;
