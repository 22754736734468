import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Descriptions, Divider, Form, Input, Modal, Row, Select, Space, Spin, TimePicker, message } from 'antd';
import { DeleteOutlined, EnvironmentTwoTone } from '@ant-design/icons';
import { BasicModal, DataTable, DeleteButton, FormModal, PageTitle, ShowButton, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import dayjs from 'dayjs';
import {
    changeMeasurement,
    changeModalCompany,
    changeModalWorkingLocation,
    deleteWorkingLocation,
    fetchCompany,
    fetchMeasurement,
    fetchWorkingLocationByDate,
    saveCompany,
    saveWorkingLocation,
    showAddWorkingLocation,
    showModalCompany,
} from '../../../redux/slices/Dashboard/workingLocationSlice';

const { Option } = Select
const { RangePicker } = TimePicker

const Index = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(true);
    const [periods, setPeriods] = useState([]);
    const [stagings, setStagings] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [attendanceId, setAttendanceId] = useState(null);
    const [divisionId, setDivisionId] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [formModal, setFormModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [employee, setEmployee] = useState("")
    const [datas, setDatas] = useState({})
    const listWorkingLocationByDate = useSelector((state) => state.workingLocation.listWorkingLocationByDate)
    const companies = useSelector((state) => state.workingLocation.companies)
    const measurements = useSelector((state) => state.workingLocation.measurements)
    const measurement = useSelector((state) => state.workingLocation.measurement)
    const modalAddWorkingLocation = useSelector((state) => state.workingLocation.modalAddWorkingLocation)
    const modalWorkingLocationTitle = useSelector((state) => state.workingLocation.modalWorkingLocationTitle)
    const defaultValuesWorking = useSelector((state) => state.workingLocation.defaultValuesWorking)
    const modalCompany = useSelector((state) => state.workingLocation.modalCompany)
    const date = useSelector((state) => state.workingLocation.date)
    const itDivision = [13, 24, 25, 33, 34, 36]

    const fetchEmployee = () => {
        SentralModel.list('Employee', { filter: [['is_active', '=', '1'], ['employee_status', ['ESPR', 'ESPB', 'ESKN']]], orderBy: 'name', order: 'asc' }).then((res) => {
            let arr = []
            res.data.forEach((el) => {
                if (itDivision.includes(el.division_id)) {
                    arr.push({ value: el.attendance_id, label: el.name, division_id: el.division_id, employee_code: el.employee_code })
                }
            })
            setEmployees(arr)
        })
    }

    const fetchPeriods = (year) => {
        setLoaded(false)
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'desc' }).then(res => {
            setLoaded(true)
            setPeriods(res.data)
        })
    }

    const changePeriod = (value) => {
        setLoaded(false);
        SentralModel.action('Attendance', 'logAttendance', { attendance_id: attendanceId, division_id: divisionId, period_id: value }, 0).then(res => {
            setLoaded(true)
            setSelectedPeriod(value);
            getLog(value);
        })
    }

    const getLog = (value) => {
        setLoaded(true)
        SentralModel.action('Attendance', 'manualAttendanceRequest', { attendance_id: attendanceId, period_id: value }).then(res => {
            setStagings(res.data)
            setLoaded(true)
        })
    }

    const disabledDate = (current) => {
        let currentMonth = dayjs(Date.now()).format('MM');
        let addYear = 1;

        if (currentMonth === '12') {
            addYear = 2;
        }

        let customDate = dayjs(new Date()).add(addYear, 'y').format('YYYY');
        return current && current > dayjs(customDate, "YYYY");
    };

    const onChangeYear = (v) => {
        let year = dayjs(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const onChangeEmployee = (v) => {
        const emp = employees.filter((el) => el.value === v)
        setEmployee(emp[0].employee_code)
        setAttendanceId(v)
        setDivisionId(emp[0].division_id)
    }

    const fetchWorkingLocation = (v) => {
        dispatch(fetchWorkingLocationByDate({
            employee_code: employee,
            date: v
        }))
    }

    useEffect(() => {
        fetchEmployee()
        dispatch(fetchCompany())
        dispatch(fetchMeasurement())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const dataSource = [
        ...stagings.map(el => {
            return {
                employee_attendance_request_id: el.employee_attendance_request_id,
                attendance_date: el.attendance_date,
                attendance_time: el.attendance_time,
                information: el.information,
                remarks: el.remarks,
                source: el.source
            }
        })
    ];

    const columns = [
        {
            title: 'Attendance Date',
            key: 'attendance_date',
            align: 'center',
            render: (row) => (
                <div className="text-center">
                    {dayjs(row.attendance_date).format('DD MMM YYYY')}
                </div>
            )
        },
        {
            title: 'Attendance Time',
            key: 'attendance_time',
            dataIndex: 'attendance_time',
            align: 'center',
        },
        {
            title: 'Information',
            key: 'information',
            dataIndex: 'information',
            align: 'center',
        },
        {
            title: 'Remark',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
            align: 'center',
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    < ShowButton size="small" onShow={() => onShowModalDetail(row)} />
                    < Button type='link' onClick={() => onShowModalWorkingLocation(row.date)} icon={< EnvironmentTwoTone style={{ fontSize: 14 }} />} />
                </Space >
            )
        },
    ];

    const columnLocations = [
        {
            title: "Company Name",
            key: "company_name",
            render: (row) => (
                row?.company?.company_name
            ),
            align: "center"
        },
        {
            title: "Time",
            key: "time",
            render: (row) => (
                `${row.time_start} - ${row.time_end}`
            )
        },
        {
            title: "Action",
            key: "action",
            render: (row) => (
                row.index === 0 || dayjs(Date.now()).format("HH:mm") > "09:00" ?
                    <Space>
                        <Button disabled type='link' icon={<DeleteOutlined />} />
                    </Space>
                    :
                    <Space>
                        <DeleteButton onConfirm={() => onDelete(row)} />
                    </Space>
            ),
            align: "center",
        }
    ];

    const onShowModalDetail = (v) => {
        setDatas(v)
        setFormModal(true)
        setModalTitle("Manage Attendance Detail")
    }

    const updateAttendance = (v) => {
        setSaving(true)
        let payload = {
            status: v.status,
        }
        SentralModel.action('Attendance', 'updateManualAttIt', payload, v.employee_attendance_request_id).then(() => {
            setFormModal(false)
            setLoaded(false)
            if (selectedPeriod) {
                getLog(selectedPeriod);
            }
            setSaving(false)
            message.success('Absence has been updated', 2);
        })
    }

    const addCompany = (v) => {
        let payload = {
            company_name: v.company_name,
            address: v.address,
            email: v.email,
            phone_no: v.phone_no,
            phone_no_2: v.phone_no_2
        }
        dispatch(saveCompany(payload)).then(() => {
            form.resetFields()
            dispatch(fetchCompany())
        })
    }

    const onDelete = (v) => {
        dispatch(deleteWorkingLocation(v.working_location_id))
    }

    const submitWorkingLocation = (v) => {
        let payload = {
            ...v,
            working_location_id: 0,
            date: date,
            employee_code: employee,
            time_start: dayjs(v.time[0]).format("HH:mm"),
            time_end: dayjs(v.time[1]).format("HH:mm")
        }

        dispatch(saveWorkingLocation(payload)).then(() => {
            fetchWorkingLocation(date)
        })
    }

    const onShowModalWorkingLocation = (v) => {
        dispatch(showAddWorkingLocation(v))
        fetchWorkingLocation(v)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Manage Absence" breadcrumbs={[['Manage Absence']]}></PageTitle>
                <Row justify="end" className="mb-3">
                    <Col>
                        <Space>
                            <Select
                                style={{ width: 200 }}
                                showSearch
                                placeholder="Select Employee"
                                options={employees}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(v) => onChangeEmployee(v)}
                            />
                            <DatePicker style={{ width: 200 }} disabled={attendanceId === null ? true : false} picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                            <Select disabled={attendanceId === null ? true : false} placeholder="Select Period" style={{ width: 200 }} onChange={changePeriod}>
                                {
                                    periods.map((el, key) => (
                                        <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                    ))
                                }
                            </Select>
                        </Space>
                    </Col>
                </Row>

                {
                    loaded ?
                        <DataTable columns={columns} dataSource={dataSource} bordered={false} search={false} size="small" />
                        : <Spinner />
                }

                {
                    (formModal) ?
                        <Modal title={modalTitle} width={700} visible={formModal} onCancel={() => setFormModal(false)} footer={
                            <Button onClick={() => setFormModal(false)}>
                                Close
                            </Button>
                        }>
                            <div className='mb-3'>
                                <Descriptions
                                    bordered
                                    size="small"
                                >
                                    <Descriptions.Item label="Attendance Date" span={2}>
                                        {dayjs(datas.attendance_date).format('DD MMM YYYY')}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Attendance Time">{datas.attendance_time}</Descriptions.Item>
                                    <Descriptions.Item label="Information" span={2}>{datas.information}</Descriptions.Item>
                                    <Descriptions.Item label="Remarks">{datas.remarks}</Descriptions.Item>
                                </Descriptions>
                            </div>
                            <Row justify='end'>
                                <Col>
                                    <Space>
                                        <Button type='primary' disabled={saving ? true : false} onClick={
                                            () =>
                                                updateAttendance({ status: 'approve', employee_attendance_request_id: datas.employee_attendance_request_id })
                                        }>
                                            {
                                                saving ? <Spin />
                                                    : "Approve"
                                            }
                                        </Button>
                                        <Button type='primary' danger disabled={saving ? true : false} onClick={
                                            () =>
                                                updateAttendance({ status: 'reject', employee_attendance_request_id: datas.employee_attendance_request_id })
                                        }>
                                            {
                                                saving ? <Spin />
                                                    : "Reject"
                                            }
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Modal>
                        :
                        null
                }
            </Card>

            {
                modalAddWorkingLocation ?
                    <BasicModal title={modalWorkingLocationTitle} width={800} showModal={modalAddWorkingLocation} onCloseModal={() => dispatch(changeModalWorkingLocation(false))}>
                        <div style={{ marginBottom: 10 }}>
                            <DataTable columns={columnLocations} dataSource={listWorkingLocationByDate} pagination={false} search={false} size="small" />
                        </div>
                        <Divider />
                        <Form layout='vertical' initialValues={defaultValuesWorking} onFinish={submitWorkingLocation}>
                            <Form.Item name="working_location_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Job Category" name="measurement_id" required rules={[{ required: true }]}>
                                <Select placeholder="Select category" style={{ width: "100%" }} onChange={(v) => dispatch(changeMeasurement(v))}>
                                    {
                                        measurements.map((el, key) => (
                                            <Option key={key} value={el.measurement_id}>{el.title}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            {
                                measurement === 1 || measurement === 6 ?
                                    <Row justify='space-between'>
                                        <Col span={17}>
                                            <Form.Item label="Company" name="company_id" required rules={[{ required: true }]}>
                                                <Select showSearch allowClear placeholder="Select company" style={{ width: "100%" }} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {
                                                        companies.map((el, key) => (
                                                            <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <div style={{ marginTop: 30 }}>
                                                <Button type='primary' onClick={() => dispatch(showModalCompany())}>Add Company</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    : null
                            }
                            <Form.Item label="Description" name="description" >
                                <Input.TextArea placeholder='Description' rows={4} />
                            </Form.Item>
                            <Form.Item label="Time" name="time" required rules={[{ required: true }]}>
                                <RangePicker format="HH:mm" style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary' htmlType='submit'>
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </BasicModal>
                    : null
            }

            {
                modalCompany ?
                    <FormModal form={form} title="Add Company" submitForm={(v) => addCompany(v)} width={600} showModal={modalCompany} onCloseModal={() => dispatch(changeModalCompany(false))} >
                        <Form.Item label="Company Name" name="company_name" required rules={[{ required: true }]}>
                            <Input placeholder='Company Name' />
                        </Form.Item>
                        <Form.Item label="Company Email" name="email" required rules={[{ required: true }]}>
                            <Input type='email' placeholder='Company Email' />
                        </Form.Item>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Phone 1" name="phone_no">
                                    <Input placeholder='Phone 1' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Phone 2" name="phone_no_2">
                                    <Input placeholder='Phone 2' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Address" name="address" required rules={[{ required: true }]}>
                            <Input placeholder='Address' />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div>
    )
}

export default Index