import { Button, Card, Col, DatePicker, Form, Input, Modal, Progress, Row, Select, Spin, Typography, message } from 'antd'
import { DataTable, DeleteButton } from '../../../../../../components'
import { DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
    onClickAddProgress,
    closeEditTask,
    onClickShowModalAddProgress,
    removeProgressTask,
    deleteTaskProgress,
    fetchListProgressTask,
    showModalConfirm,
    updateTaskProgress,
    fetchMyTask,
    updateMilestoneProgress,
    showLogTimeProgress
} from '../../../../../../redux/slices/Dashboard/myTaskSlice'
import { fetchDailyActivities } from '../../../../../../redux/slices/Dashboard/myDailyActivitySlice'

const { Option } = Select
const { Text, Title } = Typography

const ModalEditProgressTask = (props) => {
    const dispatch = useDispatch()
    const defaultValues = useSelector((state) => state.myTask.defaultValues);
    const editProgressTaskModal = useSelector((state) => state.myTask.editProgressTaskModal);
    const editProgressTaskModalTitle = useSelector((state) => state.myTask.editProgressTaskModalTitle);
    const defaultStat = useSelector((state) => state.myTask.defaultStat);
    const attachmentInfo = useSelector((state) => state.myTask.attachmentInfo);
    const saving = useSelector((state) => state.myTask.saving);
    const uploading = useSelector((state) => state.myTask.uploading);
    const taskProgressData = useSelector((state) => state.myTask.taskProgressData);
    const percentageTask = useSelector((state) => state.myTask.percentageTask);
    const myProgressPercentage = useSelector((state) => state.myTask.myProgressPercentage);
    const showAttachmentFinished = useSelector((state) => state.myTask.showAttachmentFinished);
    const attachmentFile = useSelector((state) => state.myTask.attachmentFile);
    const isMilestoneTask = useSelector((state) => state.myTask.isMilestoneTask);
    const isOwner = useSelector((state) => state.myTask.isOwner);
    const modalLogTimeProgress = useSelector((state) => state.myTask.modalLogTimeProgress);
    const timeProgressData = useSelector((state) => state.myTask.timeProgressData);
    // const multipleAssign = useSelector((state) => state.myTask.multipleAssign);

    const onFinish = (v) => {
        if (percentageTask === 100) {
            dispatch(showModalConfirm(v))
        } else {
            let payload = {
                ...v,
                data_from: v.data_from,
                daily_progress: taskProgressData,
                attachment: attachmentFile
            }

            if (percentageTask > 100) {
                message.warning("Total Percentage is More than 100%, Please fixed it", 5)
            } else {
                if (!isMilestoneTask) {
                    if (percentageTask === 100) {
                        dispatch(showModalConfirm(payload))
                    } else {
                        dispatch(updateTaskProgress(payload)).then(() => {
                            dispatch(fetchDailyActivities({ start_date: null, end_date: null }))
                            dispatch(fetchListProgressTask(v.all_app_task_id))
                            dispatch(fetchMyTask())
                            dispatch(closeEditTask())
                        })
                    }
                }
                if (isMilestoneTask) {
                    dispatch(updateMilestoneProgress(payload)).then(() => {
                        dispatch(fetchDailyActivities({ start_date: null, end_date: null }))
                        dispatch(fetchMyTask())
                        dispatch(closeEditTask())
                    })
                }
            }
        }
    }

    const onRemove = (v) => {
        if (v.all_progress_task_id !== 0) {
            dispatch(deleteTaskProgress(v)).then(() => {
                dispatch(fetchListProgressTask(v.all_app_task_id))
                dispatch(fetchDailyActivities({ start_date: null, end_date: null }))
            })
        } else {
            dispatch(removeProgressTask(v))
        }
    }

    // const columns = [
    //     {
    //         title: 'Employee Name',
    //         render: (row) => (
    //             row?.task_to?.name
    //         ),
    //         key: 'employee'
    //     }
    // ]

    const columnTaskProgress = [

        {
            title: 'Employee',
            render: (row) => (
                row?.employee?.name
            ),
            key: 'employee',
            align: 'center'
        },
        {
            title: "Job Description",
            dataIndex: "job_description",
            key: "job_description",
            align: "center"
        },
        {
            title: "Remarks",
            dataIndex: "remarks",
            key: "remarks",
            align: "center"
        },
        {
            title: "Progress",
            render: (row) => (
                <Button type='link' onClick={() => dispatch(showLogTimeProgress({ status: true, data: row }))}>
                    {`${row?.progress ?? 0}%`}
                </Button>
            ),
            align: "center",
            key: "progress"
        },
        {
            title: "Action",
            render: (row) => (
                defaultStat === 'show' ?
                    <Button disabled type='link' icon={<DeleteOutlined />} />
                    :
                    moment(row?.progress_date).format("YYYY-MM-DD") < moment(Date.now()).format("YYYY-MM-DD") ?
                        <Button disabled type='link' icon={<DeleteOutlined />} /> :
                        <DeleteButton onConfirm={() => onRemove(row)} />
            ),
            key: "action",
            width: "5%",
            align: "center"
        }
    ];

    const columnTimeProgress = [
        {
            title: "Date",
            render: (row) => (
                moment(row?.progress_date).format("YYYY-MM-DD")
            ),
            key: "date",
            align: "center"
        },
        {
            title: "Time",
            render: (row) => (
                row?.time_start !== null & row?.time_end !== null ?
                    `${row?.time_start} - ${row?.time_end}`
                    : '-'
            ),
            key: "time",
            align: "center"
        },
        {
            title: "Progress",
            render: (row) => (
                `${row?.progress ?? 0}%`
            ),
            align: "center",
            key: "progress"
        },
    ]

    return (
        <>
            <Modal centered title={editProgressTaskModalTitle} width={1000} visible={editProgressTaskModal} onCancel={() => dispatch(closeEditTask())} footer={null}>
                <Form layout='vertical' initialValues={defaultValues} onFinish={onFinish}>
                    <Form.Item name="all_app_task_id" hidden>
                        <Input placeholder="all_app_task_id" />
                    </Form.Item>
                    <Form.Item name="category_task_code" hidden>
                        <Input placeholder="category_task_code" />
                    </Form.Item>
                    <Form.Item name="emp_task_id" hidden>
                        <Input placeholder="id" />
                    </Form.Item>
                    <Form.Item name="employee_code" hidden>
                        <Input placeholder="Employee Code" />
                    </Form.Item>
                    <Form.Item name="data_from" hidden>
                        <Input placeholder="Data From" />
                    </Form.Item>
                    <Form.Item name="milestone_id" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="milestone_task_id" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name="start_date" hidden>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea rows={4} disabled />
                    </Form.Item>
                    <Form.Item label="Confirm Finished" name="confirm_finished">
                        <Select disabled>
                            <Option value="confirm">Only Confirm</Option>
                            <Option value="confirm_with_attachment">Confirm and Attachment</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Attachment Clause" name="attachment_clause" >
                        <Input.TextArea rows={4} disabled placeholder="Attachment Clause" />
                    </Form.Item>

                    {
                        defaultStat === 'show' ?
                            <Form.Item label="Remark Finished" name="remark_finished">
                                <Input.TextArea rows={4} disabled />
                            </Form.Item>
                            : null
                    }

                    <Form.Item>
                        {
                            attachmentInfo === null ?
                                <Button disabled onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>No Attachment</Button>
                                :
                                <Button onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>Show Attachment</Button>
                        }
                    </Form.Item>

                    {/* <div className='mb-3'>
                    <div className='mb-2'>
                        <Title level={5}>Assign To</Title>
                    </div>
                    <DataTable bordered size="small" dataSource={multipleAssign} columns={columns} pagination={false} search={false} />
                </div> */}

                    <Card style={{ marginBottom: 10 }} title={
                        <Row justify='space-between'>
                            <Col>
                                <Text>Task Progress</Text>
                            </Col>
                            <Col>
                                {
                                    defaultStat === 'show' ?
                                        showAttachmentFinished === null ?
                                            null :
                                            <Button type='primary' onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${showAttachmentFinished}`)}>Show Attachment</Button>
                                        :
                                        (taskProgressData.filter((el) => moment(el.progress_date).format("YYYY-MM-DD") === moment(Date.now()).format("YYYY-MM-DD"))).length === 0 ?
                                            <Button type="primary" onClick={() => dispatch(onClickAddProgress())}>
                                                Add Progress
                                            </Button>
                                            :
                                            myProgressPercentage === 100 ?
                                                <Button disabled type="primary" onClick={() => dispatch(onClickShowModalAddProgress(true))}>
                                                    Add Progress
                                                </Button>
                                                :
                                                <Button type="primary" onClick={() => dispatch(onClickAddProgress())}>
                                                    Add Progress
                                                </Button>
                                }
                            </Col>
                        </Row>
                    }>
                        <div className='mb-3'>
                            <DataTable size="small" columns={columnTaskProgress} dataSource={taskProgressData} bordered search={false} pagination={false} />
                        </div>
                        {
                            isOwner ?
                                null :
                                <div>
                                    <Row>
                                        <Col span={4}>
                                            <Title level={5}>
                                                My Progress:
                                            </Title>
                                        </Col>
                                        {
                                            myProgressPercentage > 100 ?
                                                <Col span={6}>
                                                    <Title level={5} style={{ color: "red" }}>
                                                        {myProgressPercentage}%
                                                    </Title>
                                                </Col>
                                                :
                                                <Col span={8}>
                                                    <Progress percent={myProgressPercentage} size="large" />
                                                </Col>
                                        }
                                    </Row>
                                </div>
                        }
                        <div>
                            <Row>
                                <Col span={4}>
                                    <Title level={5}>
                                        Total Progress:
                                    </Title>
                                </Col>
                                {
                                    percentageTask > 100 ?
                                        <Col span={6}>
                                            <Title level={5} style={{ color: "red" }}>
                                                {Math.round(percentageTask)}%
                                            </Title>
                                        </Col>
                                        :
                                        <Col span={8}>
                                            <Progress percent={percentageTask} size="large" />
                                        </Col>
                                }
                            </Row>
                        </div>
                    </Card>
                    <Row justify='end'>
                        <Col>
                            {
                                defaultStat === 'show' ? null :
                                    uploading || !saving ?
                                        <Form.Item>
                                            <Button type='primary' htmlType='submit'>Save</Button>
                                        </Form.Item>
                                        :
                                        <Form.Item>
                                            <Button disabled type='primary' htmlType='submit'><Spin size='small' /></Button>
                                        </Form.Item>
                            }
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {
                modalLogTimeProgress ?
                    <Modal title="Time Progress" width={500} visible={modalLogTimeProgress} onCancel={() => dispatch(showLogTimeProgress({ statue: false, data: null }))} footer={null}>
                        <DataTable bordered size="small" dataSource={timeProgressData} columns={columnTimeProgress} pagination={false} search={false} />
                    </Modal>
                    : null
            }
        </>
    )
}

export default ModalEditProgressTask