import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, PrintPdfButton, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';

const {Text} = Typography

const Detail = () => {
    const {voucherId} = useParams()
    const [voucher, setVoucher] = useState(null)

    const fetchVoucher = (id) => {
       SentralModel.get('SubmissionVoucher', {}, id).then((res) => {
           setVoucher(res.data)
       })
    }

    useEffect(() => {
        fetchVoucher(voucherId)
    }, [voucherId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/voucher/' + voucherId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Voucher" breadcrumbs={[['Voucher', '/voucher'], ['Detail']]}></PageTitle>
            </div>
            {
                (voucher) ? 
                <div>
                    <Row className="mb-3">
                        <Col span={24} >
                            <PrintPdfButton right onPrint={printData} />
                        </Col>
                    </Row>
                    <DetailHeader 
                        name={voucher.employee.name} 
                        date={voucher.created_at}
                        status={voucher.status}/>
                    <Card bordered className="mb-4">
                        <Text strong>VOUCHER</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{voucher.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{voucher.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{voucher.employee.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Title</th><td>{voucher.title}</td>
                                        </tr>
                                        <tr>
                                            <th>Type</th><td>{voucher.voucher_type.description}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Voucher</th><td>{voucher.total}</td>
                                        </tr>
                                        {
                                            voucher.description != null ?
                                                <tr>
                                                    <th>Description</th><td>{voucher.description}</td>
                                                </tr> 
                                            : null
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered className="mb-4">
                        <Text strong>VOUCHER DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th style={{width: '20%'}}>Voucher</th>
                                            <th>Necessity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            voucher.details.map((el, key) => (
                                                <tr key={key}>
                                                   <td style={{textAlign: 'center'}}>{key+1}</td>
                                                   <td>{el.necessity}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="VOUCHER" 
                            approvers={voucher.approver} />
                    </Card>
                </div> : <Spinner/>
            }
            
        </Card>
    );
}

export default Detail;
