import React, { useState, useEffect } from 'react';
import { Card, message } from 'antd';
import { PageTitle, Spinner } from '../../../components'
import RequestForm from './RequestForm'
import moment from 'moment'
import { SentralModel } from '../../../models/SentralModel';
import { useHistory, useParams } from 'react-router-dom'

const CreateRealization = () => {
    const history = useHistory()
    const { overtimeId } = useParams()
    const [saving, setSaving] = useState(false)
    const [overtime, setOvertime] = useState(null)
    const [validityHour, setValidityHour] = useState({});

    const fetchOvertime = (overtimeId) => {
        SentralModel.get('SubmissionOvertime', {}, overtimeId).then((res) => {
            setOvertime(res.data)
        })
    }

    const fetchValidityHour = () => {
        SentralModel.action('Submission', 'getOvertimeHour').then(res => {
            setValidityHour(res.message);
        })
    }

    useEffect(() => {
        fetchOvertime(overtimeId)
        fetchValidityHour()
    }, [overtimeId]);

    const submitOvertimeRealization = (v) => {
        if (moment(v.time_realization[0], 'HH:mm').format('HH:mm:ss') < validityHour.start_time) {
            message.error('You cannot apply for overtime below ' + moment(validityHour.start_time, 'HH:mm:ss').format('HH:mm'), 2);
        } else if (moment(v.time_realization[1], 'HH:mm').format('HH:mm:ss') > validityHour.end_time) {
            message.error('You cannot apply for overtime after ' + moment(validityHour.end_time, 'HH:mm:ss').format('HH:mm'), 2);
        } else {
            message.loading('Saving Data', 0)
            setSaving(true)
            let data = {
                overtime_id: overtimeId,
                from_date_realization: moment(v.date_realization).format('YYYY-MM-DD'),
                to_date_realization: moment(v.date_realization).format('YYYY-MM-DD'),
                from_time_realization: moment(v.time_realization[0], 'HH:mm').format('HH:mm:ss'),
                to_time_realization: moment(v.time_realization[1], 'HH:mm').format('HH:mm:ss'),
            }
            SentralModel.action('Submission', 'saveOvertimeRealization', data, 0).then(() => {
                setSaving(false)
                message.destroy()
                message.success('Overtime submitted', 1)
                setTimeout(() => {
                    history.push('/overtime');
                }, 1000);
            }).catch((err) => {
                setSaving(false)
                message.destroy()
                message.error(err.message, 2)
            })
        }
    }
    return (
        <Card className="content-container">
            <PageTitle title="Overtime Submission" breadcrumbs={[['Overtime', '/overtime'], ['Create Realization']]}></PageTitle>
            {
                (overtime) ?
                    <RequestForm onSubmit={(v) => submitOvertimeRealization(v)} saving={saving} overtimeData={overtime} />
                    : <Spinner />
            }
        </Card>
    );
}

export default CreateRealization;
