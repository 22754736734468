import moment from 'moment';
import { authHeader, uploadHeader, handleResponse } from '../helpers/response'
import { AuthModel } from './AuthModel'

export const SentralModel = {
    list,
    get,
    save,
    saveWithApproval,
    action,
    approval,
    deleteById,
    upload,
    remove,
    custom,
    dashboardData,
    getData
};


function list(model, payload) {
    setLastRequest()
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/list/` + model, requestOptions).then(handleResponse);
}
function get(model, payload, id) {
    setLastRequest()
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/get/` + model + `/${id}`, requestOptions).then(handleResponse);
}

function save(model, payload, id) {
    setLastRequest()
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/save/` + model + `/${id}`, requestOptions).then(handleResponse);
}

function saveWithApproval(model, category, payload) {
    setLastRequest()
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/save-with-approval/` + model + `/${category}`, requestOptions).then(handleResponse);
}

function action(controller, action, payload, id) {
    setLastRequest()
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/action/` + controller + `/` + action + `/${id}`, requestOptions).then(handleResponse);
}

function approval(category, id, level, code, status) {
    setLastRequest()
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: authHeader(),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/approval/${category}/${id}/${level}/${code}/${status}`, requestOptions).then(handleResponse);
}

function deleteById(model, id) {
    setLastRequest()
    const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: authHeader(),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/delete/` + model + `/${id}`, requestOptions).then(handleResponse);
}

function upload(folder, file, fileName = null) {
    setLastRequest()
    const data = new FormData();
    if (fileName !== null) {
        data.append("file", file, fileName);
    } else {
        data.append("file", file);
    }

    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: uploadHeader(),
        body: data,
    };
    return fetch(`${process.env.REACT_APP_API_URL}/upload/` + folder, requestOptions).then(handleResponse);
}

function remove(file) {
    setLastRequest()
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: authHeader(),
        body: JSON.stringify({
            file: file
        })
    };
    return fetch(`${process.env.REACT_APP_API_URL}/remove`, requestOptions).then(handleResponse);
}

function custom(type, url, payload) {
    setLastRequest()
    let requestOptions = {
        method: type,
        mode: 'cors',
        headers: authHeader(),
    };
    if (type === 'POST') {
        requestOptions.body = JSON.stringify(payload);
    }
    return fetch(`${process.env.REACT_APP_API_URL}/${url}`, requestOptions).then(handleResponse);
}

// eslint-disable-next-line
function setLastRequest() {
    let lastRequest = localStorage.getItem('lastRequest')
    const currentTime = moment(new Date())
    if (lastRequest) {
        lastRequest = moment(new Date(lastRequest))
        var diff = moment.duration(currentTime.diff(lastRequest));
        var minutes = diff.asMinutes();
        if (minutes > 1440) {
            AuthModel.logout(window.location.pathname)
        } else {
            localStorage.setItem('lastRequest', currentTime);
        }
    } else {
        localStorage.setItem('lastRequest', currentTime);
    }
}

function dashboardData(controller, action, payload, id) {
    setLastRequest()
    const requestOptions = {
        method: 'POST',
        mode: 'cors',
        headers: authHeader(),
        body: JSON.stringify(payload),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/dashboard/action/` + controller + `/` + action + `/${id}`, requestOptions).then(handleResponse);
}

function getData(model, payload, id) {
    setLastRequest()
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        body: JSON.stringify(payload),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/${model}`, requestOptions).then(handleResponse);
}