import React from 'react'

const Quarterly = (props) => {
    const { data } = props
    console.log(data)
    return (
        <div>Quarterly</div>
    )
}

export default Quarterly