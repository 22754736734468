import React from 'react';
import { Row, Col, Card, Form, Input, Button, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { SentralModel } from '../../models/SentralModel'

const Login = () => {

    const onFinish = (v) => {
        SentralModel.action('Profile', 'changePassword', v, 0).then((res) => {
            message.destroy()
            message.success('Password has been changed', 2)
            setTimeout(() => {
                window.location.href = "/"
            }, 2000);
        }).catch((err) => {
            message.destroy()
            message.error(err.message, 2)
        })
    }


    return (
        <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
            <Col span={18}>
                <Row type="flex" align="middle">
                    <Col xs={0} sm={16}>
                        <img alt="PT Sentral Mitra Informatika Tbk" style={{ width: '100%', height: '100%', padding: 10 }} src={process.env.PUBLIC_URL + '/images/bg-reset.svg'} />
                    </Col>
                    <Col xs={24} sm={8}>
                        <Card style={{ borderRadius: 10 }}>
                            <div className="full-width text-center" style={{ marginBottom: 30 }}>
                                <img alt="karajo-logo" src={process.env.PUBLIC_URL + '/images/teamwork.png'} style={{ width: 150 }} />
                            </div>
                            <Form
                                name="normal_login"
                                className="login-form"
                                size="large"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}>
                                <Form.Item name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                let error;
                                                let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
                                                if (getFieldValue('password').length < 8) {
                                                    error = 'Password minimum 8 character';
                                                } else if (regex.exec(getFieldValue('password')) == null) {
                                                    error = 'Must have atleast 1 uppercase, 1 lowercase letter, 1 special character and 1 number';
                                                } else {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error(error))
                                            },
                                        }),
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password prefix={<UserOutlined />} placeholder="Password" />
                                </Form.Item>

                                <Form.Item name="confirm_password" dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block> Change Password </Button>
                                </Form.Item>
                            </Form>
                            <Row>
                                <Col span={24} className="text-center">
                                    v 1.0.0
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <div className="full-width text-center">
                    © Copyright {new Date().getFullYear()} TeamWork HR System. All Rights Reserved.
                </div>
            </Col>
        </Row>
    );
}

export default Login;
