import { Button, Col, Descriptions, Form, Input, Modal, Row, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { changeShowEditTicket, changeTicketPresalesEmployee, fetchListTicket, fetchTicketAssign, updateTicketPresales } from '../../../../../../../redux/slices/Dashboard/karajoTicketSlice'
import moment from 'moment'

const EditModalPresales = () => {
    const dispatch = useDispatch()
    const showModalEditTicket = useSelector((state) => state.karajoTicket.showModalEditTicket)
    const info = useSelector((state) => state.karajoTicket.info)
    const employeesPresales = useSelector((state) => state.karajoTicket.employeesPresales)

    const onFinish = (v) => {
        let payload = {
            all_app_task_id: info.id,
            description: v.description,
        }

        dispatch(updateTicketPresales(payload)).then(() => {
            dispatch(fetchListTicket())
            dispatch(fetchTicketAssign())
        })
    }

    const changeEmployee = (v) => {
        dispatch(changeTicketPresalesEmployee({ employee_code: v })).then(() => {
            dispatch(fetchListTicket())
            dispatch(fetchTicketAssign())
        })
    }

    return (
        <Modal visible={showModalEditTicket} onCancel={() => dispatch(changeShowEditTicket(false))} title="Update Ticket" width={1000}
            footer={
                <Button onClick={() => dispatch(changeShowEditTicket(false))}>
                    Close
                </Button>
            }>
            <div style={{ marginBottom: 10 }}>
                <Descriptions title={
                    <Row justify='space-between'>
                        <Col span={10}>
                            Presales Ticket Info
                        </Col>
                        <Col span={13}>
                            <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Select Presales Employee"
                                onChange={changeEmployee}
                                options={employeesPresales}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Col>
                    </Row>
                } bordered>
                    <Descriptions.Item label="Company Name">{info?.company_name ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label="SLA Level">{info?.sla_level ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label="Category Request">{info?.category_request ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label="Aanwijzing Date">{moment(info?.aanwijzing_date).format("DD MMMM YYYY")}</Descriptions.Item>
                    <Descriptions.Item label="Procurement Name">{info?.procurement_name ?? "-"}</Descriptions.Item>
                    <Descriptions.Item label="Presales">{info?.multiple_task_to[0]?.task_to?.name ?? "-"}</Descriptions.Item>
                    <Descriptions.Item span={2} label="Description">{info?.description ?? "-"}</Descriptions.Item>
                </Descriptions>
            </div>

            <Form layout='vertical' onFinish={onFinish}>
                <Form.Item label="Remark Presales" name="description" required rules={[{ required: true }]}>
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Update
                    </Button>
                </Form.Item>
            </Form>
        </Modal >
    )
}

export default EditModalPresales