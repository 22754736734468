import { Button, Card, DatePicker, Form, Input, Modal, Typography, Col, Row, Progress } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { DataTable, DeleteButton } from '../../../../../../components'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeModalDailyStatus,
    fetchDailyActivities,
    fetchListProgressTask,
    removeDailyProgress,
    showAddProgress,
    updateDailyProgress
} from '../../../../../../redux/slices/Dashboard/myDailyActivitySlice'

const { Text } = Typography

const ModalEditProgressDaily = (props) => {
    const dispatch = useDispatch()
    const defaultValueEdit = useSelector((state) => state.myDailyActivity.defaultValueEdit);
    const editProgressModalTitle = useSelector((state) => state.myDailyActivity.editProgressModalTitle);
    const editProgressDailyModal = useSelector((state) => state.myDailyActivity.editProgressDailyModal);
    const attachmentInfo = useSelector((state) => state.myDailyActivity.attachmentInfo);
    const defaultStat = useSelector((state) => state.myDailyActivity.defaultStat);
    const dailyProgressData = useSelector((state) => state.myDailyActivity.dailyProgressData);

    const onFinishUpdate = (v) => {
        let payload = {
            ...v,
            data_from: "DAILY ACTIVITY",
            daily_progress: dailyProgressData
        }
        dispatch(updateDailyProgress(payload)).then(() => {
            dispatch(fetchListProgressTask(v.all_app_task_id))
            dispatch(fetchDailyActivities({ start_date: null, end_date: null }))
        })
    }

    const onRemove = (v) => {
        dispatch(removeDailyProgress(v))
    }

    const columnDailyProgress = [
        {
            title: "Job Description",
            dataIndex: "job_description",
            key: "job_description"
        },
        {
            title: "Time",
            render: (row) => (
                `${row?.time_start} - ${row?.time_end}`
            ),
            key: "time"
        },
        {
            title: "Progress",
            render: (row) => (
                row?.progress ?
                    <Progress percent={row.progress} size="small" />
                    :
                    <Progress percent={0} size="small" />
            ),
            align: "center",
            key: "progress"
        },
        {
            title: "Action",
            render: (row) => (
                defaultStat === 'show' ?
                    <Button disabled type='link' icon={<DeleteOutlined />} /> :
                    <DeleteButton onConfirm={() => onRemove(row)} />
            ),
            key: "action",
            width: "5%",
            align: "center"
        }
    ];

    return (
        <Modal centered title={editProgressModalTitle} width={800} visible={editProgressDailyModal} onCancel={() => dispatch(changeModalDailyStatus(false))} footer={null}>
            <Form layout='vertical' initialValues={defaultValueEdit} onFinish={(v) => onFinishUpdate(v)}>
                <Form.Item name="all_app_task_id" hidden>
                    <Input placeholder="all_app_task_id" />
                </Form.Item>
                <Form.Item name="emp_task_id" hidden>
                    <Input placeholder="ID" />
                </Form.Item>
                <Form.Item name="employee_code" hidden>
                    <Input placeholder="Employee Code" />
                </Form.Item>
                <Form.Item name="data_from" hidden>
                    <Input placeholder="Data From" />
                </Form.Item>
                <Form.Item name="start_date" hidden>
                    <DatePicker />
                </Form.Item>
                <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                    <Input.TextArea rows={4} disabled placeholder="Description" />
                </Form.Item>
                <Form.Item>
                    {
                        attachmentInfo === null ?
                            <Button disabled onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>No Attachment</Button>
                            :
                            <Button onClick={() => window.open(`${process.env.REACT_APP_API_FILE}/${attachmentInfo}`)}>Show Attachment</Button>
                    }
                </Form.Item>
                <Card style={{ marginBottom: 10 }} title={
                    <Row justify='space-between'>

                        <Col>
                            <Text>Daily Progress</Text>
                        </Col>
                        {
                            defaultStat === 'show' ?
                                null :
                                <Col>
                                    <Button type="primary" onClick={() => dispatch(showAddProgress())}>Add Progress</Button>
                                </Col>
                        }
                    </Row>
                }>
                    <DataTable size="small" columns={columnDailyProgress} dataSource={dailyProgressData} bordered search={false} pagination={false} />
                </Card>
                {
                    defaultStat === 'show' ?
                        null :
                        <Form.Item>
                            <Button type='primary' htmlType='submit'>Save</Button>
                        </Form.Item>
                }
            </Form>
        </Modal>
    )
}

export default ModalEditProgressDaily