import { FormModal } from '../../../../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Form, Input, Select } from 'antd'
import { changeAddTaskModal, changeConfirmFinished, changeSaving, saveCreateTask } from '../../../../../../redux/slices/Dashboard/myTaskSlice'
import moment from 'moment'

const { Option } = Select

const ModalAddTask = () => {
    const dispatch = useDispatch()
    const showModalAddTask = useSelector((state) => state.myTask.showModalAddTask)
    const titleAddTask = useSelector((state) => state.myTask.titleAddTask)
    const employees = useSelector((state) => state.myTask.employees)
    const confirmFinished = useSelector((state) => state.myTask.confirmFinished)
    const saving = useSelector((state) => state.myTask.saving)

    const onFinish = (v) => {
        dispatch(changeSaving(true))
        let payload = {
            ...v,
            deadline: moment(v.deadline),
            data_from: "TASK DAILY",
            status: "In Progress",
            confirm_finished: confirmFinished
        }

        dispatch(saveCreateTask(payload))
    }

    return (
        <FormModal title={titleAddTask} showModal={showModalAddTask} onCloseModal={() => dispatch(changeAddTaskModal(false))} width={700} submitForm={(v) => onFinish(v)} saving={saving}>
            <Form.Item label="Assign To" name="task_to">
                <Select
                    showSearch
                    mode='multiple'
                    placeholder="Select Employees"
                    options={employees}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    } />
            </Form.Item>
            <Form.Item label="Confirm Finished" name="confirm_finished" rules={[{ required: true }]}>
                <Select placeholder="Select Confirm Finished" onChange={(v) => dispatch(changeConfirmFinished(v))}>
                    <Option value="confirm">Only Confirm</Option>
                    <Option value="confirm_with_attachment">Confirm and Attachment</Option>
                </Select>
            </Form.Item>
            {
                confirmFinished === "confirm_with_attachment" ?
                    <Form.Item label="Attachment Clause" name="attachment_clause">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    : null
            }
            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item label="Deadline" name="deadline" rules={[{ required: true }]}>
                <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} />
            </Form.Item>
        </FormModal>
    )
}

export default ModalAddTask