import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import { SubmitButton, Uploader } from '../../../components'
import { SentralModel } from '../../../models/SentralModel';
const { TextArea } = Input
const { Option } = Select

const RequestForm = (props) => {
    const [form] = Form.useForm()
    const [division, setDivision] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(0)
    const [employee, setEmployee] = useState([])
    const [uploading, setUploading] = useState(false)
    const [attachmentFile, setAttachmentFile] = useState("")

    const fetchDivision = () => {
        SentralModel.list('Division', { filter: [["division_id", [13, 22, 36]]], order: "DESC" }).then((res) => {
            setDivision(res.data)
        })
    }

    const fetchEmployee = () => {
        SentralModel.list('Employee', { filter: [["is_active", "1"], ["employee_status", ["ESPR", "ESPB", "ESKN", "ESMG"]]], order: "ASC" }).then((res) => {
            setEmployee(res.data)
        })
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    const fetchReferrals = (v) => {
        SentralModel.action('Referral', 'getReferralbyID', null, v).then(res => {
            const v = res.data
            form.setFieldsValue({
                company_name: v.company_name,
                PIC_name: v.PIC_name,
                PIC_phone: v.PIC_phone,
                PIC_email: v.PIC_email,
                remarks: v.remarks,
                division_id: v.division_id,
                employee_code: v.employee_code,
                attachment: attachmentFile
            })
            setSelectedDivision(v.division_id)
            setAttachmentFile(v.attachment)
        })
    }

    useEffect(() => {
        fetchDivision()
        fetchEmployee()
        if (props.referral_id !== '0') {
            fetchReferrals(props.referral_id)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.referral_id]);

    const submitReferral = (v) => {
        let data = {
            company_name: v.company_name,
            PIC_name: v.PIC_name,
            PIC_phone: v.PIC_phone,
            PIC_email: v.PIC_email,
            remarks: v.remarks,
            division_id: v.division_id,
            employee_code: v.division_id === 13 ? null : v.employee_code,
            attachment: attachmentFile
        }
        props.onSubmit(data)
    }

    return (
        <Form form={form} size="middle" layout="vertical" onFinish={submitReferral}>
            <Row justify="center">
                <Col span={22}>
                    <Row justify="space-between">
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item name="company_name" label="Company Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="PIC_phone" label="Phone PIC" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item name="PIC_name" label="Nama PIC" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="PIC_email" label="Email PIC" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item name="division_id" label="Assign To" rules={[{ required: true }]}>
                                <Select onChange={(v) => setSelectedDivision(v)}>
                                    {
                                        division.map((el, key) => (
                                            <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="remarks" label="Remarks" rules={[{ required: true }]}>
                                <TextArea autoSize={{ minRows: 2 }} />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ paddingRight: 10 }}>
                            <Form.Item name="employee_code" label="Employee">
                                <Select
                                    disabled={selectedDivision === 0 || selectedDivision === 13 ? true : false}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        employee.filter((el) => el.division_id === selectedDivision).map((el, key) => (
                                            <Option key={key} value={el.employee_code}>{el.name}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item name="attachment" label="Attachment">
                                <Uploader folder="attachment" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={24} className="text-right">
                            <SubmitButton loading={props.saving || uploading} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
