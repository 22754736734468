import React, {useState, useEffect} from 'react';
import {List, Divider} from 'antd';
import {Spinner} from '../../components'
import { SentralModel } from '../../models/SentralModel';


const Leaves = () => {
    const [todayLeaves, setTodayLeaves] = useState(null)

    const fetchTodayLeaves = () => {
        SentralModel.action('HumanResource', 'getTodayLeaves', {}, 0).then((res) => {
            setTodayLeaves(res.data)
        })
    }

    useEffect(() => {
        fetchTodayLeaves()
    }, []);

    return (
        <div>
            {
                (todayLeaves) ? 
                <div>
                <List style={{maxHeight:'65vh', overflowY:'auto'}}>
                    {
                        todayLeaves.map((el, key)=>(
                            <div key={'leave_' + key}>
                                <List.Item style={{padding:0, margin:0, border:0, cursor:'pointer'}}>
                                    {el.employee.name} <br/>
                                    <span className="text-muted">Responsible : {el.responsible.name}</span>
                                </List.Item>
                                <Divider/>
                            </div>
                        ))
                    }
                </List>
                </div>
                : <Spinner/>
            }
            {
                (todayLeaves && todayLeaves.length===0) ? 
                    <span className="text-muted">No employee leave for today</span>
                : null
            }
        </div>
    );
}

export default Leaves;
