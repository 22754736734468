import { atom } from "recoil";

export const scoreCardState = atom({
    key: "scoreCard",
    default: {}
});

export const loadedState = atom({
    key: "loaded",
    default: false
})