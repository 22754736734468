import React, {useEffect} from 'react';
import {Row, Col, Form, Input, DatePicker} from 'antd';
import moment from 'moment'
import {SubmitButton} from '../../../components'
import authData from '../../../helpers/authData'

const RequestForm = (props) => {
    const [resignForm] = Form.useForm()

    const fetData = (form) => {
        form.setFieldsValue({
            name: authData.name,
            division: authData.division.division_name,
            position: authData.position.position_name,
            join_date: moment(authData.join_date).format('DD MMMM YYYY'),
            exit_date: moment().add(1, 'months').add(1, 'days')
        })
    }
    useEffect(() => {
        fetData(resignForm)
    }, [resignForm]);

    const submitResign = (v) => {
        let data={
            employee_code: authData.employee_code,
            exit_date: moment(v.exit_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            reason: v.reason,
            status: 'WAITING',
        }
        props.onSubmit(data)
    }

    const disabledDate = (current) => {
        return current < moment().add(1, 'months');
    }

    return (
        <Form form={resignForm} labelCol={{span:8}} wrapperCol={{span:16}} onFinish={submitResign}>
            <Row justify="center">
                <Col span={10}>
                    <Form.Item name="name" label="Name" rules={[{required:true}]}>
                        <Input placeholder="Name" disabled/>
                    </Form.Item><Form.Item name="division" label="Division" rules={[{required:true}]}>
                        <Input placeholder="Division" disabled/>
                    </Form.Item>
                    <Form.Item name="reason" label="Reason" rules={[{required:true}]}>
                        <Input placeholder="Reason"/>
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item name="join_date" label="Join Date" rules={[{required:true}]}>
                        <Input placeholder="Join Date" disabled/>
                    </Form.Item><Form.Item name="position" label="Position" rules={[{required:true}]}>
                        <Input placeholder="Position" disabled/>
                    </Form.Item>
                    <Form.Item name="exit_date" label="Exit Date" rules={[{required:true}]}>
                        <DatePicker style={{width:'100%'}} format="DD MMMM YYYY" disabledDate={disabledDate}/>
                    </Form.Item>
                </Col>
                <Col span={20} className="text-right">
                    <SubmitButton loading={props.saving}/>
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
