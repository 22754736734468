import { useState, useEffect } from 'react'
import { Card, Tabs, Descriptions, Form, Input, Button, Select, message, Tag } from 'antd'
import { BasicModal, DataTable, PageTitle, ShowButton, Spinner } from '../../components'
import { SentralModel } from '../../models/SentralModel'

const { Option } = Select

const Index = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [isReward, setIsReward] = useState(false)
    const [rewardData, setRewardData] = useState([])
    const [punishmentData, setPunishmentData] = useState([])
    const [responseData, setResponseData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [data, setData] = useState({})

    const fetchRewardPunishment = () => {
        SentralModel.action("EmployeeRewardPunishment", "getAll", 0, 0).then((res) => {
            const v = res.data
            const rd = v.filter((el) => el.category === "REWARD")
            const pn = v.filter((el) => el.category === "PUNISHMENT")

            setRewardData(rd)
            setPunishmentData(pn)
            setLoaded(true)
        })
    }

    const fetchType = () => {
        let payload = {
            filter: [["general_code", ["RW", "PNM"]]],
            orderBy: 'general_code',
            order: 'asc'
        }
        SentralModel.list('GeneralDetail', payload).then((res) => {
            setResponseData(res.data)
        })
    }

    useEffect(() => {
        fetchRewardPunishment()
        fetchType()

    }, []);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'To',
            render: (row) => (
                row?.to?.name
            ),
            key: 'to_employee',
        },
        {
            title: 'From',
            render: (row) => (
                row?.from?.name
            ),
            key: 'from_employee',
        },
        {
            title: 'Category',
            render: (row) => (
                <Tag color={row.category === "PUNISHMENT" ? "red" : "green"}>{row.category}</Tag>
            ),
            key: 'category',
            align: 'center',
        },
        {
            title: 'Sub Category',
            dataIndex: 'sub_category',
            key: 'sub_category',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Action',
            render: (row) => (
                <ShowButton onShow={() => onShowModal(row)} />
            ),
            align: 'center',
            width: '10%'
        }
    ]

    const onShowModal = (v) => {
        form.setFieldsValue({
            emp_reward_punishment_id: v.emp_reward_punishment_id
        })

        if (v.category === "REWARD") {
            setIsReward(true)
        } else {
            setIsReward(false)
        }

        setShowModal(true)
        setModalTitle("Add Feedback")
        setData(v)
    }

    const saveFeedback = (v) => {
        let payload = {
            ...v
        }

        SentralModel.action("EmployeeRewardPunishment", "addFeedback", payload, 0).then((res) => {
            message.success("Success Add Feedback")
            setShowModal(false)
            fetchRewardPunishment()
        }).catch((err) => {
            message.warning("Failed Add Feedback")
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="List Reward & Punishment" breadcrumbs={[['List Reward & Punishment']]} />
            {
                loaded ?
                    <Tabs defaultActiveKey='reward' type='card'>
                        <Tabs.TabPane tab="Reward" key="reward">
                            <DataTable size="small" columns={columns} dataSource={rewardData} bordered />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Punishment" key="punishment">
                            <DataTable size="small" columns={columns} dataSource={punishmentData} bordered />
                        </Tabs.TabPane>
                    </Tabs>
                    : <Spinner />
            }

            {
                showModal ?
                    <BasicModal showModal={showModal} title={modalTitle} onCloseModal={() => setShowModal(false)} width={900}>
                        <div className="mb-3">
                            <Descriptions bordered title="Information">
                                <Descriptions.Item label="From" span={2}>{data?.from?.name}</Descriptions.Item>
                                <Descriptions.Item label="To">{data?.to?.name}</Descriptions.Item>
                                <Descriptions.Item label="Category" span={2}>{data?.category}</Descriptions.Item>
                                <Descriptions.Item label="Sub Category">{data?.sub_category}</Descriptions.Item>
                                <Descriptions.Item label="Description" span={3}>{data?.description}</Descriptions.Item>
                            </Descriptions>
                        </div>
                        <Card title="Feedback" >
                            {
                                data.feedback.length === 0 ?
                                    <Form layout='vertical' form={form} onFinish={(v) => saveFeedback(v)}>
                                        <Form.Item name="emp_reward_punishment_id" hidden>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="Response Type" name="response_type" required rules={[{ required: true }]}>
                                            {
                                                isReward ?
                                                    <Select placeholder="Select Response Type">
                                                        {
                                                            responseData.filter((el) => el.general_code === "RW").map((i, key) => (
                                                                <Option key={key} value={i.description}>{i.description}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                                    :
                                                    <Select placeholder="Select Response Type">
                                                        {
                                                            responseData.filter((el) => el.general_code === "PNM").map((i, key) => (
                                                                <Option key={key} value={i.description}>{i.description}</Option>
                                                            ))
                                                        }
                                                    </Select>
                                            }
                                        </Form.Item>
                                        <Form.Item label="Feedback" name="feedback">
                                            <Input.TextArea rows={4} placeholder='Feedback' />
                                        </Form.Item>
                                        <Button type="primary" htmlType='submit'>Save</Button>
                                    </Form>
                                    :
                                    data.feedback.map((el, key) => (
                                        <Descriptions bordered key={key}>
                                            <Descriptions.Item span={3} label="Response Type">{el.response_type}</Descriptions.Item>
                                            <Descriptions.Item span={3} label="Feedback">{el.feedback}</Descriptions.Item>
                                        </Descriptions>
                                    ))
                            }
                        </Card>
                    </BasicModal>
                    : null
            }
        </Card>
    )
}

export default Index