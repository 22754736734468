import React from 'react';
import {Card, Tabs, Tag} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {DataTable, ShowButton} from '../../../components'
import {formatDateTime} from '../../../utils/dateFormat'
import { toIdr } from '../../../utils/currencyHandler';

const {TabPane} = Tabs;

const Index = (props) => {
    const prColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name'
        },{
            title: 'COA',
            dataIndex: 'coa_code',
            key: 'coa_code',
        },{
            title: 'Budget Planning',
            dataIndex: 'budget_planning',
            key: 'budget_planning',
        },{
            title: 'Category',
            align:'center',
            dataIndex: 'category',
            render : (text) => (
                (text==='0') ? <Tag color="green">ON BUDGET</Tag> : <Tag color="red">OVER BUDGET</Tag>
            )
        },{
            title: 'Request Date',
            dataIndex: 'date',
            key: 'date',
        },{
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/purchase-request/'+row.id} />
                </div>
            )
        }
    ];
    const prData = [
        ...props.data.map((el) => {
            return{
                id:el.request_id,
                name:el.name,
                coa_code:el.coa_code,
                status:el.status,
                budget_planning: el.description,
                category: el.is_over_budget,
                date: formatDateTime(el.created_at),
                total: toIdr(el.total_amount),
                submitted_at: formatDateTime(el.submitted_at)
            }
        })
    ]
    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={prColumn} dataSource={prData.filter(el => el.status==="WAITING")} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={prColumn} dataSource={prData.filter(el => el.status==="APPROVED")} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={prColumn} dataSource={prData.filter(el => el.status==="REJECTED")} bordered/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
