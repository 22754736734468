import React, {useEffect, useState} from 'react';
import { Checkbox, Select, Input, Button, message } from 'antd';
// import html2canvas from "html2canvas";
// import jsPdf from "jspdf";
import '../../../assets/spt.css'
import { SentralModel } from '../../../models/SentralModel';
import {formatNumber, extractNumber} from '../../../utils/currencyHandler'

function useForceUpdate(){
    /* eslint-disable */
    const [value, setValue] = useState(0);
    /* eslint-enable */
    return () => setValue(value => ++value);
}

const SptMasa = (props) => {
    const forceUpdate = useForceUpdate()
    const {pemotong, period=null} = props
    const [spt, setSpt] = useState(null)
    const [additionalTotal, setAdditionalTotal] = useState(0)
    const [additionalYear, setAdditionalYear] = useState(null)
    const [additionalMonth, setAdditionalMonth] = useState([])
    const [saving, setSaving] = useState(false)

    const changeKelebihan=(e) =>{
        let x=additionalMonth;
        if(e.target.checked){
            if(!additionalMonth.includes(e.target.name)){
                x.push(e.target.name)
            }
        }else{
            if(additionalMonth.includes(e.target.name)){
                let index = x.findIndex(el => el===e.target.name)
                x.splice(index, 1)
            }
        }
        setAdditionalMonth(x)
        forceUpdate()
    }

    const printPDF = () => {
        // const domElement = document.getElementById("spt-masa-block");
        // html2canvas(domElement)
        // .then((canvas) => {
        //     let imgWidth = 250;
        //     let imgHeight = 600;
        //     const imgData = canvas.toDataURL('img/png');
        //     // let w = window.open('about:blank');
        //     // let image = new Image();
        //     // image.src = imgData;
        //     // setTimeout(function(){
        //     //     w.document.write(image.outerHTML);
        //     // }, 0);
        //     // window.location.href = imgData;
        //     const pdf = new jsPdf('p', 'mm', 'a4');
        //     pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        //     let pageHeight= pdf.internal.pageSize.height;

        //     // Before adding new content
        //     let y = imgHeight // Height position of new content
        //     if (y >= pageHeight){
        //         pdf.addPage();
        //         y = 0 // Restart height position
        //     }
        //     pdf.save("download.pdf");
        // })
    };

    const changeTotalKelebihan = (v) =>{
        setAdditionalTotal(extractNumber(v.target.value))
    }

    const fetchSptDetail = (period) =>{
        SentralModel.action('Spt','getSptDetail', {period:period}).then((res)=>{
            setSpt(res.data)
            if(res.data.spt_masa){
                setAdditionalMonth(JSON.parse(res.data.spt_masa.additional_months))
                setAdditionalYear(res.data.spt_masa.additional_year)
                setAdditionalTotal(res.data.spt_masa.additional_total)
            }
        })
    }


    useEffect(() => {
        fetchSptDetail(period)
    }, [period]);

    const saveChange = () =>{
        setSaving(true)
        let data={
            period_id:period,
            months: JSON.stringify(additionalMonth),
            year: additionalYear,
            total: additionalTotal
        }
        SentralModel.action('Spt','saveSptMasa', data,0).then((res) => {
            setSaving(false)
            message.success('SPT Updated',2);
        })
    }

    return (
        <div>
            <div id="spt-masa-block" onClick={() => printPDF()}>
            <div style={{position:'relative'}}>
                    <img style={{width:900, border:'1px solid #ededed'}} src={process.env.PUBLIC_URL+'/images/spt/masa1.jpg'} alt="SPT Masa 1"/>
                    <span className="spt-item-masa" style={{top:208,left:120}}>{(spt) ? spt.month_period : "-"}</span>
                    <span className="spt-item-masa" style={{top:208,left:175}}>{(spt) ? spt.year_period : "-"}</span>
                    <span className="spt-item-masa" style={{top:216,left:289}}>X</span>

                    <span className="spt-item-masa" style={{top:287,left:163}}>{pemotong.npwp_no.substring(0,12)}</span>
                    <span className="spt-item-masa" style={{top:287,left:400}}>{pemotong.npwp_no.substring(13 , 16)}</span>
                    <span className="spt-item-masa" style={{top:287,left:475}}>{pemotong.npwp_no.substring(17 , 20)}</span>
                    <span className="spt-item-masa" style={{top:316,left:163}}>{pemotong.company_name}</span>
                    <div className="spt-item-masa" style={{top:345,left:163, width:685}}>{pemotong.address}</div>

                    <span className="spt-item-masa" style={{top:400,left:163}}>{pemotong.phone_no} </span>
                    <span className="spt-item-masa" style={{top:400,left:605}}>{pemotong.email}  </span>

                    <div className="spt-item-masa text-center" style={{top:537,left:465,width:'83px'}}>{(spt) ? spt.total_employee : 0}</div>
                    <div className="spt-item-masa" style={{top:537,left:552,width:'139px'}}>{(spt) ? formatNumber(spt.total_bruto) : 0}</div>
                    <div className="spt-item-masa" style={{top:537,left:716,width:'145px'}}>{(spt) ? formatNumber(spt.total_pph) : 0} </div>

                    <div className="spt-item-masa text-center" style={{top:566,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:566,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:566,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:595,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:595,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:595,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:649,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:649,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:649,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:678,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:678,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:678,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:707,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:707,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:707,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:736,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:736,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:736,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:765,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:765,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:765,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:794,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:794,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:794,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:823,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:823,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:823,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:852,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:852,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:852,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:881,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:881,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:881,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:910,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:910,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:910,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:939,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:939,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:939,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:968,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:968,left:552,width:'139px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:968,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa text-center" style={{top:997,left:465,width:'83px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:997,left:552,width:'139px'}}>{(spt) ? formatNumber(spt.total_bruto) : 0}</div>
                    <div className="spt-item-masa" style={{top:997,left:716,width:'145px'}}>{(spt) ? formatNumber(spt.total_pph) : 0} </div>

                    <div className="spt-item-masa" style={{top:1052,left:716,width:'145px'}}>0</div>

                    {
                        [...Array(12)].map((el, key)=>(
                            <div className="spt-item-masa" key={key} style={{top:1096,left:156 + (((key) * 31) +1)}}>
                                <Checkbox checked={additionalMonth.includes(key+1)} name={key+1} onChange={changeKelebihan}/>
                            </div>
                        ))
                    }
                    
                    <div className="spt-item-masa" style={{top:1094,left:564}}>
                        <Select placeholder="tahun" value={additionalYear} onChange={(v) => setAdditionalYear(v)} size="small" style={{width:80}}>
                            {
                                [2019,2020,2021].map((el, key)=>(
                                    <Select.Option key={key} value={el}>{el}</Select.Option>
                                ))
                            }
                        </Select>
                    </div>


                    <div className="spt-item-masa" style={{top:1095,left:716,width:'140px'}}>
                        <Input disabled={!additionalYear || !additionalMonth} size="middle" value={formatNumber(additionalTotal)} onChange={changeTotalKelebihan}/>
                    </div>
                    <div className="spt-item-masa" style={{top:1138,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa" style={{top:1167,left:716,width:'145px'}}>{(spt) ? formatNumber(spt.total_pph - additionalTotal) : 0}</div>

                    <div className="spt-item-masa" style={{top:1225,left:716,width:'145px'}}>0 </div>
                    <div className="spt-item-masa" style={{top:1258,left:716,width:'145px'}}>0 </div>

                    <div className="spt-item-masa" style={{top:1258,left:716,width:'145px'}}>0 </div>

                    {/* <div className="spt-item-masa text-center" style={{top:567,left:463,width:'83px',border:'1px solid #000'}}>23 </div> */}
            </div>
            <div style={{position:'relative'}}>
                <img style={{width:900, border:'1px solid #ededed'}} src={process.env.PUBLIC_URL+'/images/spt/masa2.jpg'} alt="SPT Masa 1"/>
                
                <span className="spt-item-masa" style={{top:70,left:185}}>90.289.114.2</span>
                <span className="spt-item-masa" style={{top:70,left:420}}>201</span>
                <span className="spt-item-masa" style={{top:70,left:495}}>000</span>

                <div className="spt-item-masa text-center" style={{top:199,left:465,width:'83px'}}>0 </div>
                <div className="spt-item-masa" style={{top:199,left:552,width:'139px'}}>0 </div>
                <div className="spt-item-masa" style={{top:199,left:716,width:'145px'}}>0 </div>

                <div className="spt-item-masa text-center" style={{top:228,left:465,width:'83px'}}>0</div>
                <div className="spt-item-masa" style={{top:228,left:552,width:'139px'}}>0</div>
                <div className="spt-item-masa" style={{top:228,left:716,width:'145px'}}>0</div>

                <div className="spt-item-masa text-center" style={{top:265,left:465,width:'83px'}}>0</div>
                <div className="spt-item-masa" style={{top:265,left:552,width:'139px'}}>0</div>
                <div className="spt-item-masa" style={{top:265,left:716,width:'145px'}}>0</div>

                <div className="spt-item-masa text-center" style={{top:299,left:465,width:'83px'}}>0</div>
                <div className="spt-item-masa" style={{top:299,left:552,width:'139px'}}>0</div>
                <div className="spt-item-masa" style={{top:299,left:716,width:'145px'}}>0</div>

                <div className="spt-item-masa text-center" style={{top:328,left:465,width:'83px'}}>0</div>
                <div className="spt-item-masa" style={{top:328,left:552,width:'139px'}}>0</div>
                <div className="spt-item-masa" style={{top:328,left:716,width:'145px'}}>0</div>

                <div className="spt-item-masa" style={{top:395,left:44,width:'83px'}}>X</div>
                <div className="spt-item-masa" style={{top:400,left:267,width:'83px'}}>1</div>

                <div className="spt-item-masa" style={{top:471,left:401,width:'83px'}}>X</div>
                <div className="spt-item-masa" style={{top:476,left:742,width:'83px'}}>1</div>

                <div className="spt-item-masa" style={{top:623,left:76,width:'83px'}}>X</div>
                <div className="spt-item-masa" style={{top:623,left:289,width:'83px'}}>X</div>

                <span className="spt-item-masa" style={{top:657,left:137}}>90.289.114.2</span>
                <span className="spt-item-masa" style={{top:657,left:373}}>201</span>
                <span className="spt-item-masa" style={{top:657,left:447}}>000</span>

                <span className="spt-item-masa" style={{top:690,left:137}}>Christine Herawati Hidajat</span>

                <span className="spt-item-masa" style={{top:723,left:155}}>26</span>
                <span className="spt-item-masa" style={{top:723,left:210}}>11</span>
                <span className="spt-item-masa" style={{top:723,left:265}}>2020</span>

                <span className="spt-item-masa" style={{top:756,left:155}}>Jakarta</span>

                <div className="spt-item-masa text-center" style={{top:645,left:570,width:'260px',height:'105px'}}>
                    <img style={{maxWidth:'230px',maxHeight:'110px', border:'1px solid #ededed'}} src={process.env.PUBLIC_URL+'/images/noimage.png'} alt="Signature"/>
                </div>
                <div className="spt-item-masa text-center" style={{top:755,left:580,width:'260px',height:'105px'}}>Christine Herawati Hidajat</div>
            </div>
            <br/>
            <Button loading={saving} type="primary" size="middle" onClick={() => saveChange()}>Save Change</Button>
            </div>
        </div>
    );
}

export default SptMasa;
