import React, {useEffect, useState} from 'react';
import {Card, Row, Col, Tabs, Typography, Space, Tooltip, Button} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined, FileSearchOutlined, FileDoneOutlined} from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, ShowButton, Spinner} from '../../../components'
import authData from '../../../helpers/authData'
import {SentralModel} from '../../../models/SentralModel'
import {formatDateTime} from '../../../utils/dateFormat'
import { Link } from 'react-router-dom';
import moment from 'moment'

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [resigns, setResigns] = useState(null);
    const [available, setAvailable] = useState(true)
    const [isWaiting, setIsWaiting] = useState(false)
    const [isApproved, setIsApproved] = useState(false)
    const [instruction, setInstruction] = useState('')

    const fetchResign = () => {
        let payload={
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionResign', payload).then(res=>{
            setResigns(res.data)
            setIsWaiting(res.data.filter(el => el.status==="WAITING").length === 0 ? false : true)
            setIsApproved(res.data.filter(el => el.status==="APPROVED" && el.cancellation_status!=="APPROVED").length === 0 ? false : true)
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction',{filter:[['code','SBRS']]}, null).then((res)=>{
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission','checkSchema', {category: 'RESIGN', division_id: authData.division_id}).then((res)=>{
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchResign()
        checkApprovalSchema()
        fetchInstruction()
    }, []);
    
    const resignData = (resigns) ? [
        ...resigns.map(el => {
            return { 
                id: el.sub_resign_id,
                status: el.status,
                reason: el.reason,
                exit_date: moment(el.exit_date).format('DD MMMM YYYY'),
                approver: el.approver,
                created_at: formatDateTime(el.created_at)
            }
        })
    ] : [];

    const resignColumn = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
            align:'center',
            width:'70px'
        },{
            title: 'Submit Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },{
            title: 'Description',
            dataIndex: 'reason',
            key: 'reason',
        },{
            title: 'Exit Date',
            dataIndex: 'exit_date',
            key: 'exit_date',
        },{
            title: 'Action',
            align:'center',
            width:'200px',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/resign/' + row.id} />
                    {
                        (row.status==='APPROVED' && row.cancellation_status!=="APPROVED") ?
                        <>
                            <Tooltip title="Exit Interview">
                                <Link to={'/resign/exit-interview/'+row.id}>
                                    <Button type="link" size="small" shape="circle" icon={<FileSearchOutlined style={{color:'orange'}}/>} />
                                </Link>
                            </Tooltip>
                            <Tooltip title="BAST">
                                <Link to={'/resign/bast/'+row.id}>
                                    <Button type="link" size="small" shape="circle" icon={<FileDoneOutlined style={{color:'green'}} />} />
                                </Link>
                            </Tooltip>
                        </>
                        : null
                    }
                </Space>
            )
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Resign Request" breadcrumbs={[['Resign Request']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Resign Request</Text>
                        <NewButton disabled={isWaiting ? true : isApproved ? true : !available} right to="/resign/create" title={isWaiting ? "Already Request" : isApproved ? "Already Approved" : available ? "Request Resign" : 'Approval Not Available'}/>
                    </div>
                }>
                    {
                        instruction ?
                            <Row>
                                <Col span={24} className="instruction-box">
                                    <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                                </Col>
                            </Row>
                        : <Spinner />
                    }
                </Card>
                <br/>
                {
                    (resigns) ?
                        <Tabs defaultActiveKey="waiting" type="card">
                            <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                                <DataTable size="small" columns={resignColumn} dataSource={resignData.filter(el => el.status==="WAITING")} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                                <DataTable size="small" columns={resignColumn} dataSource={resignData.filter(el => el.status==="APPROVED")} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                                <DataTable size="small" columns={resignColumn} dataSource={resignData.filter(el => el.status==="REJECTED")} bordered/>
                            </TabPane>
                        </Tabs>
                    : null
                }

            </Card>
        </div>
    );
}

export default Index;
