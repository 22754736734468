import React, {useState, useEffect} from 'react';
import { Button, Badge, Row, Col, List, Typography, Dropdown, Avatar, Divider, Space } from 'antd';
import {Link} from 'react-router-dom'
import {LogoutOutlined, SettingOutlined, MenuOutlined, MenuFoldOutlined, AlignLeftOutlined} from '@ant-design/icons'
import authData from '../helpers/authData'
import { SentralModel } from '../models/SentralModel';
import Icon from '../components/Icon'
// import {AuthModel} from '../models/AuthModel'
import {decrypt} from '../helpers/crypto'

const {Text} = Typography

const Header = (props) => {
    const [notifications, setNotifications] = useState([])
    const [user, setUser] = useState(null)

    const fetchNotifications = () =>{
        SentralModel.list('Notification',{filter:[["employee_code", authData.employee_code],["is_read","!=" ,1]], orderBy:"created_at", order:"DESC"}).then((res)=>{
            setNotifications(res.data)
        })
    }

    useEffect(() => {
        if(authData){
            fetchNotifications()
        }
        const currentUser=localStorage.getItem('currentUser') ? JSON.parse(decrypt(localStorage.getItem('currentUser'))) :''
        setUser(currentUser)
    }, []);

    const notificationClicked = (v) =>{
        SentralModel.save('Notification',{is_read:1},v.notification_id).then(()=>{
            fetchNotifications()
        }).then(()=>{
            window.location.href=v.target_url
        })
    }

    const clearNotification =() =>{
        SentralModel.action('Notification', 'clearNotification', {employee_code: authData.employee_code}, 0)
        setNotifications([])
    }

    const notificationBox = (
        <div className="dropdown-notification-box">
            {   
                (notifications.length>0) ? 
                <div>
                    <List
                        itemLayout="horizontal"
                        style={{paddingLeft:10, paddingRight:10}}
                        dataSource={notifications}
                        renderItem={item => (
                        <List.Item onClick={() => notificationClicked(item)}>
                            <div style={{width:'100%', cursor:'pointer'}}>
                                <List.Item.Meta
                                    title={item.title}
                                    description={item.description}
                                />
                            </div>
                        </List.Item>
                        )}
                    />
                    <div className="full-width text-center">
                        <Button type="link" onClick={() => clearNotification()}>
                            clear notification
                        </Button>
                    </div>
                </div>
                :
                <div className="full-width text-center">
                    <Text type="secondary">You don't have any notification</Text>
                </div>
            }
        </div>
    )
    const logout = () =>{
        // AuthModel.logout()
        localStorage.clear()
        window.location.href="https://portal.sentralapps.com/"
    }

    const changeSidebar = (v) => {
        props.setSidebar(v)
        sessionStorage.setItem('sidebar',v)
    }

    const profileBox = (
        <div className="dropdown-profile-box text-center" style={{marginRight:10}}>
            {
                (authData && authData.profile_image) ?
                <Avatar src={process.env.REACT_APP_API_FILE+'/'+authData.profile_image.photo} style={{width:70, height:70, border:'1px solid #ededed'}}/>
                :
                <Avatar src={process.env.PUBLIC_URL+'/images/user-male.png'} style={{width:70, height:70, border:'1px solid #ededed'}}/>
            }
            <br/>
            <Text strong>{(user)?user.name:''}</Text><br/>
            <Text type="secondary">{(user)?user.division.division_name:''}</Text>
            <Divider style={{marginTop:10, marginBottom:10}}/>
            <div className="full-width">
                <Row justify="space-around">
                <Link to="/profile">
                    <Button icon={<SettingOutlined />} type="primary">Profile</Button>
                </Link>
                <Button icon={<LogoutOutlined />} type="danger" onClick={() => logout()}>Log out</Button>
                </Row>
            </div>
        </div>
    )

    return (
        <Row className="mb-3">
            <Col span={18}>
                {
                    (props.display==='expanded') ?
                        <MenuOutlined style={{color:'#0698ae', fontSize:'25px', marginRight:'10px'}} onClick={()=>changeSidebar('collapsed')}/>
                    : (props.display==='collapsed') ?
                        <MenuFoldOutlined style={{color:'#0698ae', fontSize:'25px', marginRight:'10px'}} onClick={()=>changeSidebar('auto')}/>
                    : <AlignLeftOutlined style={{color:'#0698ae', fontSize:'25px', marginRight:'10px',}} onClick={()=>changeSidebar('expanded')}/>
                }
                <span style={{fontSize:'25px',fontWeight:'bold', color:'#444444', display:'inline-block'}}>Hi, {(authData) ? authData.name : null}</span>
            </Col>
            <Col span={6}>
                <div style={{float:'right', paddingRight:'10px', cursor:'pointer'}}>
                    <Space>
                        <Dropdown overlay={notificationBox}>
                            <Button type="primary" icon={
                                <Badge count={notifications.length} size="large"> <Icon name="bell"/></Badge>
                            } shape="circle" size="large"/>
                        </Dropdown>
                        <Dropdown overlay={profileBox}>
                            <Button type="primary" icon={<Icon name="user" />} shape="circle" size="large"/>
                        </Dropdown>
                    </Space>
                </div>
            </Col>
            {JSON.stringify(props.breadcrumbs) || ''}
        </Row>
    );
}

export default Header;
