import React, { useState, useEffect } from 'react';
import { Card, DatePicker, Button, Space, Drawer, Form, Select } from 'antd';
import { FileExcelOutlined, FilterOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'

const { Option } = Select;

const DailyActivity = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [employeeTodo, setEmployeeTodo] = useState([])
    const [employeeList, setEmployeeList] = useState([])
    const [year, setYear] = useState(null)
    const [employeeCode, setEmployeeCode] = useState(null)

    const fetchEmployeeTodo = (v) => {
        SentralModel.action('EmployeeTodo', 'getEmployeeTodo', { year: v.year, employee_code: v.employee_code }).then((res) => {
            const v = res.data.filter((el) => el?.employee_code !== "ADM001" && el?.employee_code !== "SMI202106001")
            setEmployeeTodo(v)
            setLoaded(true)
        })
    }

    const fetchEmployee = () => {
        SentralModel.list('Employee', { filter: [['division_id', [13, 24, 34]], ['employee_status', ['ESPR', 'ESPB', 'ESKN']]], orderBy: 'division_id', order: 'ASC' }, 0).then((res) => {
            setEmployeeList(res.data)
        })
    }

    useEffect(() => {
        fetchEmployeeTodo({ year: null, employee_code: null })
        fetchEmployee()

    }, []);

    const jobColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            align: 'center'
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            align: 'center',
            key: 'employee_name',
        }, {
            title: 'Date',
            render: (row) => (
                moment(row?.date).format('DD MMMM YYYY')
            ),
            key: 'date'
        }, {
            title: 'Time',
            align: 'center',
            render: (row) => (
                (row.time_start) ? row.time_start + '-' + row.time_end : ''
            )
        }, {
            title: 'Measurement',
            dataIndex: 'measurement',
            key: 'measurement',
            align: 'center'
        }, {
            title: 'Company',
            dataIndex: 'company_name',
            key: 'company_name',
            align: 'center'
        }, {
            title: 'Job Description',
            dataIndex: 'job_description',
            width: '400px',
            key: 'description'
        },
    ];

    const filterData = (v) => {
        let data = {
            year: moment(v.year).format('YYYY'),
            employee_code: v.employee
        }

        setYear(moment(v.year).format('YYYY'))
        setEmployeeCode(v.employee)

        fetchEmployeeTodo(data)
    }

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/dailyActivity?year=${year === null ? moment(Date.now()).format('YYYY') : year}&employee_code=${employeeCode === null ? null : employeeCode}`)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Daily Activity" breadcrumbs={[['Daily Activity']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" shape='circle' onClick={() => exportReport()} icon={<FileExcelOutlined />}></Button>
                    <Button type="primary" shape='circle' onClick={() => setShowFilter(true)} icon={<FilterOutlined />}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable scroll={{ x: 1000 }} size="small" columns={jobColumn} dataSource={employeeTodo} bordered />
                    : <Spinner />
            }


            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}
            >
                <Form form={form} layout="vertical" onFinish={filterData}>
                    <Form.Item name="year" label="Year">
                        <DatePicker style={{ width: '100%' }} picker='year' allowClear={false} />
                    </Form.Item>
                    <Form.Item name="employee" label="Employee">
                        <Select placeholder="Select Employee">
                            {
                                employeeList.map((el, key) => (
                                    <Option value={el.employee_code} key={key}>{el.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item >
                        <div className="text-right">
                            <Space>
                                <Button type="primary" htmlType="submit" size="middle" icon={<FilterOutlined />}> Show</Button>
                                <Button type='primary' danger onClick={() => {
                                    fetchEmployeeTodo({ year: null, employee_code: null })
                                    setYear(null)
                                    setEmployeeCode(null)
                                    form.resetFields()
                                }}>Reset</Button>
                            </Space>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default DailyActivity;
