import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';

const {Text} = Typography

const Detail = () => {
    const {requestId} = useParams()
    const [crf, setCrf] = useState(null)

    const fetchCrf = (id) => {
       SentralModel.get('ChangeRequestForm', {}, id).then((res)=>{
           setCrf(res.data)
       })
    }

    useEffect(() => {
        fetchCrf(requestId)
    }, [requestId]);

    const statusUpdated = () =>{
        setCrf(null)
        fetchCrf(requestId)
        window.location.reload();
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Change Request Form" breadcrumbs={[['Submission List','/submission-list'],['Detail']]}></PageTitle>
            </div>
            {
                (crf) ? 
                <div>
                    <DetailHeader 
                        name={crf.employee.name} 
                        date={crf.created_at}
                        status={crf.approval_status}/>
                    <Card bordered  className="mb-4">
                        <Text strong>CHANGE REQUEST FORM DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Name</th><td>{crf.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Email / Ext</th><td>{crf.employee.work_email} / {crf.employee.extension_no}</td>
                                        </tr>
                                        <tr>
                                            <th>Request Date</th><td>{crf.request_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Summary Request</th><td>{crf.summary_request}</td>
                                        </tr>
                                        <tr>
                                            <th>Reason</th><td>{crf.reason}</td>
                                        </tr>
                                        <tr>
                                            <th>Service Category</th><td>{crf.service_desc.description}</td>
                                        </tr>
                                        <tr>
                                            <th>Impact Category</th><td>{crf.impact_desc.description}</td>
                                        </tr>
                                        <tr>
                                            <th>Priority</th><td>{crf.priority_desc.description}</td>
                                        </tr>
                                        <tr>
                                            <th>Type</th><td>{crf.type_desc.description}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="CRF" 
                            requestId={requestId} 
                            approvers={crf.approver} 
                            onSubmitted={() => statusUpdated()}/>
                    </Card>
                </div> : <Spinner/>
            }
        </Card>
    );
}

export default Detail;
