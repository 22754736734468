import { useEffect, useState } from 'react'
import { Row, Tag } from 'antd'
import { SentralModel } from '../../../models/SentralModel'
import { DataTable } from '../../../components'
import currentUser from '../../../helpers/authData'
import moment from 'moment'
// import { useHistory } from 'react-router-dom'

const Milestone = () => {
    const [milestone, setMilestone] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchMilestone = () => {
        SentralModel.action('Milestone', 'getMilestoneByDivision', 0, currentUser.division_id).then((res) => {
            setMilestone(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchMilestone()
    }, []);

    const columns = [
        {
            title: "Due Date",
            dataIndex: "due_date",
            key: "due_date",
            align: "center",
            render: (row) => (
                <div style={{ border: '1px solid #dfe6e9' }}>
                    <div style={{ height: '20px', backgroundColor: '#0A98B4', width: '100%' }}>
                        <h3 style={{ color: 'white', fontSize: 10 }}>{moment(row.due_date).format('MMMM')}</h3>
                    </div>
                    <h1 style={{ fontSize: 24, margin: 0, color: 'grey' }}>{moment(row.due_date).format('DD')}</h1>
                    <h4 style={{ fontSize: 12, color: 'grey' }}>{moment(row.due_date).format('dddd')}</h4>
                </div>
            ),
            width: '8%'
        },
        {
            title: "Name",
            dataIndex: "milestone_name",
            key: "milestone_name",
            width: '50%'
        },
        {
            title: "Milestone Progress",
            dataIndex: "milestone_progress",
            key: "milestone_progress",
            align: "center",
            render: (row) => (
                parseInt(row) === 0 ? <Tag color="#108ee9">On Progress</Tag> : <Tag color="#87d068">Complete</Tag>
            ),
            width: '10%'
        },
    ]

    return (
        <div>
            <Row>{
                loaded ?
                    <DataTable columns={columns} dataSource={milestone} />
                    : null
            }</Row>
        </div>
    )
}

export default Milestone