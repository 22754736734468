import { Card, Col, Descriptions, Divider, List, Progress, Row } from 'antd'
import { Pie } from 'react-chartjs-2';
import moment from 'moment';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import InfiniteScroll from 'react-infinite-scroll-component';

ChartJS.register(ArcElement, Tooltip, Legend);

const Overview = ({ data, chart, log }) => {
    const dataChart = {
        labels: ['In Progress', 'Complete'],
        datasets: [
            {
                data: [chart.ipMilestone, chart.compMilestone],
                backgroundColor: [
                    '#FF990A',
                    '#00B393',
                ],
                borderColor: [
                    '#FF990A',
                    '#00B393',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Card>
            <Row justify="space-between">
                <Col span={13}>
                    <Row className='mb-4' justify="space-between">
                        <Col span={12}>
                            <Card>
                                <Row justify='center' style={{ marginBottom: 15 }}>
                                    <Progress type="circle" percent={Math.round(parseFloat(data.progress))} />
                                </Row>
                                <Row justify='center'>
                                    <Col span={24}>
                                        <Descriptions bordered>
                                            <Descriptions.Item label="Start Date" span={3}>{moment(data.start_date).format('DD MMMM YYYY')}</Descriptions.Item>
                                            <Descriptions.Item label="End Date" span={3}>{moment(data.end_date).format('DD MMMM YYYY')}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={11}>
                            <Card>
                                <Pie data={dataChart} />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Descriptions layout='vertical' bordered>
                                <Descriptions.Item label="Description">
                                    {data.short_description}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </Col>
                <Col span={10}>
                    <Card title="Activity">
                        <div
                            id="scrollableDiv"
                            style={{
                                height: 400,
                                overflow: 'auto',
                            }}
                        >
                            <InfiniteScroll
                                dataLength={log.length}
                                hasMore={log.length < 10}
                                endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                                scrollableTarget="scrollableDiv"
                            >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={log}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={`${moment(item.created_at).format('DD MMMM YYYY')}`}
                                                description={item.description} />
                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Card>
    )
}

export default Overview