import React, { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, DatePicker, Select, Typography, Space, message } from 'antd';
import { PageTitle, SubmitButton, PrintPdfButton, Spinner, EditButton, DataTable, FormModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link,
} from "suneditor/src/plugins"
import authData from '../../../helpers/authData'

const { Text } = Typography
const { TextArea } = Input;
const { Option } = Select

const Detail = () => {
    const history = useHistory()
    const { crfId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [saving, setSaving] = useState(false)
    const [form] = Form.useForm()
    const [employeeCode, setEmployeeCode] = useState(null);
    const [crfCode, setCrfCode] = useState(null);
    const [content, setContent] = useState('');
    const [category, setCategory] = useState([]);
    const [priority, setPriority] = useState([]);
    const [type, setType] = useState([]);
    const [selectedServiceCategory, setSelectedServiceCategory] = useState(null);
    const [selectedImpactCategory, setSelectedImpactCategory] = useState(null);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [status, setStatus] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [assignTo, setAssignTo] = useState([]);
    const [developers, setDevelopers] = useState([])
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})

    const fetchCRF = (crfId, form) => {
        if (crfId === '0') {
            setLoaded(false);
            form.resetFields();
            SentralModel.get('Employee', {}, authData.employee_code)
                .then(res => {
                    form.setFieldsValue({
                        division_id: authData.division_id,
                        requester_name: res.data.name,
                        email: res.data.work_email,
                        extension_no: res.data.extension_no,
                        email_ext: "" + res.data.work_email + " / " + res.data.extension_no + "",
                        request_date: moment(),
                    })
                    setEmployeeCode(authData.employee_code);
                    setStatus('WAITING');
                    setLoaded(true);
                })
        } else {
            setLoaded(false);
            SentralModel.get('ChangeRequestForm', {}, crfId)
                .then(res => {
                    form.setFieldsValue({
                        id: res.data.change_request_form_id,
                        division_id: res.data.division_id,
                        requester_name: res.data.employee.name,
                        crf_code: res.data.change_request_form_code,
                        email: res.data.employee.work_email,
                        extension_no: res.data.employee.extension_no,
                        email_ext: "" + res.data.employee.work_email + " / " + res.data.employee.extension_no + "",
                        request_date: moment(res.data.request_date, 'YYYY-MM-DD'),
                        description: res.data.description,
                        summary_request: res.data.summary_request,
                        service_category: res.data.service_category,
                        impact_category: res.data.impact_category,
                        priority: res.data.priority,
                        type: res.data.type,
                        reason: res.data.reason,
                        risk_implemented: res.data.risk_implemented,
                        risk_not_implemented: res.data.risk_not_implemented,
                        asset: res.data.asset,
                        vulnerability: res.data.vulnerability,
                        risk: res.data.risk,
                        mitigation: res.data.mitigation,
                        parameter: res.data.parameter,
                        finish_date: res.data.finish_date,
                        implementation_date: res.data.implementation_date === null ? null : moment(res.data.implementation_date, 'YYYY-MM-DD'),
                        status: res.data.status,
                        assign_to: res.data.developers.map((el) => el.employee_code)
                    })
                    setEmployeeCode(res.data.employee_code);
                    setCrfCode(res.data.change_request_form_code);
                    setContent(res.data.description);
                    setSelectedServiceCategory(res.data.service_category);
                    setSelectedImpactCategory(res.data.impact_category);
                    setSelectedPriority(res.data.priority);
                    setSelectedType(res.data.type);
                    setStatus(res.data.status);
                    setAssignTo(res.data.developers.map((el) => el.employee_code));
                    setDevelopers(res.data.developers);
                    setLoaded(true);
                })
        }
    }

    const fetchCategory = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'CC']], orderBy: 'order_key', order: 'asc' }).then(res => {
            setCategory(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchPriority = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'CP']], orderBy: 'order_key', order: 'asc' }).then(res => {
            setPriority(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchType = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'CTY']], orderBy: 'order_key', order: 'asc' }).then(res => {
            setType(res.data)
        }).finally(() => {
            // setLoaded(true)
        })
    }

    const fetchEmployees = () => {
        setLoaded(false)
        SentralModel.list('Employee', { filter: [['division_id', 24], ['is_active', '=', '1']], orderBy: 'name', order: 'asc' }).then(res => {
            setLoaded(true)
            setEmployees(res.data)
        })
    }

    useEffect(() => {
        fetchCRF(crfId, form);
        fetchCategory();
        fetchPriority();
        fetchType();
        fetchEmployees();
    }, [crfId, form]);

    const saveCRF = (v) => {
        setSaving(true)

        let data = {
            change_request_form_id: crfId,
            change_request_form_code: crfCode,
            employee_code: employeeCode,
            division_id: v.division_id,
            request_date: moment(v.request_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            description: content,
            summary_request: v.summary_request,
            reason: v.reason,
            service_category: selectedServiceCategory,
            impact_category: selectedImpactCategory,
            priority: selectedPriority,
            type: selectedType,
            risk_implemented: v.risk_implemented,
            risk_not_implemented: v.risk_not_implemented,
            asset: v.asset,
            vulnerability: v.vulnerability,
            risk: v.risk,
            mitigation: v.mitigation,
            parameter: v.parameter,
            implementation_date: v.implementation_date,
            status: status,
            assign_to: assignTo
        }

        SentralModel.action('ChangeRequestForm', 'saveCRF', data, crfId).then((res) => {
            message.success('crf saved', 2);
            setTimeout(() => {
                history.push('/crf');
            }, 1000);
            setSaving(false)
        })
    }

    const dataSource = [
        ...developers.map(el => {
            return {
                id: el.change_request_form_developer_id,
                employee_code: el.employee_code,
                employee_name: el.employee.name,
                note: el.note,
                status: el.status,
                assign_date: el.assign_date,
                finish_date: el.finish_date
            }
        })
    ];

    const column = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        },
        {
            title: 'Developer Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            render: (text) => (
                text === null ? '-' : text
            )
        },
        {
            title: 'Assign Date',
            dataIndex: 'assign_date',
            key: 'assign_date',
            render: (text) => (
                <div className="text-center">
                    {text === null ? '-' : moment(text).format('DD MMMM YYYY')}
                </div>
            )
        },
        {
            title: 'Finish Date',
            dataIndex: 'finish_date',
            key: 'finish_date',
            render: (text) => (
                <div className="text-center">
                    {text === null ? '-' : moment(text).format('DD MMMM YYYY')}
                </div>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <div className="text-center">
                    {text === 'ONPROGRESS' ? 'On Progress' : 'Finished'}
                </div>
            )
        },
    ];

    if (developers.find((el) => el.employee_code === authData.employee_code)) {
        column.push({
            title: 'Action',
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        {
                            authData.employee_code === row.employee_code ?
                                <EditButton onEdit={() => editProgress(row)} />
                                : null
                        }
                    </Space>
                </div>
            )
        })
    }

    const handleEditorChange = (content) => {
        setContent(content);
    }

    const changeServiceCategory = e => {
        setSelectedServiceCategory(e);
    }

    const changeImpactCategory = e => {
        setSelectedImpactCategory(e);
    }

    const changePriority = e => {
        setSelectedPriority(e);
    }

    const changeType = e => {
        setSelectedType(e);
    }

    const changeStatus = e => {
        setStatus(e);
    }

    const changeEmployee = e => {
        setAssignTo(e)
    }

    const editProgress = (v) => {
        setDefaultValues({
            change_request_form_developer_id: v.id,
            note: v.note,
            status: v.status
        })
        setFormModal(true)
    }

    const saveProgress = (v) => {
        setSaving(true)
        let payload = {
            change_request_form_developer_id: v.change_request_form_developer_id,
            change_request_form_id: crfId,
            note: v.note,
            status: v.status,
            finish_date: v.status === 'FINISHED' ? moment().format('YYYY-MM-DD') : null
        }
        SentralModel.action('ChangeRequestForm', 'updateDeveloper', payload, crfId).then((res) => {
            setFormModal(false);
            fetchCRF(crfId, form);
            message.success('Progress saved', 2);
        }).finally(() => {
            setSaving(false);
        })
    }

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/crf/' + crfId)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Change Request Form" breadcrumbs={[['Change Request Form', '/crf'], ['Detail']]}></PageTitle>
            {
                loaded ?
                    <div>
                        {
                            crfId !== '0' ?
                                <Row className="mb-3">
                                    <Col span={24} >
                                        <PrintPdfButton right onPrint={printData} />
                                    </Col>
                                </Row>
                                : null
                        }
                        <Form size="middle" form={form} layout="vertical" onFinish={saveCRF}>
                            <Form.Item name="change_request_form_id" hidden>
                                <Input placeholder="CRF ID" />
                            </Form.Item>
                            <Form.Item name="division_id" hidden>
                                <Input placeholder="Division ID" />
                            </Form.Item>
                            <Form.Item label="Requester Name" name="requester_name" rules={[{ required: true }]}>
                                <Input placeholder="Requester Name" disabled />
                            </Form.Item>
                            <Row justify="space-between">
                                <Col span={12} style={{ paddingRight: 10 }}>
                                    <Form.Item label="Email / Ext" name="email_ext" rules={[{ required: true }]}>
                                        <Input placeholder="Email / Ext" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 10 }}>
                                    <Form.Item label="Request Date" name="request_date" rules={[{ required: true }]}>
                                        <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ paddingBottom: 10 }}>
                                <Text style={{ color: '#ed5b56', paddingRight: 5 }}>*</Text>
                                <Text>Description</Text>
                            </Row>
                            <SunEditor setOptions={{
                                showPathLabel: false,
                                minHeight: "100vh",
                                maxHeight: "100vh",
                                placeholder: "Enter your text here!!!",
                                plugins: [
                                    align,
                                    font,
                                    fontColor,
                                    fontSize,
                                    formatBlock,
                                    hiliteColor,
                                    horizontalRule,
                                    lineHeight,
                                    list,
                                    paragraphStyle,
                                    table,
                                    template,
                                    textStyle,
                                    image,
                                    link
                                ],
                                buttonList: [
                                    ["undo", "redo"],
                                    ["font", "fontSize", "formatBlock"],
                                    ["paragraphStyle"],
                                    [
                                        "bold",
                                        "underline",
                                        "italic",
                                        "strike",
                                        "subscript",
                                        "superscript"
                                    ],
                                    ["fontColor", "hiliteColor"],
                                    ["removeFormat"],
                                    "/", // Line break
                                    ["outdent", "indent"],
                                    ["align", "horizontalRule", "list", "lineHeight"],
                                    ["table", "link", "image"]
                                ],
                                formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
                                font: [
                                    "Arial",
                                    "Calibri",
                                    "Comic Sans",
                                    "Courier",
                                    "Garamond",
                                    "Georgia",
                                    "Impact",
                                    "Lucida Console",
                                    "Palatino Linotype",
                                    "Segoe UI",
                                    "Tahoma",
                                    "Times New Roman",
                                    "Trebuchet MS"
                                ]
                            }} height={500} setContents={content} onChange={(value) => handleEditorChange(value)} />
                            <Row justify="space-between" style={{ paddingTop: 20 }}>
                                <Col span={12} style={{ paddingRight: 10 }}>
                                    <Form.Item label="Summary Request" name="summary_request" rules={[{ required: true }]}>
                                        <TextArea rows={5} placeholder="Summary" />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 10 }}>
                                    <Form.Item label="Reason" name="reason" rules={[{ required: true }]}>
                                        <TextArea rows={5} placeholder="Why this request is needed" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={12} style={{ paddingRight: 10 }}>
                                    <Form.Item label="Risk Implemented" name="risk_implemented" rules={[{ required: true }]}>
                                        <TextArea rows={5} placeholder="Risk if this request implemented" />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 10 }}>
                                    <Form.Item label="Risk Not Implemented" name="risk_not_implemented" rules={[{ required: true }]}>
                                        <TextArea rows={5} placeholder="Risk if this request not implemented" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={6} style={{ paddingRight: 10 }}>
                                    <Form.Item label="Asset" name="asset">
                                        <TextArea rows={4} placeholder="Asset" />
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <Form.Item label="Vulnerability" name="vulnerability">
                                        <TextArea rows={4} placeholder="Vulnerability" />
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <Form.Item label="Risk" name="risk">
                                        <TextArea rows={4} placeholder="Risk" />
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ paddingLeft: 10 }}>
                                    <Form.Item label="Mitigation" name="mitigation">
                                        <TextArea rows={4} placeholder="Mitigation" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={6} style={{ paddingRight: 10 }}>
                                    <Form.Item label="Service Category" name="service_category" rules={[{ required: true }]}>
                                        <Select placeholder="Select Category" onChange={changeServiceCategory}>
                                            {
                                                category.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    <Form.Item label="Impact Category" name="impact_category" rules={[{ required: true }]}>
                                        <Select placeholder="Select Category" onChange={changeImpactCategory}>
                                            {
                                                category.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 10 }}>
                                    <Form.Item label="Priority" name="priority" rules={[{ required: true }]}>
                                        <Select placeholder="Select Priority" onChange={changePriority}>
                                            {
                                                priority.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col span={12} style={{ paddingRight: 10 }}>
                                    <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                                        <Select placeholder="Select Type" onChange={changeType}>
                                            {
                                                type.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 10 }}>
                                    <Form.Item label="Parameter" name="parameter">
                                        <Input placeholder="Parameter" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                crfId !== '0' ?
                                    authData.division_id === 24 ?
                                        <>
                                            <Row justify="space-between">
                                                <Col span={12} style={{ paddingRight: 10 }}>
                                                    <Form.Item label="Implementation Schedule" name="implementation_date">
                                                        <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12} style={{ paddingLeft: 10 }}>
                                                    <Form.Item label="Status" name="status">
                                                        <Select placeholder="Select Status" onChange={changeStatus}>
                                                            <Option value="OPEN" hidden>Open</Option>
                                                            <Option value="ONPROGRESS" hidden>On Progress</Option>
                                                            <Option value="FINISHED" hidden>Finish</Option>
                                                            {
                                                                status === "FINISHED" || status === "CLOSED" ?
                                                                    <>
                                                                        <Option value="REVISION">Revision</Option>
                                                                        <Option value="CLOSED">Close</Option>
                                                                    </> :
                                                                    <>
                                                                        <Option value="HOLD">Hold</Option>
                                                                        <Option value="REJECTED">Reject</Option>
                                                                    </>
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row justify="space-between">
                                                <Col span={12} style={{ paddingRight: 10 }}>
                                                    <Form.Item label="Assign To" name="assign_to">
                                                        <Select mode='multiple' showSearch allowClear placeholder="Select employee" optionFilterProp="children" onChange={changeEmployee} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {
                                                                employees.map((el, key) => (
                                                                    <Option key={key} value={el.employee_code}>{el.name}</Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                        : null
                                    : null
                            }

                            {
                                authData.division_id !== 24 ?
                                    status === 'WAITING' ?
                                        <Row justify="space-around">
                                            <Col span={24} className="text-right">
                                                <SubmitButton loading={saving} />
                                            </Col>
                                        </Row>
                                        : null
                                    : <Row justify="space-around">
                                        <Col span={24} className="text-right">
                                            <SubmitButton loading={saving} />
                                        </Col>
                                    </Row>
                            }
                        </Form>

                        <br />

                        {
                            crfId !== '0' ?
                                authData.division_id === 24 ?
                                    <DataTable size="small" search={false} columns={column} dataSource={dataSource} bordered />
                                    : null
                                : null
                        }

                        {
                            (formModal) ?
                                <FormModal defaultValues={defaultValues} title={'Edit Progress'} submitForm={(v) => saveProgress(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                                    <Form.Item name="change_request_form_developer_id" hidden>
                                        <Input placeholder="Developer ID" />
                                    </Form.Item>
                                    <Form.Item label="Note" name="note" required rules={[{ required: true }]}>
                                        <Input placeholder="Note" />
                                    </Form.Item>
                                    <Form.Item label="Status" name="status" rules={[{ required: true }]}>
                                        <Select placeholder="Select Status" onChange={changeStatus}>
                                            <Option value="ONPROGRESS" hidden>On Progress</Option>
                                            <Option value="FINISHED">Finish</Option>
                                        </Select>
                                    </Form.Item>
                                </FormModal> :
                                null
                        }
                    </div>
                    : <Spinner />
            }
        </Card>
    );
}

export default Detail;
