import React from 'react'
import { Button, Empty, Space } from 'antd'
import { Link } from 'react-router-dom'
import { UsergroupAddOutlined, FormOutlined, ZoomInOutlined, BarsOutlined } from '@ant-design/icons'
import authData from '../../../../helpers/authData'

const Table = ({ roles, appraisals, division }) => {
    return (
        <div>
            <table className="table-collapse table-default" style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Employee Name</th>
                        <th>Division Name</th>
                        {
                            (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                <th>Evaluator</th>
                                : null
                        }
                        <th className="text-center">KPI</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        appraisals.map((el, i) => (
                            <tr key={i}>
                                <td style={{ verticalAlign: 'top', textAlign: 'center' }}>{i + 1}</td>
                                <td style={{ verticalAlign: 'top' }}>{el.name}</td>
                                <td style={{ verticalAlign: 'top' }}>{(el.division) ? el.division.division_name : ''}</td>
                                {
                                    (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                        <td style={{ padding: '0px' }}>
                                            {
                                                (el.pra_appraisal) ?
                                                    <table className="table-noborder" style={{ width: '100%', margin: '0px' }}>
                                                        <tbody>
                                                            {
                                                                el.pra_appraisal.evaluators.map((ev, x) => (
                                                                    <tr key={x}>
                                                                        <td>{ev.employee.name}</td>
                                                                        <td className="text-center" style={{ width: '70px' }}>{ev.kpi_value}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    : null
                                            }
                                        </td>
                                        : null
                                }
                                <td className="text-center" style={{ verticalAlign: 'top' }}>
                                    {
                                        (el.pra_appraisal) ?
                                            (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                el.pra_appraisal.kpi_value?.toFixed(2) : el.pra_appraisal.evaluators[0].kpi_value?.toFixed(2)
                                            : ''
                                    }
                                </td>
                                <td style={{ width: '15%', verticalAlign: 'top', padding: 0, paddingTop: '5px' }}>
                                    <Space>
                                        {
                                            (roles.includes('HR') || roles.includes('SUPERADMIN')) ?
                                                (!el.pra_appraisal) ?
                                                    <div>
                                                        <Link to={'/management/pra-appraisal/set-item/' + el.employee_code}>
                                                            <Button type="link" icon={<BarsOutlined />} />
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div>
                                                        {
                                                            (!el.pra_appraisal.kpi_value) ?
                                                                <div>
                                                                    <Link to={'/management/pra-appraisal/set-item/' + el.employee_code}>
                                                                        <Button type="link" icon={<BarsOutlined />} />
                                                                    </Link>
                                                                    <Link to={'/management/pra-appraisal/set-evaluator/' + el.pra_appraisal.kpi_pra_appraisal_id}>
                                                                        <Button type="link" icon={<UsergroupAddOutlined />} />
                                                                    </Link>
                                                                    {
                                                                        (el.pra_appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0) ?
                                                                            <Link to={'/management/pra-appraisal/form-appraisal/' + el.pra_appraisal.kpi_pra_appraisal_id}>
                                                                                <Button type="link" icon={<FormOutlined />} />
                                                                            </Link>
                                                                            : null
                                                                    }
                                                                    {
                                                                        (el.pra_appraisal.evaluators.length > 0) ?
                                                                            <Link to={'/management/pra-appraisal/detail?division=' + division + '&appraisalId=' + el.pra_appraisal.kpi_pra_appraisal_id}>
                                                                                <Button type="link" icon={<ZoomInOutlined />} />
                                                                            </Link>
                                                                            : null
                                                                    }
                                                                </div>
                                                                :
                                                                (el.pra_appraisal.evaluators.length > 0) ?
                                                                    <Link to={'/management/pra-appraisal/detail?division=' + division + '&appraisalId=' + el.pra_appraisal.kpi_pra_appraisal_id}>
                                                                        <Button type="link" icon={<ZoomInOutlined />} />
                                                                    </Link>
                                                                    : null
                                                        }
                                                    </div>
                                                :
                                                (el.pra_appraisal.evaluators.filter((x => x.evaluator_code === authData.employee_code)).length > 0 && !el.pra_appraisal.kpi_value) ?
                                                    <Link to={'/management/pra-appraisal/form-appraisal/' + el.pra_appraisal.kpi_appraisal_id}>
                                                        <Button type="link" icon={<FormOutlined />} />
                                                    </Link>
                                                    : <span className="text-muted" style={{ paddingLeft: 5 }}>Penilaian sudah dikunci</span>
                                        }
                                    </Space>
                                </td>
                            </tr>
                        ))
                    }
                    {
                        (appraisals.length === 0) ?
                            <tr><td colSpan={5}><Empty /></td></tr> : null
                    }
                </tbody>

            </table>
        </div>
    )
}

export default Table