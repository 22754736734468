import { Button, Space, Tag } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons'
import { DataTable } from '../../../../components';

const ActionProgress = ({ data }) => {
    const actionPlanColumn = [
        {
            title: 'Divison Name',
            dataIndex: 'division',
            key: 'division',
            className: 'text-top',
            fixed: 'left',
            align: 'center'
        },
        {
            title: 'Total Action Plan',
            dataIndex: 'total_action_plan',
            key: 'total_action_plan',
            align: 'center'
        },
        {
            title: 'Action Plan Overdue',
            dataIndex: 'overdue',
            key: 'overdue',
            render: (row) => (
                row === 0 ? <Tag color="#87d068">{row}</Tag> : <Tag color="#f50">{row}</Tag>
            ),
            align: 'center'
        },
        {
            title: 'Action Plan Realization',
            dataIndex: 'realization',
            key: 'realization',
            align: 'center'
        },
        {
            title: 'Action Plan Progress',
            dataIndex: 'action_plan_progress',
            key: 'action_plan_progress',
            render: (row) => (
                `${row.toFixed(2)}%`
            ),
            align: 'center'
        },
    ];

    const exportReport = () => {
        window.open(`${process.env.REACT_APP_API_URL}/report/actionPlanProgress`)
    }

    return (
        <div>
            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} icon={<FileExcelOutlined />}>Export</Button>
                    {/* <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button> */}
                </Space>
            </div>
            <DataTable size="small" columns={actionPlanColumn} dataSource={data} bordered />
        </div>
    )
}

export default ActionProgress