import { Button, Popover, Progress, Typography } from 'antd'
import moment from 'moment'

const { Text } = Typography

const TableGantt = ({ data }) => {
    return (
        <table className="styled-table">
            <thead>
                <tr>
                    <th style={{ border: '0.5px solid grey' }}>NO</th>
                    <th style={{ border: '0.5px solid grey' }}>DIVISION</th>
                    <th style={{ width: '15%', border: '0.5px solid grey' }}>PROJECT NAME</th>
                    <th style={{ width: '25%', border: '0.5px solid grey' }}>SHORT DESCRIPTION</th>
                    <th style={{ border: '0.5px solid grey' }}>PROGRESS</th>
                    <th style={{ border: '0.5px solid grey' }}>JAN</th>
                    <th style={{ border: '0.5px solid grey' }}>FEB</th>
                    <th style={{ border: '0.5px solid grey' }}>MAR</th>
                    <th style={{ border: '0.5px solid grey' }}>APR</th>
                    <th style={{ border: '0.5px solid grey' }}>MEI</th>
                    <th style={{ border: '0.5px solid grey' }}>JUN</th>
                    <th style={{ border: '0.5px solid grey' }}>JUL</th>
                    <th style={{ border: '0.5px solid grey' }}>AGU</th>
                    <th style={{ border: '0.5px solid grey' }}>SEP</th>
                    <th style={{ border: '0.5px solid grey' }}>OKT</th>
                    <th style={{ border: '0.5px solid grey' }}>NOV</th>
                    <th style={{ border: '0.5px solid grey' }}>DES</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map((el, key) => (
                        <tr style={{ cursor: 'pointer', textAlign: 'start' }} key={key} className="active-row">
                            <td style={{ border: '0.5px solid grey' }}>{key + 1}</td>
                            <td style={{ border: '0.5px solid grey' }}>{el.division.division_name}</td>
                            <td style={{ border: '0.5px solid grey' }}>{el.project_name}</td>
                            <td style={{ border: '0.5px solid grey' }}>{el.short_description}</td>
                            <td style={{ border: '0.5px solid grey' }}><Progress percent={el.progress !== 0 ? Math.round(el.progress) : 0} /></td>
                            <td className=
                                {
                                    el.date_realization === 1 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(1) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(1) ?
                                            el.progress !== 100 ?
                                                el.done.includes(1) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(1) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(1) || el.date_realization === 1 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 2 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(2) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(2) ?
                                            el.progress !== 100 ?
                                                el.done.includes(2) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(2) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(2) || el.date_realization === 2 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 3 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(3) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(3) ?
                                            el.progress !== 100 ?
                                                el.done.includes(3) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(3) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(3) || el.date_realization === 3 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 4 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(4) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(4) ?
                                            el.progress !== 100 ?
                                                el.done.includes(4) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(4) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(4) || el.date_realization === 4 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 5 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(5) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(5) ?
                                            el.progress !== 100 ?
                                                el.done.includes(5) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(5) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(5) || el.date_realization === 5 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 6 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(6) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(6) ?
                                            el.progress !== 100 ?
                                                el.done.includes(6) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(6) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(6) || el.date_realization === 6 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 7 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(7) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(7) ?
                                            el.progress !== 100 ?
                                                el.done.includes(7) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(7) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(7) || el.date_realization === 7 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 8 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(8) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(8) ?
                                            el.progress !== 100 ?
                                                el.done.includes(8) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(8) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(8) || el.date_realization === 8 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 9 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(9) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(9) ?
                                            el.progress !== 100 ?
                                                el.done.includes(9) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(9) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(9) || el.date_realization === 9 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 10 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(10) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(10) ?
                                            el.progress !== 100 ?
                                                el.done.includes(10) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(10) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(10) || el.date_realization === 10 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 11 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(11) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(11) ?
                                            el.progress !== 100 ?
                                                el.done.includes(11) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(11) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(11) || el.date_realization === 11 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                            <td className=
                                {
                                    el.date_realization === 12 ?
                                        parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ?
                                            'color-table-overdue' :
                                            el.done.includes(12) ?
                                                'color-table-done' : 'color-table-row'
                                        :
                                        el.range_date.includes(12) ?
                                            el.progress !== 100 ?
                                                el.done.includes(12) ?
                                                    parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' :
                                                        'color-table-progress'
                                                    : 'color-table-row'
                                                :
                                                el.done.includes(12) && parseInt(moment(el.realization_date).format('M')) > parseInt(moment(el.end_date).format('M')) ? 'color-table-overdue' : 'color-table-done'
                                            :
                                            null
                                }
                            >
                                {
                                    el.range_date.includes(12) || el.date_realization === 12 ?
                                        <Popover
                                            content={
                                                <div>
                                                    <Text>
                                                        Start Date: <b>{moment(el.start_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        End Date: <b>{moment(el.end_date).format('MMM')}</b>
                                                    </Text><br />
                                                    <Text>
                                                        Realization: {el.realization_date ? <b>{moment(el.realization_date).format('MMM')}</b> : '-'}
                                                    </Text><br />
                                                    <Text>
                                                        Progress: <b>{el.progress}</b> %
                                                    </Text><br />
                                                </div>
                                            }
                                            title={el.project_name}
                                            trigger="hover"
                                        >
                                            <Button type="primary" size='small' style={{ backgroundColor: 'transparent', border: 'none', color: 'transparent', boxShadow: 'none', width: '20px', fontSize: 0 }}> Click</Button>
                                        </Popover>
                                        : null
                                }
                            </td>
                        </tr>
                    ))

                }
            </tbody>
        </table >
    )
}

export default TableGantt