import React from 'react'
import { toIdr } from '../../../utils/currencyHandler'
import { Card, Row } from 'antd'

const Head = ({ data }) => {
    const nullData = [0, null]
    return (
        <div>
            {
                data.map((el, key) => (
                    <Card key={key} title={
                        <Row justify='center'>
                            <h1 style={{ fontSize: 20 }}>{el.sales_name.toUpperCase()}</h1>
                        </Row>
                    } style={{ marginBottom: 8 }}>
                        <table className="table-sub" style={{
                            fontSize: 16,
                            display: "block",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            width: "100%"
                        }}>
                            <thead>
                                <tr>
                                    <th style={{ backgroundColor: "#eaeaea" }} rowSpan={2}>Desc</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>Januari</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>Februari</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>Maret</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>April</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>Mei</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>Juni</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>Juli</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>Agustus</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>September</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>Oktober</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>November</th>
                                    <th style={{ backgroundColor: "#eaeaea" }} colSpan={3}>Desember</th>
                                </tr>
                                <tr>
                                    {/* Januari */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea", width: 5 }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* Februari */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* Maret */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* April */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* Mei */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* Juni */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* Juli */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* Agustus */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* September */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* Oktober */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* November */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                    {/* Desember */}
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Amount</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Target</th>
                                    <th style={{ fontSize: 12, backgroundColor: "#eaeaea" }}>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sales</td>
                                    {/* Januari */}
                                    <td style={{ width: 5 }}>{toIdr(el.periode["1"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["1"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["1"].target_sales) || nullData.includes(el.periode["1"].amount_sales) ? 0 : Math.round((el.periode["1"].amount_sales / el.periode["1"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* Februari */}
                                    <td>{toIdr(el.periode["2"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["2"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["2"].target_sales) || nullData.includes(el.periode["2"].amount_sales) ? 0 : Math.round((el.periode["2"].amount_sales / el.periode["2"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* Maret */}
                                    <td>{toIdr(el.periode["3"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["3"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["3"].target_sales) || nullData.includes(el.periode["3"].amount_sales) ? 0 : Math.round((el.periode["3"].amount_sales / el.periode["3"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* April */}
                                    <td>{toIdr(el.periode["4"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["4"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["4"].target_sales) || nullData.includes(el.periode["4"].amount_sales) ? 0 : Math.round((el.periode["4"].amount_sales / el.periode["4"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* Mei */}
                                    <td>{toIdr(el.periode["5"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["5"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["5"].target_sales) || nullData.includes(el.periode["5"].amount_sales) ? 0 : Math.round((el.periode["5"].amount_sales / el.periode["5"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* Juni */}
                                    <td>{toIdr(el.periode["6"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["6"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["6"].target_sales) || nullData.includes(el.periode["6"].amount_sales) ? 0 : Math.round((el.periode["6"].amount_sales / el.periode["6"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* Juli */}
                                    <td>{toIdr(el.periode["7"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["7"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["7"].target_sales) || nullData.includes(el.periode["7"].amount_sales) ? 0 : Math.round((el.periode["7"].amount_sales / el.periode["7"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* Agustus */}
                                    <td>{toIdr(el.periode["8"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["8"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["8"].target_sales) || nullData.includes(el.periode["8"].amount_sales) ? 0 : Math.round((el.periode["8"].amount_sales / el.periode["8"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* September */}
                                    <td>{toIdr(el.periode["9"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["9"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["9"].target_sales) || nullData.includes(el.periode["9"].amount_sales) ? 0 : Math.round((el.periode["9"].amount_sales / el.periode["9"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* Oktober */}
                                    <td>{toIdr(el.periode["10"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["10"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["10"].target_sales) || nullData.includes(el.periode["10"].amount_sales) ? 0 : Math.round((el.periode["10"].amount_sales / el.periode["10"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* November */}
                                    <td>{toIdr(el.periode["11"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["11"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["11"].target_sales) || nullData.includes(el.periode["11"].amount_sales) ? 0 : Math.round((el.periode["11"].amount_sales / el.periode["11"].target_sales) * 100)
                                        }%
                                    </td>
                                    {/* Desember */}
                                    <td>{toIdr(el.periode["12"].amount_sales)}</td>
                                    <td>{toIdr(el.periode["12"].target_sales)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["12"].target_sales) || nullData.includes(el.periode["12"].amount_sales) ? 0 : Math.round((el.periode["12"].amount_sales / el.periode["12"].target_sales) * 100)
                                        }%
                                    </td>
                                </tr>
                                <tr>
                                    <td>Profit</td>
                                    {/* Januari */}
                                    <td>{toIdr(el.periode["1"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["1"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["1"].target_profit) || nullData.includes(el.periode["1"].amount_profit) ? 0 : Math.round((el.periode["1"].amount_profit / el.periode["1"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* Februari */}
                                    <td>{toIdr(el.periode["2"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["2"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["2"].target_profit) || nullData.includes(el.periode["2"].amount_profit) ? 0 : Math.round((el.periode["2"].amount_profit / el.periode["2"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* Maret */}
                                    <td>{toIdr(el.periode["3"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["3"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["3"].target_profit) || nullData.includes(el.periode["3"].amount_profit) ? 0 : Math.round((el.periode["3"].amount_profit / el.periode["3"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* April */}
                                    <td>{toIdr(el.periode["4"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["4"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["4"].target_profit) || nullData.includes(el.periode["4"].amount_profit) ? 0 : Math.round((el.periode["4"].amount_profit / el.periode["4"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* Mei */}
                                    <td>{toIdr(el.periode["5"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["5"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["5"].target_profit) || nullData.includes(el.periode["5"].amount_profit) ? 0 : Math.round((el.periode["5"].amount_profit / el.periode["5"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* Juni */}
                                    <td>{toIdr(el.periode["6"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["6"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["6"].target_profit) || nullData.includes(el.periode["6"].amount_profit) ? 0 : Math.round((el.periode["6"].amount_profit / el.periode["6"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* Juli */}
                                    <td>{toIdr(el.periode["7"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["7"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["7"].target_profit) || nullData.includes(el.periode["7"].amount_profit) ? 0 : Math.round((el.periode["7"].amount_profit / el.periode["7"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* Agustus */}
                                    <td>{toIdr(el.periode["8"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["8"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["8"].target_profit) || nullData.includes(el.periode["8"].amount_profit) ? 0 : Math.round((el.periode["8"].amount_profit / el.periode["8"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* September */}
                                    <td>{toIdr(el.periode["9"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["9"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["9"].target_profit) || nullData.includes(el.periode["9"].amount_profit) ? 0 : Math.round((el.periode["9"].amount_profit / el.periode["9"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* Oktober */}
                                    <td>{toIdr(el.periode["10"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["10"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["10"].target_profit) || nullData.includes(el.periode["10"].amount_profit) ? 0 : Math.round((el.periode["10"].amount_profit / el.periode["10"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* November */}
                                    <td>{toIdr(el.periode["11"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["11"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["11"].target_profit) || nullData.includes(el.periode["11"].amount_profit) ? 0 : Math.round((el.periode["11"].amount_profit / el.periode["11"].target_profit) * 100)
                                        }%
                                    </td>
                                    {/* Desember */}
                                    <td>{toIdr(el.periode["12"].amount_profit)}</td>
                                    <td>{toIdr(el.periode["12"].target_profit)}</td>
                                    <td>
                                        {
                                            nullData.includes(el.periode["12"].target_profit) || nullData.includes(el.periode["12"].amount_profit) ? 0 : Math.round((el.periode["12"].amount_profit / el.periode["12"].target_profit) * 100)
                                        }%
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                ))
            }
        </div>
    )
}

export default Head