import React from 'react'
import { FormModal, Uploader } from '../../../../../../components'
import { useDispatch, useSelector } from 'react-redux'
import { changeAddDailyModal, changeJobCategory, fetchMasterJobDesc, changeUploading, fileUploaded, changeJobDesc, changeProgressDailyValue, fetchDailyActivities, saveInfraActivity } from '../../../../../../redux/slices/Dashboard/myDailyActivitySlice'
import { Col, DatePicker, Form, Input, InputNumber, Row, Select, Slider, TimePicker, message } from 'antd'
import { endOfMonth, firstOfMonth } from '../../../../../../helpers/getDate'
import moment from 'moment'
import currentUser from '../../../../../../helpers/authData'

const { Option } = Select
const { RangePicker } = DatePicker

const ModalAddDailyInfra = () => {
    const dispatch = useDispatch()
    const showModalAddDailyActivity = useSelector((state) => state.myDailyActivity.showModalAddDailyActivity)
    const titleAddDailyActivity = useSelector((state) => state.myDailyActivity.titleAddDailyActivity)
    const job_category = useSelector((state) => state.myDailyActivity.job_category)
    const masterJob = useSelector((state) => state.myDailyActivity.masterJob)
    const attachmentFile = useSelector((state) => state.myDailyActivity.attachmentFile)
    const jobDescId = useSelector((state) => state.myDailyActivity.jobDescId)
    const jobDesc = useSelector((state) => state.myDailyActivity.jobDesc)
    const progressDailyValue = useSelector((state) => state.myDailyActivity.progressDailyValue)

    const changeCategory = (v) => {
        dispatch(changeJobCategory(v))
        dispatch(fetchMasterJobDesc({ division_id: 34, position_id: 66, job_category: v }))
    }

    const onFinish = (v) => {
        if (attachmentFile === null && progressDailyValue === 100) {
            return message.warning("Attachment Cannot be empty")
        }
        if (v.job_category === "DAILY" || v.job_category === "WEEKLY" || v.job_category === "MONTHLY") {
            if (new Date(v.start_month).getMonth() + 1 !== new Date(v.date_job).getMonth() + 1) {
                return message.warning("Month Period and Job Date is not match")
            }
        } else {
            if (v.job_category === "QUARTERLY") {
                const datePeriod = new Date(v.date_job).getMonth() + 1
                const date1 = new Date(v.range_period[0]).getMonth() + 1
                const date2 = new Date(v.range_period[1]).getMonth() + 1
                let diff = 0
                let arr = []

                for (let i = date1; i <= date2; i++) {
                    diff = diff + 1
                    arr.push(i)
                }

                if (diff !== 3) {
                    return message.warning("Quarterly Job Desc Must Be Within 3 Months")
                }
                if (!arr.includes(datePeriod)) {
                    return message.warning("Month Period and Job Date is not match")
                }
            }
            if (v.job_category === "SEMESTER") {
                const datePeriod = new Date(v.date_job).getMonth() + 1
                const date1 = new Date(v.range_period[0]).getMonth() + 1
                const date2 = new Date(v.range_period[1]).getMonth() + 1
                let diff = 0
                let arr = []

                for (let i = date1; i <= date2; i++) {
                    diff = diff + 1
                    arr.push(i)
                }

                if (diff !== 6) {
                    return message.warning("Semester Job Desc Must Be Within 6 Months")
                }
                if (!arr.includes(datePeriod)) {
                    return message.warning("Month Period and Job Date is not match")
                }
            }
        }

        let start_date;
        let end_date;

        if (v.job_category === "DAILY" || v.job_category === "WEEKLY") {
            start_date = v.start_month
            end_date = v.start_month

        } else if (v.job_category === "MONTHLY") {
            start_date = v.start_month
            end_date = endOfMonth(new Date(v.start_month))
        } else {
            start_date = firstOfMonth(new Date(v.range_period[0]))
            end_date = endOfMonth(new Date(v.range_period[1]))
        }

        let payload = {
            ...v,
            start_period: v.range_period ? new Date(v.range_period[0]).getMonth() + 1 : new Date(v.start_month).getMonth() + 1,
            end_period: v.range_period ? new Date(v.range_period[1]).getMonth() + 1 : new Date(v.start_month).getMonth() + 1,
            job_description_id: jobDescId,
            job_description: jobDesc,
            attachment: attachmentFile,
            start_date: moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
            due_date: moment(end_date).format("YYYY-MM-DD HH:mm:ss"),
            date_job: moment(v.date_job).format("YYYY-MM-DD HH:mm:ss"),
            time_start: moment(v.time[0]).format("HH:mm"),
            time_end: moment(v.time[1]).format("HH:mm"),
            progress: progressDailyValue,
            division_id: currentUser.division_id
        }

        console.log(payload)

        dispatch(saveInfraActivity(payload)).then(() => {
            dispatch(fetchDailyActivities({ start_date: null, end_date: null }))
        })
    }

    return (
        <FormModal title={titleAddDailyActivity} showModal={showModalAddDailyActivity} onCloseModal={() => dispatch(changeAddDailyModal(false))} width={600} submitForm={(v) => onFinish(v)}>
            <Form.Item label="Job Category" name="job_category" rules={[{ required: true }]}>
                <Select placeholder="Select Job Category" onChange={(v) => changeCategory(v)}>
                    <Option value="DAILY">DAILY</Option>
                    <Option value="WEEKLY">WEEKLY</Option>
                    <Option value="MONTHLY">MONTHLY</Option>
                    <Option value="QUARTERLY">QUARTERLY</Option>
                    <Option value="SEMESTER">SEMESTER</Option>
                </Select>
            </Form.Item>
            {
                job_category === "DAILY" || job_category === "WEEKLY" || job_category === "MONTHLY" ?
                    <Form.Item label="Period" name="start_month" rules={[{ required: true }]}>
                        <DatePicker picker="month" style={{ width: "100%" }} />
                    </Form.Item>
                    :
                    job_category === "QUARTERLY" || job_category === "SEMESTER" ?
                        <Form.Item label="Range Period" name="range_period" rules={[{ required: true }]}>
                            <RangePicker picker="month" style={{ width: "100%" }} />
                        </Form.Item>
                        : null
            }
            <Form.Item label="Date Job" name="date_job" rules={[{ required: true }]}>
                <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Job Description" name="job_description" rules={[{ required: true }]}>
                <Select
                    showSearch
                    allowClear
                    placeholder="Select Job Description"
                    options={masterJob}
                    onChange={(v) => dispatch(changeJobDesc(v))}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                />
            </Form.Item>
            {
                parseInt(jobDescId) === 0 ?
                    <Form.Item label="Job Description" name="description" rules={[{ required: true }]}>
                        <Input.TextArea rows={3} placeholder="Input Description" />
                    </Form.Item> : null
            }
            <Form.Item label="Progress" rules={[{ required: true }]}>
                <Row justify='space-around'>
                    <Col span={16}>
                        <Slider min={0} max={100} value={progressDailyValue} onChange={(v) => dispatch(changeProgressDailyValue(v))} />
                    </Col>
                    <Col span={4}>
                        <InputNumber
                            min={0}
                            max={100}
                            value={progressDailyValue}
                            onChange={(v) => dispatch(changeProgressDailyValue(v))}
                        />
                    </Col>
                </Row>
            </Form.Item>
            {
                progressDailyValue === 100 ?
                    <Form.Item label="Progress Description" name="progress_description" rules={[{ required: true }]}>
                        <Input.TextArea rows={3} placeholder="Progress Description" />
                    </Form.Item>
                    : null
            }
            <Form.Item label="Remarks" name="remark" rules={[{ required: true }]}>
                <Input.TextArea rows={3} placeholder="Remarks..." />
            </Form.Item>
            <Form.Item label="Time" name="time" rules={[{ required: true }]}>
                <TimePicker.RangePicker format="HH:mm" />
            </Form.Item>
            {
                progressDailyValue === 100 ?
                    <Form.Item label="Attachment" name="attachment">
                        <Uploader folder="attachment-infra" onUploaded={(v) => dispatch(fileUploaded(v))} isUploading={(v) => dispatch(changeUploading(v))} view={true} />
                    </Form.Item>
                    : null
            }
        </FormModal>
    )
}

export default ModalAddDailyInfra