import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Typography} from 'antd'
import { PageTitle, PrintPdfButton, Spinner} from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import {toIdr} from '../../../utils/currencyHandler'

const {Text} = Typography

const Detail = () => {
    const {momId} = useParams();
    const [mom, setMom] = useState(null);

    const fetchMom = (id) => {
       SentralModel.get('SubmissionMinutesOfMeeting', {}, id).then((res) => {
           setMom(res.data);
       });
    }

    useEffect(() => {
        fetchMom(momId);
    }, [momId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/mom/' + momId);
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Minutes of Meeting" breadcrumbs={[['Minutes of Meeting', '/mom'], ['Detail']]}></PageTitle>
            </div>
            {
                (mom) ? 
                <div>
                    <Row className="mb-3">
                        <Col span={24} >
                            <PrintPdfButton right onPrint={printData} />
                        </Col>
                    </Row>
                    <DetailHeader name={mom.employee.name} date={mom.created_at} status={mom.status} />
                    
                    <Card bordered className="mb-4">
                        <Text strong>MINUTES OF MEETING DETAIL</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={12}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <th>Employee Name</th><td>{mom.employee.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Division</th><td>{mom.employee.division.division_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Position</th><td>{mom.employee.position.position_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Amount</th><td>{toIdr(mom.total_amount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>

                    <Card bordered className="mb-4">
                        <Text strong>MINUTES OF MEETING SUMMARY</Text>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Item Name</th>
                                            <th>Amount</th>
                                            <th>Attachment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            mom.items.map((el, key) => (
                                                <tr key={key}>
                                                   <td>{el.description}</td>
                                                   <td>{toIdr(el.amount)}</td>
                                                   <td>
                                                        {
                                                            (el.attachment) ?
                                                            <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE+'/'+el.attachment}>
                                                                show attachment
                                                            </a> : '-'
                                                        }
                                                   </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th id="total">Total</th>
                                            <td>{toIdr(mom.total_amount)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <Card bordered>
                        <ApprovalLog category="MOM" 
                            approvers={mom.approver} />
                    </Card>
                </div> : 
                <Spinner/>
            }
        </Card>
    );
}

export default Detail;
