import React, { useState, useEffect } from 'react';
import { Affix, Card, Avatar, Typography, Row, Col, Divider, DatePicker, Select, Space, Button } from 'antd';
import { SentralModel } from '../../models/SentralModel'
import authData from '../../helpers/authData'
import Information from './Information'
import Leave from './Leave'
import Probation from './Probation';
import { decrypt } from '../../helpers/crypto';
import GaugeChart from 'react-gauge-chart';
import moment from 'moment';
import { loadedState, scoreCardState } from '../../recoil-state/scoreCard.state';
import { useRecoilState } from 'recoil';
import { tabState } from '../../recoil-state/dashboard.state';
import { Spinner } from '../../components';

const { Title } = Typography
const { Option } = Select

const ProfileImage = (props) => (
    <Col className="text-center">
        <div className="text-center">
            {
                (authData.profile_image) ?
                    <Avatar size={80} src={process.env.REACT_APP_API_FILE + '/' + authData.profile_image.photo} />
                    :
                    <Avatar size={80} src={process.env.PUBLIC_URL + '/images/user-male.png'} />
            }
        </div>
    </Col>
)

const ProfileBox = () => {
    // eslint-disable-next-line
    const [activeKey, setActiveKey] = useRecoilState(tabState);
    const [user, setUser] = useState(null)
    const [leaves, setLeaves] = useState(null)
    const [attendance, setAttendance] = useState(0)
    const [percentage, setPercentage] = useRecoilState(scoreCardState)
    const [loaded, setLoaded] = useRecoilState(loadedState)
    const [periods, setPeriods] = useState([])

    const role = localStorage.getItem('role') ? JSON.parse(decrypt(localStorage.getItem('role'))) : []

    const fetchLeave = () => {
        SentralModel.action('Submission', 'getAnnualLeave', null, null).then(res => {
            setLeaves(res.data)
        })
    }

    const fetchScoreCard = (v) => {
        setLoaded(false)
        let payload = {
            payroll_period_id: v
        }
        SentralModel.action("Task", "getScoreCardData", payload, null).then((res) => {
            setPercentage(res.data)
            setLoaded(true)
        })
    }

    const fetchAttendance = () => {
        SentralModel.action('Attendance', 'getMonthlyAttendance', { attendance_id: authData.attendance_id }).then(res => {
            setAttendance(res.data.length)
        })
    }

    const fetchPeriods = (year) => {
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'desc' }).then(res => {
            setPeriods(res.data)
        })
    }

    useEffect(() => {
        setUser(authData)
        fetchLeave()
        fetchAttendance()
        fetchScoreCard(null)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const changePeriod = (v) => {
        fetchScoreCard(v)
    }

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addYear = 1;

        if (currentMonth === '12') {
            addYear = 2;
        }

        let customDate = moment(new Date()).add(addYear, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    };

    const changeTab = () => {
        setActiveKey("score_card")
        sessionStorage.setItem('selectedDashboardTab', "score_card")
    }

    return (
        <div>
            <Card style={{ width: '100%', minHeight: 500 }} className="box-shadow-2">
                <div className="px-4 pt-4">
                    <Row type="flex" align="top" justify="space-between">
                        <ProfileImage />
                        {user ?
                            <Col>
                                <h5 className="m-0">
                                    <b>{user.name}</b>
                                </h5>
                                <p className="mb-0">{user.division ? user.division.division_name : '-'}</p>
                                <a
                                    href="!#"
                                    css={`
                                    display: block;
                                    `}
                                >
                                    {user.work_email}
                                </a>
                                <br />
                                <a
                                    href="!#"
                                    css={`
                                    display: block;
                                    `}
                                >
                                    {user.mobile_phone}
                                </a>
                            </Col>
                            : null}
                    </Row>
                </div>

                <Divider orientation="center">
                    <small>Stats</small>
                </Divider>
                {user ?
                    <>
                        <Row
                            className="text-center px-4"
                            type="flex"
                            align="middle"
                            justify="space-between"
                        >
                            <Col span={10} style={{ marginRight: '10px' }}>
                                <h2 className="m-0">
                                    <b>{leaves ? (leaves.balance - leaves.public_mass) : 0}</b>
                                </h2>
                                <small>Leave Balance</small>
                            </Col>
                            <Col span={10} style={{ marginLeft: '10px' }}>
                                <h2 className="m-0">
                                    <b>{attendance}</b>
                                </h2>
                                <small>Attendance</small>
                            </Col>
                        </Row>
                    </>
                    : null}
                {/* <Divider style={{ margin: '20px 0px 20px 0px' }} /> */}
                <div className="px-4 pb-4">
                    <div className='text-center mb-3'>
                        <Divider orientation="center">
                            <Title level={5}>Score Card</Title>
                        </Divider>
                        <div className='mb-3'>
                            <Row>
                                <Title level={5}>Period</Title>
                            </Row>
                            <Row justify='start'>
                                <Col>
                                    <Space>
                                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                                        <Select placeholder="Select Period" onChange={changePeriod}>
                                            {
                                                periods.map((el, key) => (
                                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                        {
                            loaded ?
                                <div>
                                    <GaugeChart
                                        id="gauge-chart3"
                                        nrOfLevels={4}
                                        percent={percentage.total_percentage < 0 ? 0 : (percentage.total_percentage / 100)}
                                        colors={["red", "orange", "#F9D949", "green"]}
                                        arcWidth={0.3}
                                        hideText={true}
                                    />
                                    <p style={{ fontSize: 24, fontWeight: "bold" }}>
                                        {percentage.total_percentage}%
                                    </p>
                                </div> : <Spinner />
                        }
                    </div>
                    <div className='text-center'>
                        <Button onClick={changeTab}>Show Detail</Button>
                    </div>
                    {/* <p className="text-uppercase mb-4">
                        <strong>Address</strong>
                    </p>
                    <p>
                        {user ? user.address : ''}
                    </p> */}
                </div>
            </Card>
            {
                role.includes('HR') || role.includes('SUPERADMIN') ?
                    <>
                        <br />
                        <Affix offsetTop={10}>
                            <Card className="box-shadow-2">
                                <Title level={3}>Employee Probation</Title>
                                <Probation />
                            </Card>
                        </Affix>
                    </> : null

            }
            <br />
            <Affix offsetTop={10}>
                <Card className="box-shadow-2">
                    <Title level={3}>Today Leaves</Title>
                    <Leave />
                </Card>
            </Affix>
            <br />
            <Affix offsetTop={10}>
                <Card className="box-shadow-2">
                    <Title level={3}>Announcement</Title>
                    <Information />
                </Card>
            </Affix>
        </div>
    );
}

export default ProfileBox;
