import React, { useState } from 'react';
import { Card, message } from 'antd';
import { PageTitle } from '../../../components'
import RequestForm from './RequestForm'
import { SentralModel } from '../../../models/SentralModel';
import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react';

const Create = () => {
    const { leaveId } = useParams()
    const history = useHistory()
    const [saving, setSaving] = useState(false)
    const [leave, setLeave] = useState(null)

    const fetchLeave = (leaveId) => {
        SentralModel.get('SubmissionLeave', {}, leaveId).then((res) => {
            setLeave(res.data)
        })
    }

    useEffect(() => {
        fetchLeave(leaveId)

    }, [leaveId])

    const submitLeave = (v) => {
        let data = {
            leave_id: leaveId,
            attachment: v.attachment
        }
        if (v.leave_type === 5 && !v.attachmentFile) {
            message.error('Attachment is required', 2)
        }
        setSaving(true)
        SentralModel.action('Submission', 'saveLeaveRealization', data, 0).then(() => {
            message.destroy()
            message.success('Leave submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/leave');
            }, 1000);
        }).catch((err) => {
            setSaving(false)
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Leave Submission" breadcrumbs={[['Leave', '/leave'], ['Create Realization']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitLeave(v)} saving={saving} leaveData={leave} />
        </Card>
    );
}

export default Create;
