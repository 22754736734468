import { useState, useEffect } from 'react'
import { Button, Card, Col, Divider, Form, Input, Row, Select, Space, message } from 'antd'
import { useParams } from 'react-router-dom'
import { DataTable, DeleteButton, EditButton, FormModal, PageTitle } from '../../../../components'
// import { HistoryOutlined } from '@ant-design/icons'
import { SentralModel } from '../../../../models/SentralModel'
import moment from 'moment'

const { Option } = Select

const TicketDetail = () => {
    const { ticketId } = useParams()
    const [form] = Form.useForm()
    const [listTechnician, setListTechnician] = useState([])
    const [handling, setHandling] = useState([])
    const [masterTypeProblem, setMasterTypeProblem] = useState([])
    const [masterHandling, setMasterHandling] = useState([])
    const [modalAddHandling, setModalAddHandling] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [status, setStatus] = useState('open')
    const [saving, setSaving] = useState(false)

    const fetchTicketDetail = () => {
        SentralModel.action("Task", "ticketDetail", 0, ticketId).then((res) => {
            const v = res.data
            let arr = []
            if (v.multiple_task_to.length > 0) {
                v.multiple_task_to?.forEach((el) => {
                    arr.push(el?.task_to?.employee_code)
                })
            }
            if (v.handling_ticket.length > 0) {
                setHandling(v.handling_ticket)
            }

            form.setFieldsValue({
                id: v.id,
                task_id: v.task_id,
                type_job: v.type_job,
                unique_code: v.unique_code,
                quantity: v.quantity,
                company_name: v.company_name,
                serial_number: v.serial_number,
                company_address: v.company_address,
                item_name: v.item_name,
                company_phone: v.company_phone,
                sku: v.sku,
                ticket_status: v.status === "Finished" ? "close" : "open",
                sla_level: v.sla_level,
                due_date: moment(v.due_date).format("MM/DD/YYYY"),
                pic_name: v.pic_name,
                description: v.description,
                technicians: arr
            })
        })
    }

    const fetchTechnician = () => {
        SentralModel.action("Task", "getTechnician", 0, 0).then((res) => {
            let arr = []
            res.data.forEach((el) => {
                arr.push({ label: el.name, value: el.employee_code })
            })
            setListTechnician(arr)
        })
    }

    const fetchMasterTypeProblem = () => {
        SentralModel.action("Task", "getMasterTypeProblem", 0, 0).then((res) => {
            setMasterTypeProblem(res.data)
        })
    }

    const fetchMasterHandling = () => {
        SentralModel.action("Task", "getMasterHandling", 0, 0).then((res) => {
            setMasterHandling(res.data)
        })
    }

    useEffect(() => {
        fetchTicketDetail()
        fetchTechnician()
        fetchMasterTypeProblem()
        fetchMasterHandling()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveTicket = (v) => {
        if (status === "close") {
            SentralModel.action("Task", "closeTicket", 0, v.id).then((res) => {
                message.success("Ticket Close")
                fetchTicketDetail()
            }).catch((err) => {
                message.warning("Failed Close Ticket")
            })
        }
    }

    const addHandling = (v) => {
        let payload = {
            task_id: ticketId,
            ...v
        }

        setSaving(false)
        if (v.all_handling_ticket_id === 0) {
            SentralModel.action("Task", "addHandlingTicket", payload, 0).then((res) => {
                setModalAddHandling(false)
                message.success("Success Add Handling")
                fetchTicketDetail()
                setSaving(true)
            }).catch((err) => {
                message.warning("Failed Add Handling")
                setSaving(true)
            })
        } else {
            SentralModel.action("Task", "editHandling", payload, v.all_handling_ticket_id).then((res) => {
                setModalAddHandling(false)
                message.success("Success Edit Handling")
                fetchTicketDetail()
                setSaving(true)
            }).catch((err) => {
                message.warning("Failed Edit Handling")
                setSaving(true)
            })
        }
    }

    const onShowModalHandling = () => {
        form.setFieldsValue({
            all_handling_ticket_id: 0,
            handling_id: 0,
            technician_id: [],
            type: null,
            type_problem: null,
            handling_description: null,
            distance: null,
        })
        setModalAddHandling(true)
        setModalTitle("Add Handling")
    }

    const editHandling = (v) => {
        form.setFieldsValue({
            all_handling_ticket_id: v.all_handling_ticket_id,
            handling_id: v.handling_id,
            technician: v.employee_code,
            type: v.type,
            handling_type: v.handling_type,
            type_problem: v.type_problem,
            handling_description: v.handling_description,
            distance: v.distance,
        })
        setModalAddHandling(true)
        setModalTitle("Edit Handling")
    }

    const deleteHandling = (v) => {
        SentralModel.action("Task", "deleteHandling", 0, v).then((res) => {
            fetchTicketDetail()
            message.success("Success Delete Handling")
        }).catch((err) => {
            message.warning("Failed Delete Handling")
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%',
            align: 'center'
        },
        {
            title: 'No Handling',
            key: 'no_handling',
            render: (row) => (
                row?.handling_id === null ? row?.all_handling_ticket_id : row?.handling_id
            )
        },
        {
            title: 'Technician',
            key: 'technician',
            render: (row) => (
                row?.technician?.name
            ),
        },
        {
            title: 'Date',
            key: 'date',
            render: (row) => (
                moment(row.created_at).format("dddd, DD MMMM YYYY")
            )
        },
        {
            title: 'Type',
            key: 'type',
            dataIndex: 'type'
        },
        {
            title: 'Distance',
            key: 'distance',
            dataIndex: 'distance'
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'handling_description',
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => editHandling(row)} />
                    <DeleteButton onConfirm={() => deleteHandling(row.all_handling_ticket_id)} />
                </Space>
            ),
            key: 'action',
            width: '10%',
            align: 'center'
        }
    ]

    return (
        <Card className="content-container">
            <PageTitle title={`Ticket Detail #${ticketId}`} breadcrumbs={[['Ticket Detail']]}></PageTitle>

            <Form form={form} layout='vertical' onFinish={(v) => saveTicket(v)}>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="task_id" hidden>
                    <Input />
                </Form.Item>
                <Row justify='space-between'>
                    <Col span={11}>
                        <Form.Item label="Type Job" name="type_job">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Ticket Number" name="unique_code">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={11}>
                        <Form.Item label="Quantity" name="quantity">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Company Name" name="company_name">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between'>
                    <Col span={11}>
                        <Row justify='space-between'>
                            <Col span={24}>
                                <Form.Item label="Serial Number" name="serial_number">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            {/* <Col span={4}>
                                <Button type="primary" style={{ borderRadius: 10, marginTop: 25, height: 40 }}><HistoryOutlined /></Button>
                            </Col> */}
                        </Row >
                    </Col >
                    <Col span={11}>
                        <Form.Item label="Address" name="company_address">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row >
                <Row justify='space-between'>
                    <Col span={11}>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Item Name" name="item_name">
                                    <Input disabled />
                                </Form.Item >
                            </Col >
                            <Col span={11}>
                                <Form.Item label="SKU / Product No" name="sku">
                                    <Input disabled />
                                </Form.Item >
                            </Col >
                        </Row >
                    </Col >
                    <Col span={11}>
                        <Form.Item label="Phone" name="company_phone">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row >
                <Row justify='space-between'>
                    <Col span={11}>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="SLA Level" name="sla_level">
                                    <Input disabled />
                                </Form.Item >
                            </Col >
                            <Col span={11}>
                                <Form.Item label="Due Date" name="due_date">
                                    <Input disabled />
                                </Form.Item >
                            </Col >
                        </Row >
                    </Col >
                    <Col span={11}>
                        <Form.Item label="PIC" name="pic_name">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row >
                <Row justify='space-between'>
                    <Col span={11}>
                        <Form.Item label="Technician" name="technicians">
                            <Select
                                mode="multiple" allowClear placeholder="Select Technician"
                                options={listTechnician} disabled />
                        </Form.Item>
                    </Col >
                    <Col span={11}>
                        <Form.Item label="Ticket Status" name="ticket_status">
                            <Select defaultValue={status} onChange={(v) => setStatus(v)}>
                                <Option value="open">Open</Option>
                                <Option value="close">Close</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row >
                <Row>
                    <Col span={11}>
                        <Form.Item label="Description" name="description">
                            <Input.TextArea rows={10} disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <Form.Item label="Laporan Kerusakan">
                            <Input.TextArea rows={7} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Save
                    </Button>
                </Form.Item>
            </Form >

            <Divider />
            <div>
                <div className='mb-3' style={{ float: 'right' }}>
                    <Button type='primary' onClick={onShowModalHandling}>+ Add Handling</Button>
                </div>
                <DataTable size dataSource={handling} columns={columns} bordered pagination={false} search={false} />
            </div>

            {
                modalAddHandling ?
                    <FormModal form={form} submitForm={addHandling} isAdd showModal={modalAddHandling} onCloseModal={() => setModalAddHandling(false)} title={modalTitle} width={900} loading={saving}>
                        <Form.Item name="all_handling_ticket_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="handling_id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Problem Description" name="type_problem">
                            <Select placeholder="Select Problem Description">
                                {
                                    masterTypeProblem.map((el, key) => (
                                        <Option value={el.name} key={key}>{el.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Handling" name="handling_type">
                            <Select placeholder="Select Handling">
                                {
                                    masterHandling.map((el, key) => (
                                        <Option value={el.name} key={key}>{el.name}</Option>
                                    ))
                                }
                            </Select >
                        </Form.Item >
                        <Form.Item label="Description" name="handling_description">
                            <Input.TextArea rows={7} />
                        </Form.Item>
                        <Form.Item label="Level Handling" name="type">
                            <Select placeholder="Select Level Handling">
                                <Option value="Normal">Normal</Option>
                                <Option value="Medium">Medium</Option>
                                <Option value="Complex">Complex</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Distance" name="distance">
                            <Select placeholder="Select Distance">
                                <Option value="Local Support (SMI / Local Customers)">{"Local Support (SMI / Local Customers)"}</Option>
                                <Option value="Long - Area Luar DKI Jakarta (> 20 KM)">{"Long - Area Luar DKI Jakarta (> 20 KM)"})</Option>
                                <Option value="Short - Area DKI Jakarta (< 20 KM)">{"Short - Area DKI Jakarta (< 20 KM)"}</Option>
                            </Select>
                        </Form.Item>
                    </FormModal >
                    : null
            }
        </Card >
    )
}

export default TicketDetail