import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Input, Typography, Button, Select, InputNumber } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { SubmitButton, AddButton, Uploader } from '../../../../components'
import authData from '../../../../helpers/authData'
import { toIdr, extractNumber } from '../../../../utils/currencyHandler';
import { SentralModel } from '../../../../models/SentralModel';

const { TextArea } = Input
const { Text } = Typography
const { Option } = Select

function useForceUpdate() {
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const RequestForm = (props) => {
    const forceUpdate = useForceUpdate()
    const [commissionForm] = Form.useForm()
    const [itemCount, setItemCount] = useState(1)
    const [attachmentFile, setAttachmentFile] = useState([])
    const [item, setItem] = useState([{ description: null, amount: 0 }])
    const [varValue, setVarValue] = useState({})
    const [uploading, setUploading] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)
    // eslint-disable-next-line
    const [urlFile, setUrlFile] = useState([])
    const [masterComission, setMasterComission] = useState([])
    const [categoryComission, setCategoryComission] = useState([])
    const [typeComission, setTypeComission] = useState([])
    const [rentalComission, setRentalComission] = useState([])
    const [subCategoryComission, setSubCategoryComission] = useState([[]])

    const fetchMasterComission = () => {
        SentralModel.action("Comission", "getMasterComission", null, null).then((res) => {
            const v = res.data
            let category = []
            let type = []

            setMasterComission(v)

            v.forEach((el) => {
                if (category.filter((i) => i === el.category).length === 0) {
                    category.push(el.category)
                }
            })
            setCategoryComission(category)

            v.forEach((el) => {
                if (el.customer_status !== null && el.customer_status !== '') {
                    if (type.filter((i) => i === el.customer_status.toUpperCase()).length === 0) {
                        type.push(el.customer_status.toUpperCase())
                    }
                }
            })
            setTypeComission(type)
        })
    }

    const fileUploaded = (v, key) => {
        let attcs = attachmentFile
        attcs[key] = v.message
        setAttachmentFile(attcs)
        setUrlFile(process.env.REACT_APP_API_FILE + "/" + v.message)
    }

    const submitReimbursement = (v) => {
        let total = 0;
        item.forEach((el, key) => {
            el.attachment = (attachmentFile[key]) ? attachmentFile[key] : null;
            total += parseInt(el.amount)
        })
        let submittedData = {
            title: v.title,
            employee_code: authData.employee_code,
            total_amount: total,
            items: item,
        }
        props.onSubmit(submittedData)
    }

    const addItem = () => {
        let x = item
        let key = item.length
        x.push({ description: null, amount: 0 })
        commissionForm.setFieldsValue({
            ['no_invoice[' + key + ']']: null,
            ['no_so[' + key + ']']: null,
            ['item_name[' + key + ']']: null,
            ['category[' + key + ']']: null,
            ['sub_category[' + key + ']']: null,
            ['quantity[' + key + ']']: null,
            ['amount[' + key + ']']: null
        })
        setItem(x)
        setItemCount(itemCount + 1)
        
        let sub = subCategoryComission
        sub.push([])
        setSubCategoryComission(sub)

        forceUpdate()
    }

    const changeNoInvoice = (v, key) => {
        let x = item
        x[key].no_invoice = v.target.value
        setItem(x)
    }

    const changeNoSo = (v, key) => {
        let x = item
        x[key].no_so = v.target.value
        setItem(x)
    }

    const changeCategory = (v, key) => {
        let x = item
        x[key].category = v
        x[key].customer_type = null
        x[key].rental_month = null
        x[key].sub_category = null
        x[key].quantity = null
        x[key].amount = null
        setItem(x)

        let arr = []
        masterComission.filter((el) => {
            if (el.category === v) {
                if (v === 'MPS') {
                    // return arr.push({ value: el.master_comission_id, label: `${el.sub_category} (${el.customer_status === "renewal" ? "Renewal" : "New Customer"})` })
                    return false
                } else {
                    return arr.push({ value: el.master_comission_id, label: el.sub_category })
                }
            } else {
                return false
            }
        })

        commissionForm.setFieldsValue({ ['customer_type[' + key + ']']: null })
        commissionForm.setFieldsValue({ ['rental_month[' + key + ']']: null })
        commissionForm.setFieldsValue({ ['sub_category[' + key + ']']: null })
        commissionForm.setFieldsValue({ ['quantity[' + key + ']']: null })
        commissionForm.setFieldsValue({ ['amount[' + key + ']']: null })

        let sub = subCategoryComission
        sub[key] = arr
        setSubCategoryComission(sub)
        forceUpdate()

        changeTotalAmount()
    }

    const changeType = (v, key) => {
        let x = item
        x[key].customer_type = v
        x[key].rental_month = null
        x[key].sub_category = null
        x[key].quantity = null
        x[key].amount = null
        setItem(x)

        let arr = []
        masterComission.filter((el) => {
            if (el.customer_status?.toUpperCase() === v) {
                if (el.rental_month !== null && el.rental_month !== '') {
                    if (arr.filter((i) => i === el.rental_month).length === 0) {
                        return arr.push(el.rental_month)
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            } else {
                return false
            }
        })

        commissionForm.setFieldsValue({ ['rental_month[' + key + ']']: null })
        commissionForm.setFieldsValue({ ['sub_category[' + key + ']']: null })
        commissionForm.setFieldsValue({ ['quantity[' + key + ']']: null })
        commissionForm.setFieldsValue({ ['amount[' + key + ']']: null })
        setRentalComission(arr)

        let sub = subCategoryComission
        sub[key] = []
        setSubCategoryComission(sub)
        forceUpdate()

        changeTotalAmount()
    }

    const changeRentalMonth = (v, key) => {
        let x = item
        x[key].rental_month = v
        x[key].sub_category = null
        x[key].quantity = null
        x[key].amount = null
        setItem(x)

        let arr = []
        masterComission.filter((el) => {
            if (el.category === x[key].category && el.customer_status.toUpperCase() === x[key].customer_type && el.rental_month === x[key].rental_month) {
                return arr.push({ value: el.master_comission_id, label: el.sub_category })
            } else {
                return false
            }
        })

        commissionForm.setFieldsValue({ ['sub_category[' + key + ']']: null })
        commissionForm.setFieldsValue({ ['quantity[' + key + ']']: null })
        commissionForm.setFieldsValue({ ['amount[' + key + ']']: null })
        
        let sub = subCategoryComission
        sub[key] = arr
        setSubCategoryComission(sub)
        forceUpdate()

        changeTotalAmount()
    }

    const changeSubCategory = (v, key) => {
        const value = masterComission.filter((el) => el.master_comission_id === v)

        let x = item
        let y = varValue
        x[key].sub_category = value[0].sub_category
        x[key].quantity = 1
        x[key].amount = value[0].sales_comission
        Object.assign(y, { [`amount_${key}`]: value[0].sales_comission })
        setItem(x)
        setVarValue(y)

        commissionForm.setFieldsValue({ ['quantity[' + key + ']']: 1 })
        commissionForm.setFieldsValue({ ['amount[' + key + ']']: toIdr(value[0].sales_comission) })

        changeTotalAmount()
    }

    const changeQuantity = (v, key) => {
        let x = item
        let y = varValue
        const value = y[`amount_${key}`] * v
        x[key].quantity = v
        x[key].amount = v === 0 || v === null ? y[`amount_${key}`] : value
        setItem(x)

        commissionForm.setFieldsValue({
            ['amount[' + key + ']']: toIdr(v === 0 || v === null ? y[`amount_${key}`] : value)
        })

        changeTotalAmount()
    }

    const changeTotalAmount = () => {
        let t = 0
        item.forEach((el) => {
            t += el.amount ?? 0
        })

        setTotalAmount(t)
    }

    const changeItemTotal = (v, key) => {
        let x = item
        const newPrice = extractNumber(v.target.value)
        x[key].amount = newPrice
        commissionForm.setFieldsValue({ ['amount[' + key + ']']: toIdr(newPrice) })
        x[key].amount = extractNumber(v.target.value)
        setItem(x)

        let t = 0
        item.forEach((el) => {
            t += el.amount
        })

        setTotalAmount(t)
    }

    const removeItem = (key) => {
        let itm = item
        itm.splice(key, 1)
        setItem(itm)

        let attcs = attachmentFile
        attcs[key] = null
        setAttachmentFile(attcs)

        let sub = subCategoryComission
        sub.splice(key, 1)
        setSubCategoryComission(sub)

        forceUpdate()
    }

    useEffect(() => {
        fetchMasterComission()
    }, [])

    return (
        <Form size="middle" form={commissionForm} layout="vertical" onFinish={submitReimbursement}>
            <Row justify="center">
                <Col span={24}>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="title" placeholder="Title" label="Title" rules={[{ required: true, message: 'Title is required' }]}>
                                <TextArea placeholder="Title" autoSize={{ minRows: 2 }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Card title={
                                <div className="full-width">
                                    <Text strong style={{ float: 'left' }}>Submission Item</Text>
                                    <AddButton right onAdd={() => addItem()} title="Add Item" />
                                </div>}>
                                <table className="table-item" style={{ width: '100%' }}>
                                    <tbody>
                                        {
                                            item?.map((el, key) => (
                                                <tr key={'rmb_item' + key} style={{ borderBottom: '1px solid #ededed' }}>
                                                    <td>
                                                        <Row>
                                                            <Col flex={3}>
                                                                <Form.Item label="No Invoice" name={'no_invoice[' + key + ']'} rules={[{ required: true, message: 'No invoice is required' }]}>
                                                                    <Input placeholder="No Invoice" onChange={(v) => changeNoInvoice(v, key)} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex={3} style={{marginLeft: 10}}>
                                                                <Form.Item label="No SO" name={'no_so[' + key + ']'} rules={[{ required: true, message: 'No SO is required' }]}>
                                                                    <Input placeholder="No SO" onChange={(v) => changeNoSo(v, key)} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex={3} style={{marginLeft: 10}}>
                                                                <Form.Item label="Category" name={'category[' + key + ']'} rules={[{ required: true, message: 'Category is required' }]}>
                                                                    <Select placeholder='Select Category' onChange={(v) => changeCategory(v, key)} allowClear>
                                                                        {
                                                                            categoryComission?.map((el, index) => (
                                                                                <Option value={el} key={index}>{el}</Option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            {
                                                                el.category === 'MPS' ?
                                                                    <>
                                                                        <Col flex={2} style={{marginLeft: 10}}>
                                                                            <Form.Item label="Customer Type" name={'customer_type[' + key + ']'} rules={[{ required: true, message: 'Customer type is required' }]}>
                                                                                <Select placeholder='Select Customer Type' onChange={(v) => changeType(v, key)} allowClear>
                                                                                    {
                                                                                        typeComission?.map((el, index) => (
                                                                                            <Option value={el} key={index}>{el}</Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col flex={2} style={{marginLeft: 10}}>
                                                                            <Form.Item label="Rental Month" name={'rental_month[' + key + ']'} rules={[{ required: true, message: 'Rental month is required' }]}>
                                                                                <Select placeholder='Select Rental Month' onChange={(v) => changeRentalMonth(v, key)} allowClear>
                                                                                    {
                                                                                        rentalComission?.map((el, index) => (
                                                                                            <Option value={el} key={index}>{el}</Option>
                                                                                        ))
                                                                                    }
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </>
                                                                : null
                                                            }
                                                        </Row>

                                                        <Row>
                                                            <Col flex={6}>
                                                                <Form.Item label="Sub Category" name={'sub_category[' + key + ']'} rules={[{ required: true, message: 'Sub Category is required' }]}>
                                                                    <Select showSearch allowClear placeholder='Select Sub Category' onChange={(v) => changeSubCategory(v, key)} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                        {
                                                                            subCategoryComission[key]?.map((el, index) => (
                                                                                <Option value={el.value} key={index}>{el.label}</Option>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex={2} style={{marginLeft: 10}}>
                                                                <Form.Item label="Quantity" name={'quantity[' + key + ']'} rules={[{ required: true, message: 'Quantity is required' }]}>
                                                                    <InputNumber min={1} placeholder="Quantity" onChange={(v) => changeQuantity(v, key)} style={{ width: '100%' }} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex={2} style={{marginLeft: 10}}>
                                                                <Form.Item label="Amount" name={'amount[' + key + ']'} rules={[{ required: true, message: 'Amount is required' }]}>
                                                                    <Input disabled type="text" placeholder="Amount" onChange={(v) =>
                                                                        changeItemTotal(v, key)
                                                                    } />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex={2} style={{marginLeft: 10}}>
                                                                <Form.Item label="Attachment">
                                                                    <Uploader folder="submission-commission" onUploaded={(v) => fileUploaded(v, key)} isUploading={(v) => setUploading(v)} view={true} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col flex={1} style={{marginLeft: 10}}>
                                                                <div className="text-center mt-5">
                                                                    {
                                                                        ((key + 1) === item.length && item.length > 1) ?
                                                                            <Button type="text" danger onClick={() => removeItem(key)} icon={<DeleteOutlined />} />
                                                                        : null
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td>Total Amount : {toIdr(totalAmount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Card>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={24} className="text-right">
                            <SubmitButton loading={props.saving || uploading} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default RequestForm;
