import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Form, Input, Typography} from 'antd'
import { Spinner, SubmitButton} from '../../../components'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import {toIdr, extractNumber} from '../../../utils/currencyHandler'
import {toFullDate} from '../../../utils/dateHandler'

const {Text} = Typography

const RealizationForm = (props) => {
    const {tripId} = useParams()
    const [form] = Form.useForm()
    const [businessTrip, setBusinessTrip] = useState(null)
    const [totalCost, setTotalCost] = useState(null)
    const [costItem, setCostItem] = useState([])

    const fetchBusinessTrip = (id, costItem) => {
       SentralModel.get('SubmissionBusinessTrip', {}, id).then((res) => {
            setBusinessTrip(res.data)
            let x = costItem
            res.data?.costs?.forEach((el, key) => {
                x.push({
                    sub_business_trip_cost_id: el.sub_business_trip_cost_id,
                    description: el.description,
                    quantity_realization: el.quantity,
                    amount_realization: el.amount,
                    total_realization: el.total,
                })
            })
            return setCostItem(x);
       })
    }

    const changeItemPrice = (v, key) => {
        let x = costItem;
        if(v.target.value){
            const newPrice = extractNumber(v.target.value)
            x[key].amount_realization = newPrice
            const qty = x[key].quantity_realization
            form.setFieldsValue({['cost_price_'+key] : toIdr(newPrice)})
            if(qty){
                x[key].total_realization = newPrice * qty
                form.setFieldsValue({['cost_total_' + key] : toIdr(newPrice * qty)})
            }
            setCostItem(x)
            changeTotalCost()
        }else{
            x[key].total_realization = 0
            form.setFieldsValue({['cost_total_' + key] : 0})
            setCostItem(x)
            changeTotalCost()
        }
    }

    const changeItemQty = (v, key) => {
        let x = costItem;
        if(v.target.value){
            const newQty = parseInt(v.target.value)
            const price = x[key].amount_realization
            x[key].quantity_realization = newQty
            if(price){
                x[key].total_realization = price * newQty
                form.setFieldsValue({['cost_total_' + key] : toIdr(price * newQty)})
            }
            setCostItem(x)
            changeTotalCost()
        }else{
            x[key].total_realization=0
            form.setFieldsValue({['cost_total_' + key] : 0})
            setCostItem(x)
            changeTotalCost()
        }
    }

    const changeTotalCost = () => {
        let total=0;
        costItem.forEach(el => {
            total+=el.total_realization
        });
        setTotalCost(total)
    }

    const submitBusinessTrip = (v) =>{
        let data={
            trip_id: tripId,
            total_amount_realization: totalCost,
            cost_realization: costItem,
        }
        props.onSubmit(data)
    }

    useEffect(() => {
        fetchBusinessTrip(tripId, costItem)
    }, [tripId, costItem]);

    return (
        (businessTrip) ? 
            <div>
                <Card bordered className="mb-4">
                    <Text strong>BUSINESS TRIP DETAIL</Text>
                    <Row className="mb-4 mt-1">
                        <Col span={12}>
                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                <tbody>
                                    <tr>
                                        <th>Employee Name</th><td>{businessTrip.employee.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Division</th><td>{businessTrip.employee.division.division_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Position</th><td>{businessTrip.employee.position.position_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Date</th><td>{toFullDate(businessTrip.from_date)} - {toFullDate(businessTrip.to_date)}</td>
                                    </tr>
                                    <tr>
                                        <th>Purpose</th><td>{businessTrip.purpose}</td>
                                    </tr>
                                    <tr>
                                        <th>Destination</th><td>{(businessTrip.trip_type.detail_code==="TTD") ? businessTrip.city.city_name : businessTrip.country.country_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Amount</th><td>{toIdr(businessTrip.total_amount)}</td>
                                    </tr>
                                    <tr>
                                        <th>Trip Planning</th><td>{businessTrip.trip_plan}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card>
                <Card bordered className="mb-4">
                    <Text strong>COST PLANNING</Text>
                    <Row className="mb-4 mt-1">
                        <Col span={24}>
                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th>Item Name</th>
                                        <th>Amount</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        businessTrip.costs.map((el, key) => (
                                            <tr key={key}>
                                                <td width="30%">{el.description}</td>
                                                <td width="25%">{toIdr(el.amount)}</td>
                                                <td width="20%">{el.quantity}</td>
                                                <td width="25%">{toIdr(el.total)}</td>
                                            </tr>
                                        ))
                                    }
                                    
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card>

                <Card bordered className="mb-4">
                    <Text strong>COST REALIZATION</Text>
                    <Form form={form} size="middle" layout="vertical" onFinish={submitBusinessTrip}>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Item Name</th>
                                            <th>Amount</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            costItem.map((el, key) =>(
                                                <tr key={'bnt_item'+key}>
                                                    <td width="30%" style={{ verticalAlign: 'top'}}>
                                                        {costItem[key].description}
                                                    </td>
                                                    <td width="25%">
                                                        <Form.Item name={`cost_price_${key}`} rules={[{required:true}]}>
                                                            <Input placeholder="Price" onChange={(v)=>changeItemPrice(v, key)}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td width="20%">
                                                        <Form.Item name={`cost_qty_${key}`} rules={[{required:true}]}>
                                                            <Input type="number" placeholder="Quantity" onChange={(v) => changeItemQty(v, key)}/>
                                                        </Form.Item>
                                                    </td>
                                                    <td width="25%">
                                                        <Form.Item name={`cost_total_${key}`} value={costItem[key].amount_realization} rules={[{required:true}]}>
                                                            <Input placeholder="Total" readOnly/>
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                        <tr style={{borderTop: '1px solid #ededed'}}>
                                            <td className="text-right" colSpan={3}>Total : </td>
                                            <td>{toIdr(totalCost)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="text-right">
                                <SubmitButton loading={props.saving}/>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div> : <Spinner/>
    );
}

export default RealizationForm;
