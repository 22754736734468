import React from 'react'
import { FormModal } from '../../../../../../components'
import { Col, Form, Input, InputNumber, Row, Slider, TimePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { addProgress, changeProgressValue, onClickShowModalAddProgress } from '../../../../../../redux/slices/Dashboard/myTaskSlice'

const ModalAddTaskProgress = () => {
    const dispatch = useDispatch()
    const modalAddTaskProgress = useSelector((state) => state.myTask.modalAddTaskProgress)
    const myProgressPercentage = useSelector((state) => state.myTask.myProgressPercentage)
    const progressValue = useSelector((state) => state.myTask.progressValue)
    const defaultValues = useSelector((state) => state.myTask.defaultValues)

    return (
        <FormModal isAdd title="Add Progress" showModal={modalAddTaskProgress} defaultValues={defaultValues} submitForm={(v) => dispatch(addProgress(v))} width={500} onCloseModal={() => dispatch(onClickShowModalAddProgress(false))}>
            <Form.Item label="Job Description" name="job_description" rules={[{ required: true }]}>
                <Input.TextArea rows={4} placeholder="Job Description" />
            </Form.Item>
            <Form.Item label="Remarks" name="remarks" rules={[{ required: true }]}>
                <Input placeholder="Remarks" />
            </Form.Item>
            <Form.Item label="Time" name="time">
                <TimePicker.RangePicker />
            </Form.Item>
            <Form.Item label="Progress">
                <Row justify='space-around'>
                    <Col span={16}>
                        <Slider min={0} max={100 - myProgressPercentage} value={progressValue} onChange={(v) => dispatch(changeProgressValue(v))} />
                    </Col>
                    <Col span={4}>
                        <InputNumber
                            min={1}
                            max={100 - myProgressPercentage}
                            value={progressValue}
                            onChange={(v) => dispatch(changeProgressValue(v))}
                        />
                    </Col>
                </Row>
            </Form.Item>
        </FormModal>
    )
}

export default ModalAddTaskProgress