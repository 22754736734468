import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, EditButton, ShowButton } from '../../../../components';
import { toFullDate } from '../../../../utils/dateHandler';
import { Button, Row, Space, Tag } from 'antd';
import { PlusCircleOutlined, ArrowsAltOutlined } from '@ant-design/icons';
import {
    fetchEmployee,
    fetchMyTask,
    editProgressTask,
    addTask,
    showModalExtend
} from '../../../../redux/slices/Dashboard/myTaskSlice';
import ModalAddTask from './Modal/Task/ModalAddTask';
import moment from 'moment';
import ModalEditProgressTask from './Modal/Task/ModalEditTaskProgress';
import ModalAddTaskProgress from './Modal/Task/ModalAddTaskProgress';
import ModalConfirm from './Modal/Task/ModalConfirm';
import ModalExtend from './Modal/Task/ModalExtend';

const MyTask = () => {
    const dispatch = useDispatch()
    const loaded = useSelector((state) => state.myTask.loaded)
    const datas = useSelector((state) => state.myTask.datas)
    const showModalAddTask = useSelector((state) => state.myTask.showModalAddTask)
    const editProgressTaskModal = useSelector((state) => state.myTask.editProgressTaskModal)
    const modalAddTaskProgress = useSelector((state) => state.myTask.modalAddTaskProgress)
    const modalConfirm = useSelector((state) => state.myTask.modalConfirm)
    const modalExtend = useSelector((state) => state.myTask.modalExtend)

    useEffect(() => {
        dispatch(fetchMyTask())
        dispatch(fetchEmployee())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onShowModalExtend = (v) => {
        dispatch(showModalExtend({ status: true, data: v }))
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        }, {
            title: 'Task From',
            render: (row) => (
                <Tag color='blue'>
                    {row?.task_from?.name}
                </Tag>
            ),
            align: 'center',
            key: 'tab'
        },
        {
            title: 'Task Origin',
            dataIndex: 'data_from',
            key: 'data_from',
        },
        {
            title: 'Description',
            render: (row) => (
                row.description !== null ?
                    row.description.length > 150 ? `${row?.description.substring(0, 150)}...` : row?.description
                    : "-"
            ),
            key: 'description',
        },
        {
            title: 'Deadline',
            key: 'deadline',
            render: (row) => (
                toFullDate(row?.due_date)
            )
        },
        {
            title: 'Finished Date',
            render: (row) => (
                row?.finished_date === null || row?.finished_date === "0000-00-00 00:00:00" ? '-' : moment(row?.finished_date).format('DD MMMM YYYY')
            ),
            key: 'finished_date',
            align: 'center'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            filters: [
                {
                    text: 'In Progress',
                    value: 'In Progress'
                },
                {
                    text: 'Finished',
                    value: 'Finished'
                }
            ],
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    {
                        row.status === "Finished" ?
                            <EditButton disabled={true} onEdit={() => dispatch(editProgressTask({ data: row }))} />
                            :
                            <EditButton onEdit={() => dispatch(editProgressTask({ data: row }))} />
                    }
                    <ShowButton onShow={() => dispatch(editProgressTask({ data: row, stat: 'show' }))} />
                    <Button disabled={row.status === "Finished" ? true : false} type='link' onClick={() => onShowModalExtend(row)} icon={<ArrowsAltOutlined />} />
                </Space>
            ),
            key: 'action',
        }
    ];

    return (
        <>
            <div>
                <Row style={{ margin: "5px 0 5px 0" }}>
                    <Button type="primary" color="#ededed" onClick={() => dispatch(addTask())} icon={<PlusCircleOutlined />}>
                        Add Task
                    </Button>
                </Row>
                {
                    loaded ?
                        <DataTable columns={columns} dataSource={datas} bordered size="small" />
                        : null
                }
            </div>

            {
                showModalAddTask ?
                    <ModalAddTask />
                    : null
            }

            {
                editProgressTaskModal ?
                    <ModalEditProgressTask />
                    : null
            }
            {
                modalAddTaskProgress ?
                    <ModalAddTaskProgress />
                    : null
            }
            {
                modalConfirm ?
                    <ModalConfirm />
                    : null
            }

            {
                modalExtend ?
                    <ModalExtend />
                    : null
            }
        </>
    )
}

export default MyTask