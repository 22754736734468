import { useState, useEffect } from 'react'
import { Card, Col, DatePicker, Form, Input, Row, Select, message } from 'antd'
import { PageTitle, SubmitButton, Uploader } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'

const { Option } = Select

const Detail = () => {
    const history = useHistory()
    const { id } = useParams()
    const [formLegal] = Form.useForm()
    const [company, setCompany] = useState([])
    const [saving, setSaving] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [attachmentFile, setAttachmentFile] = useState(null)

    const fetchDocLegalitas = () => {
        SentralModel.action('Documentation', 'getLegalitasById', null, id).then((res) => {
            formLegal.setFieldsValue({
                title: res.data.title,
                issued_date: moment(res.data.issued_date),
                company_code: res.data.company_code,

            })
        })
    }

    const fetchCompany = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ISOP']], orderBy: 'order_key', order: 'asc' })
            .then(res => {
                setCompany(res.data)
            })
    }

    useEffect(() => {
        fetchCompany()

        if (parseInt(id) !== 0) {
            fetchDocLegalitas()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const saveLegal = (v) => {
        let payload = {
            ...v,
            doc_legal_id: id,
            attachment: attachmentFile
        }

        SentralModel.action('Documentation', 'saveLegalitas', payload, id).then((res) => {
            setSaving(false)
            message.success(`Success ${parseInt(id) === 0 ? "Add" : "Edit"} Document`)
            setTimeout(() => {
                history.push('/documentation/legalitas')
            }, 1000)
        }).catch((err) => {
            setSaving(false)
            message.success(`Failed ${parseInt(id) === 0 ? "Add" : "Edit"} Document`)
        })
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message);
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Legalitas" breadcrumbs={[['Legalitas', '/documentation/legalitas'], ['Detail']]}></PageTitle>
                <Form size="middle" form={formLegal} layout="vertical" onFinish={saveLegal}>
                    <Form.Item name="doc_legal_id" hidden>
                        <Input />
                    </Form.Item>
                    <Row justify="center">
                        <Col span={20}>
                            <Row justify="space-around">
                                <Col span={10}>
                                    <Form.Item label="Title" name="title" required rules={[{ required: true }]}>
                                        <Input placeholder="Title" />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="Issued Date" name="issued_date" required rules={[{ required: true }]}>
                                        <DatePicker style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="space-around">
                                <Col span={10}>
                                    <Form.Item label="Company" name="company_code" required rules={[{ required: true }]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Company" optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                company.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item label="Attachment">
                                        <Uploader dragger folder="legalitas" accept={'.png,.jpg,.jpeg,.pdf,.doc,.docx,.xls,.xlsx,.ppt'} onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify='space-around'>
                                <Col span={10}>
                                    <SubmitButton loading={saving || uploading} />
                                </Col>
                                <Col span={10}></Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div >
    )
}

export default Detail