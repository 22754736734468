import currentUser from "../../../../helpers/authData"
import { BsCheckCircle, BsExclamationCircle } from "react-icons/bs"

const Weekly = ({ data }) => {
    return (
        <div style={{ overflow: 'scroll', overflowY: "hidden" }}>
            <table className="styled-table">
                <thead>
                    <tr>
                        <th rowSpan={2} style={{ width: '10%', border: '0.5px solid grey', fontSize: 16 }}>Date</th>
                        {
                            data.datas.map((el, key) => (
                                <th colSpan={currentUser.employee_code === 'ADM001' ? (data.employee.length) : (data.employee.length - 1)} key={key} style={{ border: '0.5px solid grey', fontSize: 16 }}>{el.job_description}</th>
                            ))
                        }
                    </tr>
                    <tr>
                        {
                            data.datas.map(() => (
                                currentUser.employee_code === 'ADM001' ?
                                    data.employee.map((el, key) => (
                                        <th style={{ border: '0.5px solid grey', fontSize: 16 }}>{el.name}</th>
                                    )) :
                                    data.employee.filter((emp) => emp.employee_code !== 'ADM001').map((el, key) => (
                                        <th style={{ border: '0.5px solid grey', fontSize: 16 }}>{el.name}</th>
                                    ))
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data.week.map((el, key) => (
                            <tr key={key}>
                                <td style={{ border: '0.5px solid grey', textAlign: 'center' }}>{`Week ${key + 1}`}</td>
                                {
                                    data.datas.map((i) => (
                                        currentUser.employee_code === 'ADM001' ?
                                            i.employee.map((emp) => (
                                                emp.job.length === 0 ?
                                                    <td style={{ border: '0.5px solid grey', textAlign: 'center' }}>
                                                        <BsExclamationCircle style={{ fontSize: "20px", fontWeight: 700 }} color="ff0000" />
                                                    </td> :
                                                    emp.job.filter((job) => job.week === `Week ${key + 1}`)[0]
                                                        ?
                                                        <td style={{ border: '0.5px solid grey', textAlign: 'center' }}>  <BsCheckCircle
                                                            style={{ fontSize: "20px", fontWeight: 700 }} color="#00ff48" /> </td> :
                                                        <td style={{ border: '0.5px solid grey', textAlign: 'center' }}><BsExclamationCircle style={{ fontSize: "20px", fontWeight: 700 }} color="ff0000" /></td>
                                            )) :
                                            i.employee.filter((emps) => emps.employee_code !== 'ADM001').map((emp) => (
                                                emp.job.length === 0 ?
                                                    <td style={{ border: '0.5px solid grey', textAlign: 'center' }}>
                                                        <BsExclamationCircle style={{ fontSize: "20px", fontWeight: 700 }} color="ff0000" />
                                                    </td> :
                                                    emp.job.filter((job) => job.week === `Week ${key + 1}`)[0]
                                                        ?
                                                        <td style={{ border: '0.5px solid grey', textAlign: 'center' }}>  <BsCheckCircle style={{ fontSize: "20px", fontWeight: 700 }} color="#00ff48" /> </td> :
                                                        <td style={{ border: '0.5px solid grey', textAlign: 'center' }}><BsExclamationCircle style={{ fontSize: "20px", fontWeight: 700 }} color="ff0000" /></td>
                                            ))
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Weekly