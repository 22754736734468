import React, { useState, useEffect } from 'react';
import { Card, Tabs, Typography, Modal, Timeline, Row, Col, Table } from 'antd'
import { CalendarOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { NewButton, PageTitle, Spinner } from '../../../components'
import LeaveRequest from './LeaveRequest'
import CancellationRequest from './CancellationRequest'
import { SentralModel } from '../../../models/SentralModel';
import authData from '../../../helpers/authData'
import moment from 'moment'
import RealizationRequest from './RealizationRequest';

const { TabPane } = Tabs;
const { Text } = Typography;

const Index = () => {
    const [leaves, setLeaves] = useState([])
    const [balances, setBalances] = useState([])
    const [showLogs, setShowLogs] = useState(false)
    const [logsData, setLogsData] = useState([])
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const fetchBalances = () => {
        setLoaded(false)

        SentralModel.action('Submission', 'getLeaveRights', {}).then(res => {
            setLoaded(true)
            setBalances(res.data)
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction', { filter: [['code', 'SBLV']] }, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission', 'checkSchema', { category: 'LEAVE', division_id: authData.division_id }).then((res) => {
            setAvailable(res.message)
        })
    }

    const fetchLeaves = () => {
        let payload = {
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionLeave', payload).then(res => {
            setLeaves(res.data)
        })
    }

    useEffect(() => {
        fetchBalances()
        fetchLeaves()
        checkApprovalSchema()
        fetchInstruction()
    }, []);

    const balanceData = (balances) ? [
        ...balances.filter(item => item.leave_id !== 4 && item.leave_id !== 5 && item.leave_id !== 8).map(el => {
            // if (el.leave.type === 'Annual' && el.public_mass) {
            //     if (el.extend) {
            //         return {
            //             leave: el.leave.description,
            //             quota: el.extend + ' (' + el.extend_year + ') + ' + el.quota + ' (' + el.current_year + ') - ' + el.public_mass + ' (cuti bersama)  =  ' + (el.extend + el.quota - el.public_mass),
            //             used: el.used,
            //             balance: el.balance - el.public_mass + el.extend
            //         }
            //     } else {
            //         return {
            //             leave: el.leave.description,
            //             quota: el.quota + ' - ' + el.public_mass + ' (cuti bersama) = ' + (el.quota - el.public_mass),
            //             used: el.used,
            //             balance: el.balance - el.public_mass
            //         }
            //     }
            // } else {
            //     return {
            //         leave: el.leave.description,
            //         quota: el.quota,
            //         used: el.used,
            //         balance: el.balance
            //     }
            // }
            return {
                leave: el.leave.description,
                quota: el.quota,
                used: el.used,
                balance: el.balance,
                type: el.leave.type,
                mass_leave: el.public_mass ?? null,
                extend: el.extend ?? null,
                extend_year: el.extend_year ?? null,
                current_year: el.current_year ?? null,
            }
        })
    ] : [];

    const balanceColumn = [
        {
            title: 'Leave',
            dataIndex: 'leave',
            key: 'leave',
            width: '30%',
            align: 'center',
            render: (text) => (
                <div style={{ textAlign: 'left' }}>
                    {text}
                </div>
            )
        }, {
            title: 'Quota',
            width: '30%',
            align: 'center',
            render: (row) => {
                if (row.type === 'Annual') {
                    return <div>
                        {
                            row.extend ?
                                <>
                                    {row.extend} ({row.extend_year}) <br />
                                    {row.quota} ({row.current_year}) <br />
                                    {
                                        row.mass_leave ?
                                            <>
                                                -{row.mass_leave} (cuti bersama)
                                            </>
                                            : null
                                    }
                                </> :
                                <>
                                    {row.quota} <br />
                                    {
                                        row.mass_leave ?
                                            <>
                                                -{row.mass_leave} (cuti bersama)
                                            </>
                                            : null
                                    }
                                </>
                        }
                    </div>
                } else {
                    return <div>
                        {row.quota}
                    </div>
                }
            }
        }, {
            title: 'Used',
            dataIndex: 'used',
            key: 'used',
            width: '20%',
            align: 'center'
        }, {
            title: 'Balance',
            width: '20%',
            align: 'center',
            render: (row) => {
                if (row.type === 'Annual') {
                    let total = row.balance - (row.mass_leave ?? 0) + (row.extend ?? 0)
                    return <div>
                        {total}
                    </div>
                } else {
                    return <div>
                        {row.balance}
                    </div>
                }
            }
        }
    ];

    const showRequestLog = (v) => {
        let x = [];
        x.push({ color: 'green', date: v.created_at, description: 'Request Submitted', remarks: null, status: 'success' })
        v.approver.forEach(el => {
            if (el.status === "APPROVED") {
                x.push({ color: 'green', date: el.submitted_at, description: <span>{el.employee.name}  Approved your request</span>, remarks: el.remarks, status: 'success' })
            } else if (el.status === "REJECTED") {
                x.push({ color: 'red', date: el.submitted_at, description: <span>{el.employee.name}  Rejected your request</span>, remarks: el.remarks, status: 'danger' })
            } else {
                x.push({ color: 'gray', date: null, description: 'Waiting approval ' + el.employee.name, remarks: null, status: "default" })
            }
        });
        setLogsData(x);
        setShowLogs(true);
    }

    return (
        <Card className="content-container">
            <PageTitle title="Leave" breadcrumbs={[['Leave']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Pengajuan Leave</Text>
                    <NewButton disabled={!available} right to="/leave/create" title={(available) ? 'Apply for leave' : 'Approval not available'} />
                </div>
            }>
                {
                    (instruction) ?
                        <div>
                            <Row className="mb-3">
                                <Col span={24} className="instruction-box">
                                    <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                                </Col>
                            </Row>
                            <Table columns={balanceColumn} dataSource={balanceData} size='middle' bordered={true} pagination={false} />
                        </div>
                        : <Spinner />
                }
            </Card>
            <br />
            {
                (loaded) ?
                    <Tabs defaultActiveKey="request" type="card">
                        <TabPane tab={<span><CalendarOutlined style={{ color: '#4ca3ff' }} /> Leave Request</span>} key="request">
                            <LeaveRequest data={leaves.filter(el => el.cancellation_status !== 'WAITING')} showRequestLog={(v) => showRequestLog(v)} />
                        </TabPane>
                        <TabPane tab={<span><CloseSquareOutlined style={{ color: 'red' }} /> Cancellation Request</span>} key="cancellation">
                            <CancellationRequest data={leaves.filter(el => el.cancellation_status)} showRequestLog={(v) => showRequestLog(v)} />
                        </TabPane>
                        <TabPane tab={<span><CalendarOutlined style={{ color: 'green' }} />  Realization Request</span>} key="realization">
                            <RealizationRequest data={leaves.filter(el => el.status_realization !== null && el.leave_id === 5)} showRequestLog={(v) => showRequestLog(v)} />
                        </TabPane>

                    </Tabs>
                    : null
            }

            {
                (showLogs) ?
                    <Modal
                        title="Request Status"
                        visible={showLogs}
                        footer={null}
                        onCancel={() => setShowLogs(false)}>
                        <Timeline>
                            {
                                logsData.map((el, key) => (
                                    <Timeline.Item key={key} color={el.color}>
                                        {
                                            (el.status === "success" || el.status === "danger") ?
                                                <div>
                                                    <span style={{ fontWeight: 'bold' }}>{moment(el.date).format('D MMMM YYYY, h:mm a')}</span><br />
                                                    <span>{el.description}</span><br />
                                                    <span>{el.remarks}</span>
                                                </div> :
                                                <div>
                                                    <span className="text-muted" style={{ fontWeight: 'bold' }}>{el.description}</span>
                                                </div>
                                        }
                                    </Timeline.Item>
                                ))
                            }
                        </Timeline>
                    </Modal>
                    : null
            }
        </Card>
    );
}

export default Index;
