import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Tabs, Typography, Descriptions} from 'antd'
import {CalendarOutlined} from '@ant-design/icons'
import { PageTitle, NewButton} from '../../../components'
import CashAdvanceRequest from './Request'
import RealizationRequest from './RealizationRequest'
import authData from '../../../helpers/authData'
import {SentralModel} from '../../../models/SentralModel'
import {toIdr} from '../../../utils/currencyHandler'

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [cashAdvances, setCashAdvances] = useState(null)
    const [cashAdvanceInfo, setCashAdvanceInfo] = useState(null);
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const fetchCashAdvances = () => {
        setLoaded(false)
        let payload={
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionCashAdvance', payload).then(res => {
            setCashAdvances(res.data)
            setLoaded(true)
        })
    }

    const fetchCashAdvanceInfo = () => {
        SentralModel.action('Submission', 'getMonthlyCashAdvance', null, null).then((res) => {
            setCashAdvanceInfo(res.data[0])
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction',{filter:[['code','SBCA']]}, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission','checkSchema', {category: 'CASHADVANCE', division_id: authData.division_id}).then((res) => {
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchCashAdvances()
        fetchCashAdvanceInfo()
        fetchInstruction()
        checkApprovalSchema()
    }, []);

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Cash Advance" breadcrumbs={[['Cash Advance']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Request Cash Advance</Text>
                        <NewButton disabled={!available} right to="/cash-advance/create" title={(available) ? "Request Cash Advance" : 'Approval not available'}/>
                    </div>
                }>
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                <Descriptions.Item label={<Text strong>Cash Advance This Month</Text>}>
                                    {
                                        (cashAdvanceInfo) ? cashAdvanceInfo.total_submission : '0'
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label={<Text strong>Total Reimbursed</Text>}>
                                    {
                                        (cashAdvanceInfo) ? toIdr(cashAdvanceInfo.total_amount) : 'Rp 0'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                </Card>
                <br/>
                {
                    (loaded) ? 
                    <Tabs defaultActiveKey="request" type="card">
                        <TabPane tab={<span><CalendarOutlined style={{color:'#4ca3ff'}}/> Request</span>} key="request">
                            <CashAdvanceRequest data={cashAdvances.filter(el => el.status_realization === null || el.status_realization === 'REJECTED')}/>
                        </TabPane>
                        <TabPane tab={<span><CalendarOutlined style={{color:'green'}}/> Realization Request</span>} key="realization">
                            <RealizationRequest data={cashAdvances.filter(el => el.status_realization !== null)}/>
                        </TabPane>
                    </Tabs> : null
                }
            
            </Card>
        </div>
    );
}

export default Index;
