import React, {useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../components'
import RequestForm from './RequestForm'
import {useHistory} from 'react-router-dom'
import {SentralModel} from '../../../models/SentralModel'

const Create = () => {
    const history = useHistory();
    const [saving, setSaving] = useState(false);

    const submitVoucher = (v)=>{
        setSaving(true)
        SentralModel.action('Submission', 'saveVoucher', v, 0).then(() => {
            message.destroy();
            message.success('Voucher submitted', 1);
            setSaving(false);
            setTimeout(() => {
                history.push('/voucher');
            }, 1000);
        }).catch(() => {
            message.destroy();
            message.error('Terjadi kesalahan, silahkan coba lagi', 2);
            setSaving(false);
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Voucher Submission" breadcrumbs={[['Voucher', '/voucher'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitVoucher(v)} saving={saving}/>
        </Card>
    );
}

export default Create;
