import React from 'react';
import {Card, Tabs} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {DataTable, ShowButton} from '../../../components'

const {TabPane} = Tabs;

const Index = (props) => {

    const voucherData = [
        ...props.data.map((el) => {
            return{
                id:el.request_id,
                name:el.name,
                title: el.title,
                type: el.type,
                total: el.total,
                division_name: el.division_name,
                status: el.status,
                submitted_at: el.submitted_at
            }
        })
    ]

    const voucherColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'Employee Name',
            dataIndex: 'name',
            key: 'name'
        },{
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },{
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },{
            title: 'Total Voucher',
            dataIndex: 'total',
            key: 'total',
            width: '15%',
            render:(text) => (
                <div className='text-center'>
                    {text}
                </div>
            )
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/voucher/'+row.id} />
                </div>
            )
        }
    ];

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={voucherColumn} dataSource={voucherData.filter(el => el.status==='WAITING')} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={voucherColumn} dataSource={voucherData.filter(el => el.status==='APPROVED')} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={voucherColumn} dataSource={voucherData.filter(el => el.status==='REJECTED')} bordered/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
