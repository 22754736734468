import React, { useEffect, useState } from 'react';
import { Card, Typography, Button, Popconfirm, message, Space } from 'antd'
import { PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { CalendarOutlined, SendOutlined } from '@ant-design/icons'

const { Text } = Typography

const Period = () => {
    const [periods, setPeriods] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [sendingNotification, setSendingNotification] = useState(false)

    const fetchPeriods = () => {
        SentralModel.list('KpiPraAppraisalPeriod', { orderBy: 'year', order: 'asc' }).then(res => {
            setPeriods(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchPeriods()

    }, []);

    const addPeriod = () => {
        setLoaded(false)
        const year = periods.length === 0 ? new Date().getFullYear() : parseInt(periods[periods.length - 1].year) + 1
        let payload = {
            year: year,
            is_active: 0
        }

        SentralModel.save('KpiPraAppraisalPeriod', payload, 0).then(res => {
            message.success('Periode Berhasil Ditambahkan', 2)
            fetchPeriods()
        })
    }

    const changeActivePeriod = (year) => {
        setLoaded(false)
        SentralModel.action('PraAppraisal', 'setActivePeriod', { year: year }, 0).then(() => {
            message.success('Periode Aktif Diubah')
            fetchPeriods()
        })
    }

    const sendNotification = () => {
        setSendingNotification(true)
        message.loading('sending notification', 0)
        SentralModel.action('PraAppraisal', 'sendNotification', {}, 0).then((res) => {
            message.destroy()
            message.success('Notifikasi berhasil dikirimkan', 2)
            setSendingNotification(false)
        }).catch(() => {
            message.destroy()
        })
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="KPI Period" breadcrumbs={[['Pra Appraisal', '/management/pra-appraisal'], ['Periode KPI']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Period List</Text>
                        <Space style={{ float: 'right' }}>
                            <Popconfirm title="Kirim Notifikasi pengisian form pra appraisal?" onConfirm={sendNotification} okText="Ya" cancelText="Tidak">
                                <Button loading={sendingNotification} type="primary" icon={<SendOutlined />}>
                                    Send Notification
                                </Button>
                            </Popconfirm>
                            <Popconfirm title="Tambahkan periode Baru?" onConfirm={addPeriod} okText="Ya" cancelText="Tidak">
                                <Button style={{ float: 'right' }} type="primary" icon={<CalendarOutlined />}>
                                    Add Period
                                </Button>
                            </Popconfirm>
                        </Space>
                    </div>}>
                    {
                        (loaded) ?
                            <div>
                                <table className="table-collapse table-default" style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50px' }} className="text-center">No</th>
                                            <th>Year</th>
                                            <th className="text-center" style={{ width: '20%' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            periods.map((el, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{i + 1}</td>
                                                    <td>
                                                        {el.year}
                                                        {
                                                            (el.is_active) ?
                                                                <span className="text-muted"> (Active Period)</span>
                                                                : ''
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        {
                                                            (!el.is_active) ?
                                                                <Popconfirm
                                                                    title="Ganti Periode Aktif ?"
                                                                    onConfirm={() => changeActivePeriod(el.year)}
                                                                    okText="Ya"
                                                                    cancelText="Tidak"
                                                                >
                                                                    <Button type="link" >
                                                                        Set Active
                                                                    </Button>
                                                                </Popconfirm>
                                                                : '-'
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                            </div>
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Period;
