import React, {useState, useEffect} from 'react';
import {Card, Descriptions, Button, Typography, Input, Select, message, Popconfirm} from 'antd';
import {PageTitle, Spinner} from '../../../components'
import {CloseCircleOutlined, PlusCircleOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { SentralModel } from '../../../models/SentralModel';
import {useParams} from 'react-router-dom'
import {toIdr, extractNumber} from '../../../utils/currencyHandler'
import {toFullDate} from '../../../utils/dateHandler'
const {Text} = Typography
const {Option} = Select

function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0);
    return () => setValue(value => ++value);
}

const Detail = () => {
    const {employeeCode} = useParams()
    const forceUpdate = useForceUpdate()
    const [saving, setSaving] = useState(false)
    const [employee, setEmployee] = useState(null)
    const [allowances, setAllowances] = useState(null)
    const [deductions, setDeductions] = useState(null)
    const [absentPenalty, setAbsentPenalty] = useState(0)
    const [components, setComponents] = useState([])
    const [overtimeCategories, setOvertimeCategories] = useState([])
    const [lateCategories, setLateCategories] = useState([])
    const [dailyIncentive, setDailyIncentive] = useState(0)
    const [weeklyIncentive, setWeeklyIncentive] = useState(0)
    const [loaded, setLoaded] = useState(false)

    const fetchComponents = () => {
        SentralModel.list('PayrollComponent').then((res)=>{
            setComponents(res.data)
        })
    }

    const fetchSchema = (employeeCode) =>{
        setLoaded(false)
        SentralModel.action('Payroll', 'getSchemaSetup', {employee_code: employeeCode},0).then((res)=>{
            setEmployee(res.data.employee)
            setLateCategories(res.data.late)
            setOvertimeCategories(res.data.overtime)
            if(res.data.schema.allowances.length>0){
                setAllowances(res.data.schema.allowances)
            }else{
                setAllowances([{
                    component_code:null,
                    description:null,
                    amount:null,
                    type:null
                }])
            }
            if(res.data.schema.deductions.length>0){
                setDeductions(res.data.schema.deductions)
            }else{
                setDeductions([{
                    component_code:null,
                    description:null,
                    amount:null,
                    type:null
                }])
            }
            setAbsentPenalty(res.data.absentPenalty)
            setDailyIncentive(res.data.daily_incentive)
            setWeeklyIncentive(res.data.weekly_incentive)
            setLoaded(true)
        })
    }
    
    useEffect(() => {
        fetchComponents()
        fetchSchema(employeeCode)
    }, [employeeCode]);

    const addAllowances = () => {
        let x = allowances;
        x.push({
            component_code:null,
            description:null,
            amount:null,
            type:null
        })
        setAllowances(x)
        forceUpdate()
    }

    const resetSchema = () =>{
        setLoaded(false)
        setAllowances(null)
        setDeductions(null)
        SentralModel.action('Payroll','resetSchema', {employee_code:employeeCode}, 0).then(()=>{
            message.success('Schema Has been reset', 2)
            fetchSchema(employeeCode)
        })
    }

    const addDeductions = () => {
        let x = deductions;
        x.push({
            component_code:null,
            description:null,
            amount:null,
            type:null
        })
        setDeductions(x)
        forceUpdate()
    }

    const submitSchema = () =>{
        setSaving(true)
        let schemaData={
            employee_code:employeeCode,
            allowances: allowances,
            deductions: deductions,
            late:lateCategories,
            overtime:overtimeCategories,
            absent_penalty: absentPenalty,
            daily_incentive: dailyIncentive,
            weekly_incentive: weeklyIncentive
        }
        SentralModel.action('Payroll', 'saveSchema',schemaData ,0).then(()=>{
            message.success("Payroll Schema successfully updated", 2)
            setSaving(false)
        }).catch(()=>{
            setSaving(false)
        })
    }

    return (
        <Card className="content-container">
            <PageTitle title="Payroll Schema" breadcrumbs={[['Payroll Schema','/payroll/schema'],['Edit']]}></PageTitle>
            {
                (loaded)?
                    <Descriptions className="mb-3" size="middle" column={4} bordered layout="vertical">
                        <Descriptions.Item label={<div className="text-center"><Text strong>Employee Name</Text></div>}>
                            <div className="text-center">{employee.name}</div>
                        </Descriptions.Item>
                        <Descriptions.Item label={<div className="text-center"><Text strong>Division</Text></div>}>
                            <div className="text-center">{employee.division.division_name}</div>
                        </Descriptions.Item>
                        <Descriptions.Item label={<div className="text-center"><Text strong>Position</Text></div>}>
                            <div className="text-center">{employee.position.position_name}</div>
                        </Descriptions.Item>
                        <Descriptions.Item label={<div className="text-center"><Text strong>Join Date</Text></div>}>
                            <div className="text-center">{toFullDate(employee.join_date)}</div>
                        </Descriptions.Item>
                    </Descriptions>
                : null
            }
            {
                (allowances && deductions) ?
                    <div>
                        <div className="full-width mt-2 mb-2 text-right">
                            <Popconfirm title="Reset payroll schema...?" okText="Yes" cancelText="No" onConfirm={() => resetSchema()}>
                                <Button size="middle" type="danger" icon={<UndoOutlined />}>Reset Schema</Button> &nbsp;
                            </Popconfirm>
                        </div>
                        <table className="table-default table-collapse" style={{width:'100%'}}>
                            <thead>
                                <tr>
                                    <th className="text-center" style={{width:'50%'}}>Allowances</th>
                                    <th className="text-center" >Deductions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{verticalAlign:"top"}}>
                                        <table className="table-noborder" style={{width:'100%'}}>
                                            <thead>
                                                    <tr>
                                                        <th style={{width:'60%'}}>Component</th>
                                                        <th>Value</th>
                                                        <th style={{width:'7%'}}>
                                                            <Button type="primary" onClick={addAllowances} icon={<PlusCircleOutlined />}/>
                                                        </th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    allowances.map((alw, key)=>(
                                                        <tr key={'allowance_item_'+key}>
                                                            <td>
                                                                <Select value={alw.component_code} style={{width:'100%'}} placeholder="select component" onChange={(v, e)=>{
                                                                    let x=allowances
                                                                    x[key].component_code=v
                                                                    x[key].description=e.children
                                                                    let f = components.find(a => a.component_code===v)
                                                                    x[key].type=f.type
                                                                    setAllowances(x)
                                                                    forceUpdate()
                                                                }}>
                                                                    {
                                                                        components.filter(el => el.category==="ALLOWANCE").map((el, key2)=>(
                                                                            <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </td>
                                                            <td>
                                                                <Input value={toIdr(alw.amount)} onChange={(v)=>{
                                                                    let x=allowances
                                                                    x[key].amount=extractNumber(v.target.value)
                                                                    setAllowances(x)
                                                                    forceUpdate()
                                                                }}/>
                                                            </td>
                                                            <td className="text-center">
                                                                <Button type="danger" onClick={()=>{
                                                                    let x=allowances
                                                                    x.splice(key, 1);
                                                                    setAllowances(x)
                                                                    forceUpdate()
                                                                }} icon={<CloseCircleOutlined />}/>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <th colSpan="3">Overtime Incentive</th>
                                                </tr>
                                                {
                                                    overtimeCategories.map((ovt, key)=>(
                                                        <tr key={key}>
                                                            <td>
                                                                <Input value={ovt.description+' | '+ovt.category} readOnly/>
                                                            </td>
                                                            <td colSpan="2">
                                                                <Input addonAfter="/jam" value={toIdr(ovt.amount)} onChange={(v) => {
                                                                    let x = overtimeCategories
                                                                    x[key]['amount'] = extractNumber(v.target.value)
                                                                    forceUpdate()
                                                                }}/>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <th colSpan="3">Daily Incentive</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Input value="Daily Incentive" readOnly/>
                                                    </td>
                                                    <td colSpan="2">
                                                        <Input addonAfter="/hari" value={toIdr(dailyIncentive)} onChange={(v) => {
                                                            setDailyIncentive(extractNumber(v.target.value))
                                                        }}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th colSpan="3">Weekly Incentive</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Input value="Weekly Incentive" readOnly/>
                                                    </td>
                                                    <td colSpan="2">
                                                        <Input addonAfter="/minggu" value={toIdr(weeklyIncentive)} onChange={(v) => {
                                                            setWeeklyIncentive(extractNumber(v.target.value))
                                                        }}/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td style={{verticalAlign:"top"}}>
                                        <table className="table-noborder" style={{width:'100%'}}>
                                            <thead>
                                                <tr>
                                                    <th style={{width:'60%'}}>Component</th>
                                                    <th>Value</th>
                                                    <th style={{width:'5%'}}>
                                                        <Button type="primary"  onClick={addDeductions} icon={<PlusCircleOutlined />}/>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    deductions.map((ded, key)=>(
                                                        <tr key={'deduction_item_'+key}>
                                                            <td>
                                                                <Select value={ded.component_code} style={{width:'100%'}} placeholder="select component" onChange={(v, e)=>{
                                                                    let x=deductions
                                                                    x[key].component_code=v
                                                                    x[key].description=e.children
                                                                    let f = components.find(a => a.component_code===v)
                                                                    x[key].type=f.type
                                                                    setDeductions(x)
                                                                    forceUpdate()
                                                                }}>
                                                                    {
                                                                        components.filter(el => el.category==="DEDUCTION").map((el, key2)=>(
                                                                            <Option key={key2} value={el.component_code}>{el.description}</Option>
                                                                        ))
                                                                    }
                                                                </Select>
                                                            </td>
                                                            <td>
                                                                <Input value={toIdr(ded.amount)} onChange={(v)=>{
                                                                    let x=deductions
                                                                    x[key].amount=extractNumber(v.target.value)
                                                                    setDeductions(x)
                                                                    forceUpdate()
                                                                }}/>
                                                            </td>
                                                            <td className="text-center">
                                                                <Button type="danger" onClick={()=>{
                                                                    let x=deductions
                                                                    x.splice(key, 1);
                                                                    setDeductions(x)
                                                                    forceUpdate()
                                                                }} icon={<CloseCircleOutlined />}/>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <th colSpan="3">Late Penalty</th>
                                                </tr>
                                                {
                                                    lateCategories.map((late, key)=>(
                                                        <tr key={key}>
                                                            <td>
                                                                <Input value={late.late_category_id} hidden/>
                                                                <Input value={late.description} readOnly/>
                                                            </td>
                                                            <td colSpan="2">
                                                                <Input value={toIdr(late.amount)} onChange={(v) => {
                                                                    let x = lateCategories
                                                                    x[key]['amount'] = extractNumber(v.target.value)
                                                                    forceUpdate()
                                                                }}/>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <th colSpan="3">Absent Penalty</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Input value="Absent Penalty" readOnly/>
                                                    </td>
                                                    <td colSpan="2">
                                                        <Input value={toIdr(absentPenalty)} onChange={(v) => {
                                                            setAbsentPenalty(extractNumber(v.target.value))
                                                            forceUpdate()
                                                        }}/>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="full-width text-right mt-2">
                            <Button loading={saving} type="primary" onClick={submitSchema} icon={<SaveOutlined />} size="large">Save</Button>
                        </div>
                    </div>
                :   
                    <Spinner/>
            }
        </Card>
    );
}

export default Detail;
