import { useEffect, useState } from 'react'
import { Card, Space, Tabs, Tag, Typography } from 'antd'
import { DataTable, PageTitle, ShowButton, Spinner } from '../../components'
import { SentralModel } from '../../models/SentralModel'
import { useHistory } from 'react-router-dom'
import { toIdr } from '../../utils/currencyHandler'
import moment from 'moment'

const { Text } = Typography
const { TabPane } = Tabs

const Index = () => {
    const [loaded, setLoaded] = useState(false)
    const [comissionData, setComissionData] = useState([])
    const history = useHistory()

    const fetchComissionList = () => {
        setLoaded(false)
        SentralModel.action("Comission", "getComissionList", null, null).then((res) => {
            setComissionData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchComissionList()

    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Employee',
            key: 'employee',
            render: (row) => (
                row?.employee?.name
            )
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Total Amount',
            key: 'total_amount',
            render: (row) => (
                toIdr(row?.total_amount)
            )
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                <Tag color={row.status === "APPROVED" ? "green" : "blue"}>{row.status}</Tag>
            ),
            align: 'center'
        },
    ];

    const waitingColumns = [];
    waitingColumns.push(
        ...columns,
        {
            title: 'Created Date',
            key: 'created_at',
            width: '12%',
            render: (row) => (
                moment(row.created_at).format('DD MMM YYYY')
            ),
            align: 'center'
        },
        {
            title: 'Action',
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton onShow={() => history.push(`/comission-list/${row.sub_commission_id}`)} />
                    </Space>
                </div>
            )
        },
    )

    const approvedColumns = [];
    approvedColumns.push(
        ...columns,
        {
            title: 'Approval Date',
            key: 'approval_date',
            width: '12%',
            render: (row) => (
                moment(row.approval_date).format('DD MMM YYYY')
            ),
            align: 'center'
        },
        {
            title: 'Action',
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <ShowButton onShow={() => history.push(`/comission-list/${row.sub_commission_id}`)} />
                    </Space>
                </div>
            )
        },
    )

    return (
        <Card className="content-container">
            <PageTitle title="Comission List" breadcrumbs={[['Comission List']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Comission List</Text>
                </div>
            }>
                <Tabs type="card" defaultActiveKey="comission-list">
                    {
                        loaded ?
                            <>
                                <TabPane tab="Waiting" key="waiting">
                                    <DataTable dataSource={comissionData?.waiting} columns={waitingColumns} />
                                </TabPane>
                                <TabPane tab="Approved" appraisal="approved">
                                    <DataTable dataSource={comissionData?.approved} columns={approvedColumns} />
                                </TabPane>
                            </>
                            : <Spinner />
                    }
                </Tabs>
            </Card>
        </Card>
    )
}

export default Index