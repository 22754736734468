import React from 'react';
import {Tabs, Space} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { DataTable, ShowButton} from '../../../components'
import {toFullDate} from '../../../utils/dateHandler'

const {TabPane} = Tabs;

const RealizationRequest = (props) => {
    const bntData = [
        ...props.data.map(el =>{
            return {
                id: el.sub_business_trip_id,
                from_date: el.from_date,
                to_date: el.to_date,
                purpose: el.purpose,
                trip_plan: el.trip_plan,
                trip_type: el.trip_type.detail_code,
                planning_cost: el.total_amount,
                city: el.city,
                country: el.country,
                total_days: el.total_days,
                total_amount: el.total_amount,
                status: el.status,
                status_realization: el.status_realization,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ]

    const businessTripColumn = [{
            title: 'No',
            dataIndex: 'no',
            key: 'name',
        },
        {
            title: 'Destination',
            render: (row) => (
                (row.trip_type==="TTD") ? row.city.city_name : row.country.country_name
            )
        },{
            title: 'Date',
            render: (row) => (
                <span>{toFullDate(row.from_date)} - {toFullDate(row.to_date)}</span>
            )
        },{
            title: 'Purpose',
            dataIndex: 'purpose',
            key: 'purpose',
        },{
            title: 'Trip Plan',
            dataIndex: 'trip_plan',
            key: 'trip_plan',
        },{
            title: 'Action',
            align:'center',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/business-trip-realization/' + row.id} />
                </Space>
            )
        }
    ];


    return (
        <div>
            <Tabs defaultActiveKey="waiting" type="card">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={businessTripColumn} dataSource={bntData.filter(el => el.status_realization==="WAITING")} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={businessTripColumn} dataSource={bntData.filter(el => el.status_realization==="APPROVED")} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={businessTripColumn} dataSource={bntData.filter(el => el.status_realization==="REJECTED")} bordered/>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default RealizationRequest;
