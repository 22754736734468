import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd'
import { PageTitle, PrintPdfButton, Spinner } from '../../../components'
import ApprovalLog from '../ApprovalLog'
import DetailHeader from '../DetailHeader'
import { useParams } from 'react-router-dom';
import { SentralModel } from '../../../models/SentralModel';
import { toFullDate } from '../../../utils/dateHandler'

const { Text } = Typography

const DetailRealization = () => {
    const { leaveId } = useParams()
    const [leaves, setLeaves] = useState(null)

    const fetchLeaves = (id) => {
        SentralModel.get('SubmissionLeave', {}, id).then((res) => {
            setLeaves(res.data)
        })
    }

    useEffect(() => {
        fetchLeaves(leaveId)
    }, [leaveId]);

    const printData = () => {
        window.open(process.env.REACT_APP_API_URL + '/print/leave/' + leaveId)
    }

    return (
        <Card className="content-container">
            <div>
                <PageTitle title="Leave" breadcrumbs={[['Leave', '/leave'], ['Detail']]}></PageTitle>
            </div>
            {
                (leaves) ?
                    <div>
                        <Row className="mb-3">
                            <Col span={24} >
                                <PrintPdfButton right onPrint={printData} />
                            </Col>
                        </Row>
                        <DetailHeader
                            name={leaves.employee.name}
                            date={leaves.created_at}
                            status={leaves.status_realization} />
                        <Card bordered className="mb-4">
                            <Text strong>LEAVE DETAIL</Text>
                            <Row className="mb-4 mt-1">
                                <Col span={12}>
                                    <table className="table-collapse table-default" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <th>Employee Name</th><td>{leaves.employee.name}</td>
                                            </tr>
                                            <tr>
                                                <th>Division</th><td>{leaves.employee.division.division_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Position</th><td>{leaves.employee.position.position_name}</td>
                                            </tr>
                                            <tr>
                                                <th>Leave Date</th>
                                                <td>
                                                    {
                                                        (leaves.total_days === 1) ? toFullDate(leaves.from_date) : <span>{toFullDate(leaves.from_date)} - {toFullDate(leaves.to_date)}</span>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Leave Type</th><td>{leaves.leave.description}</td>
                                            </tr>
                                            <tr>
                                                <th>Leave Information</th><td>{leaves.information.description}</td>
                                            </tr>
                                            <tr>
                                                <th>Necessity</th><td>{leaves.reason}</td>
                                            </tr>
                                            <tr>
                                                <th>Responsible Employee</th><td>{(leaves.responsible) ? leaves.responsible.name : '-'}</td>
                                            </tr>
                                            <tr>
                                                <th>Attachment</th>
                                                <td>
                                                    {
                                                        (leaves.attachment) ?
                                                            <a rel="noopener noreferrer" target='_blank' href={process.env.REACT_APP_API_FILE + '/' + leaves.attachment}>
                                                                Show Attachment
                                                            </a> : '-'
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered>
                            <ApprovalLog category="LEAVEREALIZATION"
                                approvers={leaves.approver_realization} />
                        </Card>
                    </div> : <Spinner />
            }

        </Card>
    );
}

export default DetailRealization;
