import { useState, useEffect } from 'react'
import { Button, Card, Space, Tag } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { DataTable, PageTitle, Spinner } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { formatDateTime2 } from '../../../utils/dateFormat'
import { toIdr } from '../../../utils/currencyHandler'

const Comission = () => {
    const [loaded, setLoaded] = useState(false)
    const [comissions, setComissions] = useState([])

    const fetchComission = () => {
        setLoaded(false)
        SentralModel.list('SubmissionCommission').then(res => {
            let data = []
            res.data.forEach((el) => {
                if (el.status === 'WAITING' || el.status === 'APPROVED') {
                    data.push(el)
                }
            })
            setComissions(data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchComission()

    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '3%'
        },
        {
            title: 'Comission ID',
            dataIndex: 'sub_commission_id',
            key: 'comission_id'
        },
        {
            title: 'Employee',
            render: (row) => (
                row?.employee?.name
            ),
            key: 'employee'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Items',
            key: 'items',
            render: (row) => (
                <table className='table-sub'>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Invoice Number</th>
                            <th>Quantity</th>
                            <th>Amount Invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            row?.items.map((el, key) => (
                                <tr>
                                    <td>{el.category}</td>
                                    <td>{el.sub_category}</td>
                                    <td>{el.no_invoice}</td>
                                    <td>{el.quantity}</td>
                                    <td>{toIdr(el.amount)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )
        },
        {
            title: 'Total Amount',
            render: (row) => (
                toIdr(row?.total_amount)
            ),
            key: 'total_amount'
        },
        {
            title: 'Request Date',
            // className: 'text-top',
            render: (row) => (
                formatDateTime2(row.created_at)
            )
        },
        {
            title: 'Status',
            render: (row) => (
                <Tag color={
                    row?.status === "WAITING" ? "blue" : row?.status === "REJECTED" ? "red" : "green"
                }>
                    {row?.status}
                </Tag>
            ),
            key: 'status',
            align: 'center'
        }
    ]

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/comission')
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Comission Report" breadcrumbs={[['Comission Report']]}></PageTitle>
                <div className="text-right mb-2">
                    <Space>
                        <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    </Space>
                </div>
                {
                    (loaded) ?
                        <DataTable pagination={false} size="small" columns={columns} dataSource={comissions} bordered />
                        : <Spinner />
                }
            </Card>
        </div>
    )
}

export default Comission