import { useState, useEffect } from 'react'
import { Card, DatePicker, Form, Input, message, Select, Space, Tag, Typography } from 'antd'
import { AddButton, DataTable, DeleteButton, EditButton, FormModal, PageTitle } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import currentUser from '../../../helpers/authData'
import { useParams } from 'react-router-dom'
import moment from 'moment'

const { Text } = Typography

const MilestoneDetail = () => {
    const { actionPlanId, milestoneId } = useParams()
    const [saving, setSaving] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [milestoneTask, setMilestoneTask] = useState([])
    const [employee, setEmployee] = useState([])
    const [defaultValues, setDefaultValues] = useState({})

    const fetchMilestoneTask = () => {
        SentralModel.action('MilestoneTask', 'getAll', 0, milestoneId).then((res) => {
            setMilestoneTask(res.data)
            setLoaded(true)
        })
    }

    const fetchEmployee = () => {
        let payload = { division_id: currentUser.division_id }
        SentralModel.action('Employee', 'getEmployeeForAssign', payload, 0).then((res) => {
            const v = res.data
            let arr = []

            v.forEach((el) => {
                arr.push({ label: el.name, value: el.employee_code })
            })

            setEmployee(arr)
        })
    }

    useEffect(() => {
        fetchMilestoneTask()
        fetchEmployee()

        // eslint-disable-next-line
    }, [])

    const add = () => {
        setShowModal(true)
        setDefaultValues({
            milestone_task_id: 0,
            title: '',
            description: '',
            due_date: '',
        })
        setModalTitle('Add Task')
    }

    const edit = (v) => {
        let assign = []

        v?.assign?.forEach((el) => {
            assign.push(el.assign_to)
        })

        setShowModal(true)
        setDefaultValues({
            milestone_task_id: v.milestone_task_id,
            title: v.title,
            description: v.description,
            assign_to: assign,
            due_date: moment(v.due_date),
        })
        setModalTitle('Edit Task')
    }

    const onFinish = (v) => {
        setSaving(true)
        let payload = {
            milestone_id: milestoneId,
            title: v.title,
            description: v.description,
            assign_to: v.assign_to,
            due_date: v.due_date,
            status: 'In Progress'
        }

        if (v.milestone_task_id === 0) {
            SentralModel.action('MilestoneTask', 'saveMilestoneTask', payload, 0)
                .then((res) => {
                    message.success('Success')
                    fetchMilestoneTask()
                    setShowModal(false)
                    setSaving(false)
                }).catch((err) => {
                    message.warning("Failed")
                    setShowModal(false)
                    setSaving(false)
                })
        } else {
            SentralModel.action('MilestoneTask', 'updateMilestone', payload, v.milestone_task_id)
                .then((res) => {
                    message.success('Success')
                    fetchMilestoneTask()
                    setShowModal(false)
                    setSaving(false)
                }).catch((err) => {
                    message.warning("Failed")
                    setShowModal(false)
                    setSaving(false)
                })
        }
    }

    const onDeleted = (v) => {
        SentralModel.deleteById("MilestoneTask", v).then((res) => {
            message.success("Success Deleted Data")
            fetchMilestoneTask()
        }).catch((err) => {
            message.warning("Failed Delete Data")
        })
    }

    const columns = [
        {
            title: "No",
            dataIndex: "no",
            key: "no",
            width: "5%",
            align: "center",
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            align: "center",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            align: "center",
        },
        {
            title: "Assign To",
            render: (row) => (
                row.assign.map((el, key) => (
                    <Tag key={key}>{el?.employee?.name}</Tag>
                ))
            ),
            key: "assign_to",
            align: "center",
        },
        {
            title: "Due Date",
            dataIndex: "due_date",
            key: "due_date",
            align: "center",
            render: (row) => moment(row).format("DD MMMM YYYY"),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (row) => (
                row === 0 ? <Tag color="#108ee9">In Progress</Tag> : <Tag color="#87d068">Complete</Tag>
            ),
        },
        {
            title: "Action",
            width: 120,
            render: (row) => (
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => edit(row)} />
                        <DeleteButton onConfirm={() => onDeleted(row.milestone_task_id)} />
                    </Space>
                </div>
            ),
            align: "center",
        },
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Milestone" breadcrumbs={[['Action Plan', '/action-plan'], ['Detail', `/action-plan/${actionPlanId}`], ['Milestone']]}></PageTitle>
            <Card title={
                <div className="full-width">
                    <Text strong style={{ float: 'left' }}>Milestone Task</Text>
                    <AddButton right onAdd={add} />
                </div>
            }>
                {
                    loaded ?
                        <DataTable bordered dataSource={milestoneTask} columns={columns} />
                        : null
                }
            </Card>

            {showModal ? (
                <FormModal
                    loading={saving}
                    defaultValues={defaultValues}
                    title={modalTitle}
                    submitForm={(v) => onFinish(v)}
                    width={700}
                    showModal={showModal}
                    onCloseModal={() => setShowModal(false)}
                >
                    <Form.Item name="milestone_task_id" hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Assign To"
                        name="assign_to"
                        required
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            mode="multiple"
                            placeholder="Assign To"
                            options={employee}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Title"
                        name="title"
                        required
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        required
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea rows={3} placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                        label="Due Date"
                        name="due_date"
                        required
                        rules={[{ required: true }]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </FormModal>
            ) : null}
        </Card>
    )
}

export default MilestoneDetail