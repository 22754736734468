import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Form, Input, Typography, message} from 'antd'
import { Spinner, SubmitButton, Uploader} from '../../../../components'
import {useParams} from 'react-router-dom';
import { SentralModel } from '../../../../models/SentralModel';
import {toIdr, extractNumber} from '../../../../utils/currencyHandler'
import {toFullDate} from '../../../../utils/dateHandler'

const {Text} = Typography

const RealizationForm = (props) => {
    const {entertainmentId} = useParams()
    const [form] = Form.useForm()
    const [entertainment, setEntertainment] = useState(null)
    const [totalAmount, setTotalAmount] = useState(null)
    const [attachmentFile, setAttachmentFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [giftItem, setGiftItem] = useState([])

    const fetchEntertainment = (id, giftItem) => {
       SentralModel.get('SubmissionEntertainment', {}, id).then((res) => {
            setEntertainment(res.data)
            let x = giftItem
            res.data?.gifts?.forEach((el, key) => {
                x.push({
                    sub_entertainment_gift_id: el.sub_entertainment_gift_id,
                    date: el.date,
                    gift: el.gift,
                    place: el.place,
                    address: el.address,
                    amount_realization: el.amount_realization,
                })
            })
            return setGiftItem(x);
       })
    }

    useEffect(() => {
        fetchEntertainment(entertainmentId, giftItem)
    }, [entertainmentId, giftItem]);

    const changePrice = (v, key) => {
        let x = giftItem
        if (v.target.value) {
            let newPrice = extractNumber(v.target.value)
            x[key].amount_realization = newPrice
            form.setFieldsValue({ ['gift_price_' + key]: toIdr(newPrice) })
            setGiftItem(x)
            changeTotalPrice()
        }
    }

    const changeTotalPrice = () => {
        let total = 0;
        giftItem.forEach(el => {
            total += el.amount_realization
        });
        setTotalAmount(total)
    }

    const submitEntertainment = (v) => {
        if (!attachmentFile) {
            message.error('Attachment is required', 2)
        } else {
            let data = {
                entertainment_id: entertainmentId,
                total_amount_realization: totalAmount,
                attachment: attachmentFile,
                gift_realization: giftItem,
            }
            props.onSubmit(data)
        }
    }

    const fileUploaded = (v) => {
        setAttachmentFile(v.message)
    }

    return (
        (entertainment) ? 
            <div>
                <Card bordered className="mb-4">
                    <Text strong>ENTERTAINMENT DETAIL</Text>
                    <Row className="mb-4 mt-1">
                        <Col span={12}>
                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                <tbody>
                                    <tr>
                                        <th>Employee Name</th><td>{entertainment.employee.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Division</th><td>{entertainment.employee.division.division_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Position</th><td>{entertainment.employee.position.position_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Title</th><td>{entertainment.title}</td>
                                    </tr>
                                    <tr>
                                        <th>Description</th><td>{entertainment.description}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Amount</th><td>{toIdr(entertainment.total_amount)}</td>
                                    </tr>
                                    <tr>
                                        <th>Attachment</th>
                                        <td>
                                            <Uploader folder="submission-entertainment" onUploaded={(v) => fileUploaded(v)} isUploading={(v) => setUploading(v)} view={true} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card>
                <Card bordered className="mb-4">
                    <Text strong>PLAN</Text>
                    <Row className="mb-4 mt-1">
                        <Col span={24}>
                            <table className="table-collapse table-default" style={{width:'100%'}}>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Date</th>
                                        <th>Place</th>
                                        <th>Address</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        entertainment?.gifts?.map((el, key) => (
                                            <tr key={key}>
                                                <td width="20%">{el.gift}</td>
                                                <td width="20%">{toFullDate(el.date)}</td>
                                                <td width="20%">{el.place}</td>
                                                <td width="20%">{el.address}</td>
                                                <td width="20%">{toIdr(el.amount)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Card>

                <Card bordered className="mb-4">
                    <Text strong>REALIZATION</Text>
                    <Form form={form} size="middle" layout="vertical" onFinish={submitEntertainment}>
                        <Row className="mb-4 mt-1">
                            <Col span={24}>
                                <table className="table-collapse table-default" style={{width:'100%'}}>
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Date</th>
                                            <th>Place</th>
                                            <th>Address</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            giftItem.map((el, key) => (
                                                <tr key={'ent_item'+key}>
                                                    <td width="20%" style={{ verticalAlign: 'top'}}>
                                                        {el.gift}
                                                    </td>
                                                    <td width="20%" style={{ verticalAlign: 'top'}}>
                                                        {toFullDate(el.date)}
                                                    </td>
                                                    <td width="20%" style={{ verticalAlign: 'top'}}>
                                                        {el.place}
                                                    </td>
                                                    <td width="20%" style={{ verticalAlign: 'top'}}>
                                                        {el.address}
                                                    </td>
                                                    <td width="20%">
                                                        <Form.Item name={`gift_price_${key}`} value={el.amount_realization} rules={[{required:true}]}>
                                                            <Input placeholder="Price" onChange={(v) => changePrice(v, key)}/>
                                                        </Form.Item>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                        <tr style={{borderTop: '1px solid #ededed'}}>
                                            <td className="text-right" colSpan={4}>Total : </td>
                                            <td>{toIdr(totalAmount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="text-right">
                                <SubmitButton loading={props.saving || uploading}/>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </div> : <Spinner/>
    );
}

export default RealizationForm;
