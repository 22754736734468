import React, { useEffect, useState } from 'react';
import { Card, Row, Col, DatePicker, Space, Button, Tooltip, message } from 'antd';
import { Bar } from 'react-chartjs-2'
import DashboardItem from './DashboardItem'
import { BasicModal, DataTable, PageTitle, Spinner, SyncButton } from '../../../components';
import { SentralModel } from '../../../models/SentralModel'
import { ZoomInOutlined, SearchOutlined } from '@ant-design/icons'
import moment from 'moment'

const { RangePicker } = DatePicker

const Attendance = () => {
    const [loaded, setLoaded] = useState(true);
    const [loadedBarData, setLoadedBarData] = useState(false);
    const [attendances, setAttendances] = useState({});
    const [barData, setBarData] = useState(null);
    const [selectedDate, setSelectedDate] = useState([null,null]);
    const [detail, setDetail] = useState([]);
    const [loadedDetail, setLoadedDetail] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [detailTitle, setDetailTitle] = useState(null);
    const [loadingAttendance, setLoadingAttendance] = useState(false);
    const [payrollPeriod, setPayrollPeriod] = useState(null);
    const [employeeLate, setEmployeeLate] = useState([]);
    const [loadedLate, setLoadedLate] = useState(false);

    const fetchAttendance = () => {
        setLoaded(false);
        SentralModel.action('Dashboard', 'attendanceHR', {}, 0).then((res) => {
            setAttendances(res.data);
            setLoaded(true);
        })
    }

    const fetchAttendanceChart = (date) => {
        setLoadedBarData(false);
        SentralModel.action('Dashboard', 'attendanceChartHR', {from:date.from, to:date.to}, 0).then((res) => {
            setBarData(res.data);
            setLoadedBarData(true);
        })
    }

    const fetchEmployeeLate = () => {
        setLoadedLate(false);
        SentralModel.action('Dashboard', 'employeeLate', {}, 0).then((res) => {
            setPayrollPeriod(`${res.data.period.period} ${res.data.period.year}`);
            setEmployeeLate(res.data.attendance);
            setLoadedLate(true);
        })
    }

    useEffect(() => {
        fetchAttendance();
        fetchAttendanceChart({from: moment().startOf('month').format('YYYY-MM-DD'), to: moment().endOf('month').format('YYYY-MM-DD')});
        fetchEmployeeLate();
        setSelectedDate([moment().startOf('month'), moment().endOf('month')]);
    }, []);

    const data = {
        labels: (barData) ? barData.days : [],
        datasets: [
        {
            label: 'Kehadiran',
            data: (barData) ? barData.presences : [],
            backgroundColor: 'rgba(0, 255, 0, 0.3)',
            borderColor: '#2ecc71',
            borderWidth: 1
        },{
            label: 'Alpha',
            data: (barData) ? barData.alphas : [],
            backgroundColor:'rgba(255, 99, 132, 0.3)',
            borderColor:'#ff7675',
            borderWidth: 1
        },{
            label: 'Leave',
            data: (barData) ? barData.leaves : [],
            backgroundColor:'rgba(255, 255, 0, 0.3)',
            borderColor:'#f1c40f',
            borderWidth: 1
        }]
    }

    const filterPeriodAttendance = () => {
        fetchAttendanceChart({from: moment(selectedDate[0]).format('YYYY-MM-DD'), to: moment(selectedDate[1]).format('YYYY-MM-DD')});
    }

    const getDetail = (type, date) => {
        setDetailModal(true);
        setDetailTitle(type);

        setLoadedDetail(false);
        SentralModel.action('Dashboard', 'employeeDetail', {type: type, date: date}, 0).then((res) => {
            setDetail(res.data);
            setLoadedDetail(true);
        })
    }

    const syncAttendance = () => {
        setLoadingAttendance(true)
        SentralModel.action('Dashboard', 'syncTodayAttendance', {}, 0).then((res) => {
            fetchAttendance();
            fetchAttendanceChart({from: moment(selectedDate[0]).format('YYYY-MM-DD'), to: moment(selectedDate[1]).format('YYYY-MM-DD')});
            setLoadingAttendance(false);
            message.destroy();
            message.success('Sync success', 1);
        }).catch(() => {
            setLoadingAttendance(false);
            message.destroy();
            message.error('Sync failed, try again !!', 2);
        })
    }

    const counterStyle = {
        fontSize:'40px',
        color:'#000',
        fontWeight:'bold'
    }

    const detailColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '55%'
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            width: '40%'
        }
    ];

    const lateColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '5%'
        },
        {
            title: 'Nama Karyawan',
            dataIndex: 'name',
            key: 'name',
            width: '55%'
        },
        {
            title: 'Dibawah 30 Menit',
            width: '20%',
            render: (row) =>(
                <div className="text-center">
                    {row.before30}
                </div>
            )
        },
        {
            title: 'Diatas 30 Menit',
            width: '20%',
            render: (row) =>(
                <div className="text-center">
                    {row.after30}
                </div>
            )
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <Row justify="space-between" align="middle">
                    <Col>
                        <PageTitle title="Attendance" breadcrumbs={[['Attendance']]} />
                    </Col>
                    <Col>
                        <SyncButton title='Sync Today Attendance' onClick={syncAttendance} loading={loadingAttendance}/>
                    </Col>
                </Row>
                {
                    loaded ?
                        <div>
                            <Row>
                                <Col span={24} className="p-1">
                                    <Row className="mb-3" gutter={[16, 16]}>
                                        <Col className="gutter-row" lg={{span: 8}} sm={{span: 8}} xs={{span: 8}}>
                                            <DashboardItem title="Kehadiran Hari Ini" height={50}>
                                                <Row justify="space-between" align="middle">
                                                    <Col>
                                                        <span style={counterStyle}>{attendances.today_attendance}</span>
                                                        <span style={{fontSize:'15px'}}> Karyawan</span>
                                                    </Col>
                                                    <Col>
                                                        <Tooltip title="Detail employee attendance">
                                                            <Button shape="circle" icon={<SearchOutlined style={{color:'#808080'}} />} 
                                                                onClick={() => getDetail('Attendance', moment().format('YYYY-MM-DD'))} 
                                                                style={{ backgroundColor: 'rgba(0, 255, 0, 0.3)', borderColor: '#2ecc71', borderWidth: 1 }} 
                                                            />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </DashboardItem>
                                        </Col>
                                        <Col className="gutter-row" lg={{span: 8}} sm={{span: 8}} xs={{span: 8}}>
                                            <DashboardItem title="Alpha Hari Ini" height={50}>
                                                <Row justify="space-between" align="middle">
                                                    <Col>
                                                        <span style={counterStyle}>{attendances.today_alpha}</span>
                                                        <span style={{fontSize:'15px'}}> Karyawan</span>
                                                    </Col>
                                                    <Col>
                                                        <Tooltip title="Detail employee alpha">
                                                            <Button shape="circle" icon={<SearchOutlined style={{color:'#808080'}} />} 
                                                                onClick={() => getDetail('Alpha', moment().format('YYYY-MM-DD'))}
                                                                style={{ backgroundColor: 'rgba(255, 99, 132, 0.3)', borderColor: '#ff7675', borderWidth: 1 }} 
                                                            />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </DashboardItem>
                                        </Col>
                                        <Col className="gutter-row" lg={{span: 8}} sm={{span: 8}} xs={{span: 8}}>
                                            <DashboardItem title="Cuti Hari Ini" height={50}>
                                                <Row justify="space-between" align="middle">
                                                    <Col>
                                                        <span style={counterStyle}>{attendances.today_leave}</span>
                                                        <span style={{fontSize:'15px'}}> Karyawan</span>
                                                    </Col>
                                                    <Col align="middle">
                                                        <Tooltip title="Detail employee leave">
                                                            <Button shape="circle" icon={<SearchOutlined style={{color:'#808080'}} />} 
                                                                onClick={() => getDetail('Leave', moment().format('YYYY-MM-DD'))}
                                                                style={{ backgroundColor: 'rgba(255, 255, 0, 0.3)', borderColor: '#f1c40f', borderWidth: 1 }}  
                                                            />
                                                        </Tooltip>
                                                    </Col>
                                                </Row>
                                            </DashboardItem>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {/* Chart */}
                            <Row className="mb-3">
                                <Col span={24} className="p-1">
                                    <DashboardItem title={
                                        <div>
                                            Grafik Kehadiran
                                            <Space style={{float:'right'}}>
                                                <RangePicker onChange={(v) =>setSelectedDate(v)} style={{width:'300px', float:'right'}} defaultValue={selectedDate}/>
                                                <Button icon={<ZoomInOutlined />} loading={!loadedBarData} onClick={() => filterPeriodAttendance()} type="primary">
                                                    Show
                                                </Button>
                                            </Space>
                                        </div>
                                    } height={400}>
                                    {
                                        (loadedBarData) ? 
                                            <Bar
                                                data={data}
                                                width={100}
                                                height={320}
                                                options={{ maintainAspectRatio: false }}
                                            />
                                        : <Spinner/>
                                    }
                                    </DashboardItem>
                                </Col>
                            </Row>

                            {/* Employee late */}
                            <Row className="mb-3">
                                <Col span={24} className="p-1">
                                    <DashboardItem title={<div>Keterlambatan Karyawan ({payrollPeriod})</div>}>
                                    {
                                        (loadedLate) ? 
                                            <DataTable bordered dataSource={employeeLate} columns={lateColumn} size="small"/>
                                        : <Spinner/>
                                    }
                                    </DashboardItem>
                                </Col>
                            </Row>

                        </div>
                    : <Spinner />
                }
            </Card>

            <BasicModal width={1000} onCloseModal={() => setDetailModal(false)} showModal={detailModal} onCancel={() => setDetailModal(false)} title={detailTitle}>
                {
                    loadedDetail ?
                        <DataTable bordered dataSource={detail} columns={detailColumn} size="small"/>
                    : <Spinner />
                }
            </BasicModal>
        </div>
    );
}

export default Attendance;
