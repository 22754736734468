import { Button, Form, Input, DatePicker, Select, Row, Col } from 'antd';
import { Uploader } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { changeUploading, fetchListTicket, fetchTicketAssign, fileUploaded } from '../../../../redux/slices/Dashboard/karajoTicketSlice';
import { changeEmployee, saveSubmitGeneralTicket } from '../../../../redux/slices/Dashboard/karajoTicketSlice';

const { Option } = Select;

const FormatTicketGeneral = (props) => {
    const dispatch = useDispatch()
    const employees = useSelector((state) => state.karajoTicket.employees)
    const divisionId = useSelector((state) => state.karajoTicket.divisionId)
    const disabled = useSelector((state) => state.karajoTicket.disabled)
    const attachmentFile = useSelector((state) => state.karajoTicket.attachmentFile)

    const saveSubmit = (v) => {
        let payload = {
            ...v,
            attachment: attachmentFile
        }

        dispatch(saveSubmitGeneralTicket(payload)).then(() => {
            dispatch(fetchListTicket())
            dispatch(fetchTicketAssign())
        })
    }

    return (
        <Form layout='vertical' onFinish={(v) => saveSubmit(v)}>
            <Form.Item label="Assign To" name="employee_code" rules={[{ required: true }]}>
                <Select showSearch allowClear placeholder="Select employee" optionFilterProp="children" style={{ width: "100%" }} onChange={(v) => dispatch(changeEmployee(v))} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {
                        divisionId === 0 ?
                            employees.map((el, key) => (
                                <Option key={key} value={el.employee_code}>{el.name}</Option>
                            ))
                            :
                            employees.filter((el) => el.division_id === divisionId).map((el, key) => (
                                <Option key={key} value={el.employee_code}>{el.name}</Option>
                            ))
                    }
                </Select>
            </Form.Item>
            <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                <Input.TextArea rows={4} placeholder="Description" disabled={disabled} />
            </Form.Item>
            <Form.Item label="Deadline" name="due_date" rules={[{ required: true }]}>
                <DatePicker format="DD MMMM YYYY" style={{ width: '100%' }} disabled={disabled} />
            </Form.Item>
            <Form.Item label="Upload Attachment">
                <Uploader folder="attachment-daily-activity" onUploaded={(v) => dispatch(fileUploaded(v))} isUploading={(v) => dispatch(changeUploading(v))} view={true} />
            </Form.Item>
            <Row justify='end'>
                <Col>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>Save</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default FormatTicketGeneral