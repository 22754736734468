import React, {useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../components'
import RequestForm from './RequestForm'
import { SentralModel } from '../../../models/SentralModel';
import {useHistory} from 'react-router-dom'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState(false)

    const submitResign = (v)=>{
        setSaving(true)
        SentralModel.action('Submission', 'saveResign', v, 0).then(()=>{
            message.destroy()
            message.success('Resign submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/resign');
            }, 1000);
        }).catch((err) => {
            setSaving(false)
        })
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="Resign Submission" breadcrumbs={[['Resign','/resign'],['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitResign(v)} saving={saving}/>
        </Card>
    );
}

export default Create;
