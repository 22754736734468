import React, { useEffect, useState } from 'react';
import { Card, Typography, message, Space, Tag } from 'antd'
import { NewButton, EditButton, DeleteButton, PageTitle, Spinner, DataTable, ShowButton } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useHistory } from 'react-router-dom';

const { Text } = Typography
const Index = () => {
    const history = useHistory()
    const [referral, setReferrals] = useState([])
    const [loaded, setLoaded] = useState(true)

    const fetchReferrals = () => {
        SentralModel.action('Referral', 'getReferral', null, null).then(res => {
            setReferrals(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchReferrals()
        // checkApprovalSchema()
    }, []);

    const deleteReferral = (id) => {
        message.loading('deleting referral')
        SentralModel.deleteById('Referral', id).then((res) => {
            message.destroy()
            message.success('Referral deleted', 2)
            fetchReferrals()
        })
    }

    const dataSource = [
        ...referral.map(el => {
            return {
                id: el.referral_id,
                company_name: el.company_name,
                PIC_name: el.PIC_name,
                PIC_phone: el.PIC_phone,
                PIC_email: el.PIC_email,
                remarks: el.remarks,
                employee: el?.employee?.name,
                status: el.status,
            }
        })
    ]

    const columns = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Company Name',
        dataIndex: 'company_name',
        key: 'company_name',
    },
    {
        title: 'PIC Name',
        dataIndex: 'PIC_name',
        key: 'PIC_name',
    },
    {
        title: 'PIC Phone',
        dataIndex: 'PIC_phone',
        key: 'PIC_phone',
    },
    {
        title: 'PIC Email',
        dataIndex: 'PIC_email',
        key: 'PIC_email',
    },
    {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
    },
    {
        title: 'Employee',
        dataIndex: 'employee',
        key: 'employee'
    },
    {
        title: 'Status',
        key: 'status',
        render: (row) => (
            <Tag color={row.status === "GET" ? "green" : "red"}>{row.status}</Tag>
        ),
        align: 'center'
    },
    {
        title: 'Action',
        width: 120,
        render: (row) => (
            <div className="text-center">
                <Space>
                    {
                        row.status === "GET" ?
                            <ShowButton onShow={() => history.push(`/referral/${row.id}`)} />
                            : null
                    }
                    <EditButton onEdit={() => history.push(`/referral/create/${row.id}`)} />
                    <DeleteButton onConfirm={() => deleteReferral(row.id)} />
                </Space>
            </div>
        )
    },
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Referrals" breadcrumbs={[['Referral']]}></PageTitle>

                <Card title={
                    <div className="full-width">
                        <Text strong style={{ float: 'left' }}>Referral List</Text>
                        <NewButton right to="/referral/create/0" title="New Referral" />
                    </div>
                }>

                    {
                        (loaded) ?
                            <DataTable size='small' bordered dataSource={dataSource} columns={columns} />
                            :
                            <Spinner />
                    }
                </Card>
            </Card>
        </div>
    );
}

export default Index;
