import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DataTable, EditButton, FormModal, ShowButton } from '../../../../components';
import { toFullDate } from '../../../../utils/dateHandler';
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Spin, Tag, message } from 'antd';
import { editProgressTask, fetchEmployee, fetchTaskAssign } from '../../../../redux/slices/Dashboard/myTaskSlice';
import moment from 'moment';
import ModalEditProgressTask from './Modal/Task/ModalEditTaskProgress';
import dayjs from 'dayjs';
import { CloseCircleTwoTone, CheckCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import { SentralModel } from '../../../../models/SentralModel';

const AssignTask = (props) => {
    const { datas = [] } = props
    const dispatch = useDispatch()
    const [extendData, setExtendData] = useState([])
    const [saving, setSaving] = useState(false)
    const [extendModal, setExtendModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [empAssign, setEmpAssign] = useState([])
    const loadedAssign = useSelector((state) => state.myTask.loadedAssign)
    const editProgressTaskModal = useSelector((state) => state.myTask.editProgressTaskModal)
    const employees = useSelector((state) => state.myTask.employees)

    useEffect(() => {
        dispatch(fetchTaskAssign())
        dispatch(fetchEmployee())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickRequest = (v) => {
        setSaving(true)
        let payload = {
            all_app_task_id: v.data.all_app_task_id,
            extend_task_id: v.data.extend_task_id,
            response: v.response
        }

        SentralModel.action("ExtendedTask", "updateExtend", payload, null).then((res) => {
            message.success("Success Update Request")
            dispatch(fetchTaskAssign())
            setSaving(false)
        }).catch((err) => {
            message.warning("Failed Update Request")
            setSaving(false)
        })
    }

    const onShowExtendModal = (v) => {
        setExtendModal(true)
        setExtendData(v)
    }

    const onShowEditTask = (v) => {
        let arr = []
        v.multiple_task_to.forEach((el) => {
            arr.push(el.employee_code)
        })
        setEditModal(true)
        setDefaultValues({
            id: v.id,
            description: v.description,
            due_date: moment(v.due_date),
        })
        setEmpAssign(arr)
    }

    const submitData = (v) => {
        let payload = {
            description: v.description,
            due_date: moment(v.due_date),
            assign_to: empAssign
        }
        if (empAssign.length === 0) {
            message.warning("Assign To cannot be empty")
        } else {
            SentralModel.action('DailyActivity', 'editDailyTask', payload, v.id).then((res) => {
                message.success('Success Edit Task')
                dispatch(fetchTaskAssign())
                setEditModal(false)
            }).catch((err) => {
                message.success('Failed Edit Task')
            })
        }

    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        }, {
            title: 'Task To',
            render: (row) => (
                row?.multiple_task_to.map((el, key) => (
                    <Tag key={key} color='blue'>
                        {el.task_to?.name}
                    </Tag>
                ))
            ),
            align: 'center',
            key: 'tab'
        },
        {
            title: 'Task Origin',
            dataIndex: 'data_from',
            key: 'data_from',
        },
        {
            title: 'Description',
            render: (row) => (
                row.description !== null ?
                    row.description.length > 150 ? `${row?.description.substring(0, 150)}...` : row?.description
                    : "-"
            ),
            key: 'description',
        },
        {
            title: 'Deadline',
            key: 'deadline',
            render: (row) => (
                toFullDate(row?.due_date)
            )
        },
        {
            title: 'Finished Date',
            render: (row) => (
                row?.finished_date === null || row?.finished_date === "0000-00-00 00:00:00" ? '-' : moment(row?.finished_date).format('DD MMMM YYYY')
            ),
            key: 'finished_date',
            align: 'center'
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                row?.extend.length > 0 ?
                    <>
                        <Row justify='center'>
                            <Col>
                                {row?.status}
                            </Col>
                        </Row>
                        <Row justify='center'>
                            <Col>
                                <Button type='link' onClick={() => onShowExtendModal(row.extend)}>Extend</Button>
                            </Col>
                        </Row>
                    </> : row.status
            ),
            align: 'center',
        }, {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    {
                        row?.status === "In Progress" ?
                            <EditButton onEdit={() => onShowEditTask(row)} />
                            : null
                    }
                    <ShowButton onShow={() => dispatch(editProgressTask({ data: row, stat: 'show', owner: true }))} />
                </Space>
            ),
            key: 'action'
        }
    ];

    const columnsExtend = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'no',
        },
        {
            title: 'Start Date',
            render: (row) => (
                dayjs(row?.start_date).format("DD/MM/YYYY")
            ),
            key: 'start_date',
        },
        {
            title: 'Due Date',
            render: (row) => (
                dayjs(row?.end_date).format("DD/MM/YYYY")
            ),
            key: 'end_date',
        },
        {
            title: 'Extended Date',
            render: (row) => (
                dayjs(row?.extended_date).format("DD/MM/YYYY")
            ),
            key: 'extended_date',
        },
        {
            title: 'Reason Extend',
            dataIndex: 'reason_extending',
            key: 'reason_extend',
        },
        {
            title: 'Status',
            render: (row) => (
                row?.status === 1 ? 'APPROVED' : row?.status === 2 ? 'REJECTED' : 'WAITING'
            ),
            key: 'status',
            align: 'center'
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    <Button
                        type='link'
                        disabled={row?.status === 0 || saving ? false : true}
                        onClick={() => onClickRequest({ response: 'reject', data: row })}
                        icon={
                            saving ?
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, }} spin />} /> :
                                <CloseCircleTwoTone twoToneColor={row?.status === 0 ? 'red' : '#bfbfbf'} style={{ fontSize: 20 }} />
                        }
                    />
                    <Button
                        type='link'
                        disabled={row?.status === 0 || saving ? false : true}
                        onClick={() => onClickRequest({ response: 'approve', data: row })}
                        icon={
                            saving ?
                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, }} spin />} /> :
                                <CheckCircleTwoTone twoToneColor={row?.status === 0 ? '#52c41a' : '#bfbfbf'} style={{ fontSize: 20 }} />
                        }
                    />
                </Space>
            ),
            key: 'action'
        }
    ];

    return (
        <>
            <div>
                {
                    loadedAssign ?
                        <DataTable columns={columns} dataSource={datas} bordered size="small" />
                        : null
                }
            </div>

            {
                editProgressTaskModal ?
                    <ModalEditProgressTask />
                    : null
            }

            {
                editModal ?
                    <FormModal title="Edit Task" showModal={editModal} onCloseModal={() => setEditModal(false)} defaultValues={defaultValues} width={600} submitForm={submitData}>
                        <Form.Item name="id" hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name="assign_to" label="Assign To">
                            <Select
                                showSearch
                                mode="multiple"
                                defaultValue={empAssign}
                                options={employees}
                                onChange={(v) => setEmpAssign(v)}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                        <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                            <Input.TextArea rows={3} />
                        </Form.Item>
                        <Form.Item name="due_date" label="Deadline" rules={[{ required: true }]}>
                            <DatePicker />
                        </Form.Item>
                    </FormModal>
                    : null
            }

            {
                extendModal ?
                    <Modal width={700} title="Extend Date" visible={extendModal} onCancel={() => setExtendModal(false)} footer={
                        <Button onClick={() => setExtendModal(false)}></Button>
                    }>
                        <DataTable columns={columnsExtend} dataSource={extendData} bordered size="small" search={false} pagination={false} />
                    </Modal>
                    : null
            }
        </>
    )
}

export default AssignTask