import { useState, useEffect } from 'react';
import { Card, Select, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'

const { Option } = Select

const EmployeeDataLog = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [attendanceData, setAttendanceData] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [employees, setEmployees] = useState([])

    const fetchEmployees = () => {
        SentralModel.list('Employee', { fields: 'employee_code,name,division_id', orderBy: 'name', order: 'ASC', filter: [['is_active', '=', '1']] }, 0).then((res) => {
            setEmployees(res.data);
        })
    }

    useEffect(() => {
        fetchEmployees()
        fetchReport({ employee_code: selectedEmployee })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEmployee]);

    const filterData = (v) => {
        let ft = {
            employee_code: v.employee_code,
        }

        fetchReport(ft)
        setShowFilter(false)
    }

    const fetchReport = (ft) => {
        setLoaded(false)
        SentralModel.action('Profile', 'getLogData', ft, 0).then((res) => {
            setAttendanceData(res.data)
            setLoaded(true)
        })
    }


    const empDataLogColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Employee Name',
            key: 'name',
            render: (row) => (
                row?.employee?.name ?? "-"
            )
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Updated At',
            key: 'updated_at',
            render: (row) => (
                moment(row?.created_at).format("DD MMMM YYYY")
            )
        }
    ];

    return (
        <Card className="content-container">
            <PageTitle title="Employee Data Log Report" breadcrumbs={[['Employee Data Log Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable size="small" columns={empDataLogColumn} dataSource={attendanceData} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}>
                <Form form={form} layout="vertical" onFinish={filterData}>
                    <Form.Item name="employee_code" label="Employee">
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            onChange={(v) => setSelectedEmployee(v)}
                            placeholder="Select Employee"
                            allowClear
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            <Option value={null}>All Employee</Option>
                            {
                                employees.map((el, key) => (
                                    <Option
                                        key={key}
                                        value={el.employee_code}
                                    >{el.name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default EmployeeDataLog;
