import React, { useState, useEffect } from 'react';
import { Card, Select, DatePicker, Form, Button, Drawer, Space } from 'antd';
import { FilterOutlined, FileExcelOutlined } from '@ant-design/icons'
import { PageTitle, DataTable, Spinner } from '../../../components';
import { SentralModel } from '../../../models/SentralModel';
import moment from 'moment'

const { Option } = Select

const ScoreCard = () => {
    const [form] = Form.useForm()
    const [loaded, setLoaded] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [scoreCardData, setScoreCardData] = useState([])
    const [periods, setPeriods] = useState([])
    const [periodId, setPeriodId] = useState(0)
    const [year, setYear] = useState("")

    const fetchPeriods = (year) => {
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'desc' }).then(res => {
            setPeriods(res.data)
        })
    }

    const fetchScoreCard = () => {
        let payload = {
            period_id: periodId,
            year: year
        }

        SentralModel.action("Report", "scoreCard", payload, null).then((res) => {
            setScoreCardData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        setLoaded(true)

    }, []);

    const onChangeYear = (v) => {
        let year = moment(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
        setYear(year)
    }

    const changePeriod = (v) => {
        setPeriodId(v)
    }

    const filterData = () => {
        fetchScoreCard()
        setShowFilter(false)
    }


    const attendanceColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
        },
        {
            title: 'Employee Name',
            render: (row) => (
                row.employee.name
            ),
            key: 'name',
        },
        {
            title: 'Percentage Attendance',
            render: (row) => (
                `${row?.percentage_attendance}%`
            ),
            key: 'percentage_attendance',
            align: 'center'
        },
        {
            title: 'Percentage Daily Activity ',
            render: (row) => (
                `${row?.percentage_daily_activity}%`
            ),
            key: 'percentage_daily_activity',
            align: 'center'
        },
        {
            title: 'Percentage Finished Task',
            render: (row) => (
                `${row?.percentage_finished_task}%`
            ),
            key: 'percentage_finished_task',
            align: 'center'
        },
    ];

    const disabledDate = (current) => {
        let currentMonth = moment().format('MM');
        let addYear = 1;

        if (currentMonth === '12') {
            addYear = 2;
        }

        let customDate = moment(new Date()).add(addYear, 'y').format('YYYY');
        return current && current > moment(customDate, "YYYY");
    };

    const exportReport = () => {
        window.open(process.env.REACT_APP_API_URL + '/report/scoreCard?period_id=' + periodId + '&year=' + year)
    }

    return (
        <Card className="content-container">
            <PageTitle title="Score Card Report" breadcrumbs={[['Score Card Report']]}></PageTitle>

            <div className="text-right mb-2">
                <Space>
                    <Button type="primary" onClick={() => exportReport()} shape="circle" icon={<FileExcelOutlined />}></Button>
                    <Button shape="circle" icon={<FilterOutlined />} onClick={() => setShowFilter(true)}></Button>
                </Space>
            </div>
            {
                (loaded) ?
                    <DataTable size="small" columns={attendanceColumn} dataSource={scoreCardData} bordered />
                    : <Spinner />
            }

            <Drawer
                title="Filter Report"
                width={400}
                placement="right"
                closable={false}
                onClose={() => setShowFilter(false)}
                visible={showFilter}>
                <Form form={form} layout="vertical" onFinish={filterData}>
                    <Form.Item label="Year" name="year">
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                    </Form.Item>

                    <Form.Item label="Period" name="period">
                        <Select placeholder="Select Period" onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item >
                        <div className="text-right">
                            <Button htmlType="submit" type="primary" size="middle" icon={<FilterOutlined />}> Show</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Drawer>
        </Card>
    );
}

export default ScoreCard;
