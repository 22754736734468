import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { DataTable, DeleteButton, EditButton, Spinner } from '../../../../components'
import { SentralModel } from '../../../../models/SentralModel'
import { Space, Tabs, Tag } from 'antd'
import moment from 'moment'

const { TabPane } = Tabs

const TicketBoost = () => {
    const history = useHistory()
    const [defaultTab, setDefaultTab] = useState('ticket')
    const [dataTicket, setDataTicket] = useState([])
    const [ticketAssigned, setTicketAssigned] = useState([])
    const [loaded, setLoaded] = useState(false)

    const fetchTicket = (v) => {
        setLoaded(false)
        SentralModel.action("Task", "ticketIt", v, 0).then((res) => {
            setDataTicket(res.data?.not_assigned_ticket)
            setTicketAssigned(res.data?.assigned_ticket)
            setLoaded(true)
        })
    }

    const column = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
        },
        {
            title: 'No Ticket',
            render: (row) => (
                row?.task_id
            ),
            key: 'no_ticket',
            align: 'center'
        },
        {
            title: 'Submitted Date',
            render: (row) => (
                moment(row?.created_at).format('DD MMMM YYYY')
            ),
            key: 'submitted_date',
            align: 'center'
        },
        {
            title: 'Company Name',
            dataIndex: 'company_name',
            key: 'company_name'
        },
        {
            title: 'PIC',
            dataIndex: 'pic_name',
            key: 'pic_name',
            align: 'center'
        },
        {
            title: 'Technician',
            render: (row) => (
                row?.multiple_task_to?.length === 0 ?
                    <Tag color="red">Not Assigned</Tag>
                    :
                    row?.multiple_task_to?.map((el, key) => (
                        <Tag color="green" key={key}>{el?.task_to?.name}</Tag>

                    ))
            ),
            key: 'technician'
        },
        {
            title: 'SLA Level',
            dataIndex: 'sla_level',
            render: (row) => (
                row === 'Low' ?
                    <Tag color='green'>Low</Tag>
                    : row === 'Medium' ?
                        <Tag color='blue'>Medium</Tag>
                        : <Tag color='red'>High</Tag>
            ),
            key: 'sla_level'
        },
        {
            title: 'Due Date',
            render: (row) => (
                moment(row?.due_date).format('DD MMMM YYYY')
            ),
            key: 'due_date'
        },
        {
            title: 'Created By',
            render: (row) => (
                row?.task_from?.name
            ),
            key: 'created_by'
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <EditButton onEdit={() => history.push(`ticket-admin/detail/${row.task_id}`)} />
                    <DeleteButton onConfirm={() => console.log(row)} disabled />
                </Space>
            ),
            align: 'center'
        }
    ]

    useEffect(() => {
        fetchTicket({ start_date: null, end_date: null })

    }, [])

    return (
        <div>
            {
                loaded ?
                    <Tabs type='card' defaultActiveKey={defaultTab} onChange={(v) => setDefaultTab(v)}>
                        <TabPane tab="Ticket" key="ticket">
                            <DataTable size="small" bordered columns={column} dataSource={dataTicket} />
                        </TabPane>
                        <TabPane tab="Assign Ticket" key="assign_ticket">
                            <DataTable size="small" bordered columns={column} dataSource={ticketAssigned} />
                        </TabPane>
                    </Tabs>
                    : <Spinner />
            }
        </div>
    )
}

export default TicketBoost