import { useEffect, useState } from 'react';
import { Card, Row, Col, Input, Descriptions, Typography, Form, Select, Tag, message, DatePicker } from 'antd';
import { BasicModal, PageTitle, Spinner, DetailButton, AddButton, FormModal } from '../../../components';
import { SentralModel } from '../../../models/SentralModel'
import { checkRole } from '../../../helpers/roleData';
import { toFullDate } from '../../../utils/dateHandler';
import moment from 'moment';

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const Index = () => {
    const [loaded, setLoaded] = useState(false)
    const [profileModal, setProfileModal] = useState(false)
    const [searching, setSearching] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [employeeDivisions, setEmployeeDivisions] = useState([])
    const [companies, setCompanies] = useState([])
    const [divisions, setDivisions] = useState([])
    const [employees, setEmployees] = useState([])
    const [filteredEmployees, setFilteredEmployees] = useState([])
    const [positions, setPositions] = useState([])
    const [genders, setGenders] = useState([])
    const [employeeStatus, setEmployeeStatus] = useState([])
    const [saving, setSaving] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [selectedDivision, setSelectedDivision] = useState(null)
    const [selectedPosition, setSelectedPosition] = useState(null)
    const [selectedGender, setSelectedGender] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)

    const fetchEmployeeDivisions = () => {
        SentralModel.action('Division', 'getEmployeeByDivision', {}, 0).then(res => {
            setLoaded(false)
            setEmployeeDivisions(res.data)
            let emps = [];
            res.data.forEach(el => {
                emps = emps.concat(el.employees)
            });
            setEmployees(emps)

            setLoaded(true)
        })
    }

    const fetchDivisions = () => {
        SentralModel.list('Division', { filter: [["is_active", "1"]], orderBy: "division_name", order: "asc" }).then(res => {
            setDivisions(res.data)
            setLoaded(true)
        })
    }

    const fetchCompanies = () => {
        SentralModel.list('Company', { filter: [["company_code", "!=", null]], orderBy: "company_code", order: "desc" }).then(res => {
            setCompanies(res.data)
            setLoaded(true)
        })
    }

    const fetchPositions = () => {
        SentralModel.list('Position', { filter: [["is_active", "1"]], orderBy: "position_name", order: "asc" }).then(res => {
            setPositions(res.data)
            setLoaded(true)
        })
    }

    const fetchGender = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'G']], orderBy: "order_key", order: "asc" }).then(res => {
            setGenders(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchEmployeeStatus = () => {
        SentralModel.list('GeneralDetail', { filter: [['general_code', 'ES'], ['detail_code', '!=', 'ESRS'], ['detail_code', '!=', 'ESFR']], orderBy: "order_key", order: "asc" }).then(res => {
            setEmployeeStatus(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchEmployeeDivisions();
        fetchCompanies();
        fetchDivisions();
        fetchPositions();
        fetchGender();
        fetchEmployeeStatus();
    }, []);

    const addEmployee = () => {
        setFormModal(true)
    }

    const saveEmployee = (v) => {
        setSaving(true)
        let data = {
            company_id: selectedCompany,
            join_date: moment(v.join_date, 'DD MMMM YYYY').format('YYYY-MM-DD'),
            division_id: selectedDivision,
            position_id: selectedPosition,
            name: v.name,
            gender: selectedGender,
            work_email: v.work_email,
            employee_status: selectedStatus,
            password: 'password'
        }
        SentralModel.action('HumanResource', 'addEmployee', data, 0).then((res) => {
            setFormModal(false)
            fetchEmployeeDivisions()
            message.success('Employee saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const searchEmployee = (v) => {
        let val = v.target.value
        if (val) {
            setSearching(true)
            let filteredEmployees = employees.filter(el => el.name.toLowerCase().includes(val.toLowerCase()))
            setFilteredEmployees(filteredEmployees)
        } else {
            setSearching(false)
            setFilteredEmployees([])
        }
    }

    const showModal = (id, emp_code) => {
        let division = employeeDivisions.find(div => div.division_id === id)
        let employee = division.employees.find(emp => emp.employee_code === emp_code)
        setDefaultValues({
            employee_code: employee.employee_code,
            division_name: employee.division.division_name,
            position_name: employee.position.position_name,
            status_detail: (employee.status_detail) ? employee.status_detail.description : '',
            employee_grade: (employee.employee_grade) ? employee.employee_grade : '-',
            employee_status: employee.employee_status,
            exit_date: employee.exit_date,
            probation_end: (employee.probation) ? employee.probation.probation_end : null,
            contract_end: (employee.probation) ? employee.probation.probation_end : null,
            name: employee.name,
            work_email: employee.work_email === null ? '-' : employee.work_email,
            mobile_phone: employee.mobile_phone === null ? '-' : employee.mobile_phone,
            extension_no: employee.extension_no === null ? '-' : employee.extension_no,
            photo: employee.document === null || employee.document.photo === null ? process.env.PUBLIC_URL + '/karajo.png' : process.env.REACT_APP_API_FILE + "/" + employee.document.photo,
            station: (employee.station) ? employee.station.city_name : '-',
        })
        setProfileModal(true)
    }

    const changeCompany = e => {
        setSelectedCompany(e)
    }

    const changeDivision = e => {
        setSelectedDivision(e)
    }

    const changePosition = e => {
        setSelectedPosition(e)
    }

    const changeGender = e => {
        setSelectedGender(e)
    }

    const changeStatus = e => {
        setSelectedStatus(e)
    }

    return (
        <div>
            <Card className="content-container" style={{ backgroundColor: '#F0F2F5', border: 1 }}>
                <PageTitle title="Employee" breadcrumbs={[['Employee']]}></PageTitle>

                {
                    loaded ?
                        <div>
                            <Row justify="space-between" style={{ marginTop: 50, marginBottom: 20 }}>
                                <Col span={24} >
                                    {
                                        checkRole('ADMIN') || checkRole('HR') ?
                                            <AddButton title="Add Employee" onAdd={addEmployee} />
                                            : null
                                    }
                                    <Search
                                        placeholder="Search Employee"
                                        onSearch={(val) => searchEmployee(val)}
                                        onChange={(val) => searchEmployee(val)}
                                        enterButton
                                        style={{ width: 300, float: 'right' }}
                                    />
                                </Col>
                            </Row>
                            {
                                (searching) ?
                                    <Row style={{ marginBottom: 20 }}>
                                        {
                                            filteredEmployees.map((employee, index) => (
                                                <Col xs={8} sm={8} md={4} key={'directory_employee_' + index}>
                                                    <div className="directory-item" style={{ position: 'relative' }}>
                                                        {
                                                            (!employee.is_active) ?
                                                                <Tag color={'red'} style={{ position: 'absolute', top: 5, right: 0, zIndex: 1 }}>Inactive</Tag>
                                                                : null
                                                        }
                                                        <Card onClick={() => showModal(employee.division_id, employee.employee_code)} style={{ borderRadius: 10, cursor: 'pointer' }}>
                                                            {/* <img className="directory-item-image" alt="example" src={employee.document === null ? process.env.PUBLIC_URL + '/images/user-male.png' : process.env.REACT_APP_API_FILE + "/" + employee.document.photo} /><br/> */}
                                                            <img className="directory-item-image" loading="lazy" alt="example" src={employee.document === null || employee.document.photo === null ? process.env.PUBLIC_URL + '/karajo.png' : process.env.REACT_APP_API_FILE + "/" + employee.document.photo} /><br />

                                                            <div className="directory-item-detail">
                                                                <Text strong>{employee.name}</Text><br />
                                                                <Text level={2} strong>{employee.employee_code}</Text><br />
                                                                <Text level={2} type="secondary">{employee?.position?.position_name}</Text><br />
                                                                <Text level={2} type="secondary">{employee.extension_no === null ? 'Ext (-)' : 'Ext (' + employee.extension_no + ')'}</Text>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    :
                                    employeeDivisions.map((division, key) => (
                                        <Row key={'directory_division_' + key} style={{ marginBottom: 20 }}>
                                            <Col span={24} className="directory-division">
                                                <Title level={4}>{division.division_name}</Title>
                                            </Col>
                                            {
                                                division.employees.map((employee, index) => (
                                                    <Col xs={8} sm={8} md={4} key={'directory_employee_' + index}>
                                                        <div className="directory-item" style={{ position: 'relative' }}>
                                                            {
                                                                (!employee.is_active) ?
                                                                    <Tag color={'red'} style={{ position: 'absolute', top: 5, right: 0, zIndex: 1 }}>Inactive</Tag>
                                                                    : null
                                                            }
                                                            <Card onClick={() => showModal(division.division_id, employee.employee_code)} style={{ borderRadius: 10, cursor: 'pointer' }}>
                                                                {/* <img className="directory-item-image" alt="example" src={employee.document === null ? process.env.PUBLIC_URL + '/images/user-male.png' : process.env.REACT_APP_API_FILE + "/" + employee.document.photo} /><br/> */}
                                                                <img className="directory-item-image" loading="lazy" alt="example" src={employee.document === null || employee.document.photo === null ? process.env.PUBLIC_URL + '/karajo.png' : process.env.REACT_APP_API_FILE + "/" + employee.document.photo} /><br />

                                                                <div className="directory-item-detail">
                                                                    <Text strong>{employee.name}</Text><br />
                                                                    <Text level={2} strong>{employee.employee_code}</Text><br />
                                                                    <Text level={2} type="secondary">{employee?.position?.position_name}</Text><br />
                                                                    <Text level={2} type="secondary">{employee.extension_no === null ? 'Ext (-)' : 'Ext (' + employee.extension_no + ')'}</Text>
                                                                </div>
                                                            </Card>
                                                        </div>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    ))
                            }
                        </div>
                        : <Spinner />
                }

                {
                    profileModal ?
                        <BasicModal title="Employee Detail" showModal={profileModal} onCloseModal={() => setProfileModal(false)}>
                            <div className="directory-item-modal-box">
                                <div className="text-center" style={{ marginBottom: 20 }}>
                                    <img className="directory-item-modal-image" alt="employee-profile" src={defaultValues.photo} />
                                    <Title style={{ display: 'block', marginBottom: 0 }} level={4}>{defaultValues.name}</Title>
                                    <Text style={{ display: 'block' }} type="secondary">{defaultValues.division_name}</Text>
                                </div>
                                <Descriptions column={1} bordered size="small">
                                    <Descriptions.Item label="Position">{defaultValues.position_name}</Descriptions.Item>
                                    <Descriptions.Item label="Extension">{defaultValues.extension_no}</Descriptions.Item>
                                    <Descriptions.Item label="Email">{defaultValues.work_email}</Descriptions.Item>
                                    <Descriptions.Item label="Phone Number">{defaultValues.mobile_phone}</Descriptions.Item>
                                    {/* <Descriptions.Item label="Employee Status">{defaultValues.status_detail}</Descriptions.Item> */}
                                    <Descriptions.Item label="Employee Grade">{defaultValues.employee_grade}</Descriptions.Item>
                                    <Descriptions.Item label="Station">{defaultValues.station}</Descriptions.Item>
                                    {
                                        (defaultValues.employee_status === 'ESRS' || defaultValues.employee_status === 'ESFR') ?
                                            <Descriptions.Item label="Exit Date">{toFullDate(defaultValues.exit_date)}</Descriptions.Item>
                                            : null
                                    }
                                    {/* {
                                        (defaultValues.employee_status === 'ESPB' && defaultValues.probation_end) ?
                                            <Descriptions.Item label="Probation End">{toFullDate(defaultValues.probation_end)}</Descriptions.Item>
                                            : null
                                    }
                                    {
                                        (defaultValues.employee_status === 'ESKN' && defaultValues.contract_end) ?
                                            <Descriptions.Item label="Contract End">{toFullDate(defaultValues.contract_end)}</Descriptions.Item>
                                            : null
                                    } */}
                                </Descriptions>
                                {
                                    checkRole('SUPERADMIN') || checkRole('ADMIN') || checkRole('HR') || checkRole('REC') ?
                                        <div className="text-center">
                                            <br />
                                            <DetailButton to={'/profile/' + defaultValues.employee_code} />
                                        </div>
                                        : null
                                }
                            </div>
                        </BasicModal>
                        : null
                }

                {
                    (formModal) ?
                        <FormModal title="Add Employee" submitForm={(v) => saveEmployee(v)} width={800} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                            <Row>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Company" name="company_id" rules={[{ required: true }]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Company" optionFilterProp="children" onChange={changeCompany} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                companies.map((el, key) => (
                                                    <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Join Date" name="join_date" rules={[{ required: true }]}>
                                        <DatePicker placeholder="Join Date" format="DD MMMM YYYY" style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Division" name="division_id" rules={[{ required: true }]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Division" optionFilterProp="children" onChange={changeDivision} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                divisions.map((el, key) => (
                                                    <Option key={key} value={el.division_id}>{el.division_name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Position" name="position_id" rules={[{ required: true }]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Position" optionFilterProp="children" onChange={changePosition} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                positions.map((el, key) => (
                                                    <Option key={key} value={el.position_id}>{el.position_name}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Name" name="name" required rules={[{ required: true }]}>
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Work Email" name="work_email" rules={[{ required: true }]}>
                                        <Input placeholder="Work Email" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Gender" name="gender" rules={[{ required: true }]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Gender" optionFilterProp="children" onChange={changeGender} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                genders.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <Form.Item label="Employee Status" name="employee_status" rules={[{ required: true }]}>
                                        <Select showSearch style={{ width: "100%" }} placeholder="Select Status" optionFilterProp="children" onChange={changeStatus} filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {
                                                employeeStatus.map((el, key) => (
                                                    <Option key={key} value={el.detail_code}>{el.description}</Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Form.Item label="Password" name="password" rules={[{required:true}]}>
                            <Input.Password placeholder="Password"/>
                        </Form.Item> */}
                        </FormModal>
                        : null
                }
            </Card>
        </div>
    );
}

export default Index;
