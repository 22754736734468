import React from 'react';
import { Tabs, Space } from 'antd'
import { FieldTimeOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { toFullDate } from '../../../utils/dateHandler'
import { DataTable, ShowButton } from '../../../components'

const { TabPane } = Tabs

const RealizationRequest = (props) => {
    // const showLogs = (row) => {
    //     props.showRequestLog(row)
    // }
    const leaveColumn = [{
        title: 'No',
        dataIndex: 'no',
        align: 'center',
        key: 'name',
    },
    {
        title: 'Leave Type',
        render: (row) => (
            <span>
                {row.leave_type}
                {
                    (row.cancellation_status === "APPROVED") ? <span style={{ color: 'red' }}><br />cancelled</span> : null
                }
            </span>
        )
    }, {
        title: 'Date',
        render: (row) => (
            (row.total_days === 1) ? row.date_from : row.date_range
        )
    }, {
        title: 'Leave Information',
        dataIndex: 'information',
        key: 'information'
    }, {
        title: 'Necessity',
        dataIndex: 'necessity',
        key: 'necessity',
    }, {
        title: 'Responsible Employee',
        dataIndex: 'responsible_employee',
        key: 'responsible_employee',
    }, {
        title: 'Action',
        align: 'center',
        render: (row) => (
            <Space>
                <ShowButton link to={'/leave-realization/' + row.id} />
            </Space>
        )
    }
    ];

    const leave = [
        ...props.data.map((el) => {
            return {
                id: el.sub_leave_id,
                leave_type: el.leave.description,
                information: el.information.description,
                necessity: el.reason,
                responsible_employee: (el.responsible) ? el.responsible.name : '-',
                date_from: toFullDate(el.from_date),
                date_to: toFullDate(el.to_date),
                date_range: toFullDate(el.from_date) + ' - ' + toFullDate(el.to_date),
                total_days: el.total_days,
                status_realization: el.status_realization,
                cancellation_status: el.cancellation_status,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ];
    return (
        <div>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{ color: '#4ca3ff' }} /> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={leaveColumn} dataSource={leave.filter(el => el.status_realization === "WAITING")} bordered />
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{ color: 'green' }} /> Approved</span>} key="approved">
                    <DataTable size="small" columns={leaveColumn} dataSource={leave.filter(el => el.status_realization === "APPROVED")} bordered />
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{ color: 'red' }} /> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={leaveColumn} dataSource={leave.filter(el => el.status_realization === "REJECTED")} bordered />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default RealizationRequest;
