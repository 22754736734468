import React, { useState } from 'react';
import { Card, message } from 'antd';
import { PageTitle } from '../../../components'
import RequestForm from './RequestForm'
import { SentralModel } from '../../../models/SentralModel'
import { useHistory, useParams } from 'react-router-dom'

const Create = () => {
    const history = useHistory()
    const { referralId } = useParams()
    const [saving, setSaving] = useState()


    const submitReferral = (v) => {
        setSaving(true)
        SentralModel.action('Referral', 'saveReferral', v, referralId).then((res) => {
            setSaving(false)
            message.success('Success Save Data')
            history.push('/referral')
        }).catch((err) => {
            console.log(err)
            message.destroy()
            message.error('Oops, something went wrong', 2)
            setSaving(false)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Referral" breadcrumbs={[['Referral', '/referral'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitReferral(v)} saving={saving} referral_id={referralId} />
        </Card>
    );
}

export default Create;
