import { useState, useEffect } from 'react'
import { Button, Card, Col, DatePicker, Row, Select, Space, Statistic, Tag } from 'antd'
import { SentralModel } from '../../../models/SentralModel'
import { generateMonth } from '../../../helpers/generateMonth'
import moment from 'moment'
import { DataTable, PageTitle, Spinner } from '../../../components'
import dayjs from 'dayjs'

const LogAttendance = () => {
    const [divisions, setDivisions] = useState([])
    const [selectedDivision, setSelectedDivision] = useState(0)
    const [defaultDate, setDefaultDate] = useState(moment(Date.now()))
    const [loaded, setLoaded] = useState(true)
    const [dashboardData, setDashboardData] = useState([])
    const [cardData, setCardData] = useState({})

    const fetchDivision = () => {
        SentralModel.list('Division', { filter: [["is_active", "1"]], orderBy: "division_name", order: "asc" }).then((res) => {
            let arr = []
            res.data.forEach((el) => {
                arr.push({ value: el.division_id, label: el.division_name })
            })

            setDivisions(arr)
        })
    }

    useEffect(() => {
        fetchDivision()

    }, [])

    const handleChangeDivision = (v) => {
        setSelectedDivision(v)
    }

    const fetchDashboardData = (v) => {
        setLoaded(false)
        let payload = {
            division_id: selectedDivision,
            date: moment(defaultDate).format("YYYY-MM-DD"),
            year: moment(defaultDate).format("YYYY"),
            month: generateMonth(moment(defaultDate).format("M"))
        }

        SentralModel.action("Dashboard", "dashboardLogAttendnace", payload, 0).then((res) => {
            let verified = 0
            let notVerified = 0
            let leave = 0
            let alpha = 0

            res.data.forEach((el) => {
                if (el.attendance.is_verified === 0 || el.attendance.is_verified === null) {
                    notVerified += 1

                    if (el.attendance.description.includes('Alpha')) {
                        alpha += 1
                    }
                    if (el.attendance.description.includes('Leave')) {
                        leave += 1
                    }
                } else {
                    verified += 1
                }
            })

            setCardData({
                verified,
                notVerified,
                leave,
                alpha
            })
            setDashboardData(res.data)
            setLoaded(true)
        })
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '3%',
            align: 'center'
        },
        {
            title: 'Employee',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Date',
            render: (row) => (
                dayjs(row?.attendance?.date).format('DD MMMM YYYY')
            ),
            key: 'date'
        },
        {
            title: 'Clock In',
            render: (row) => (
                row?.attendance?.in_time
            ),
            key: 'clock_in'
        },
        {
            title: 'Clock Out',
            render: (row) => (
                row?.attendance?.out_time
            ),
            key: 'clock_out'
        },
        {
            title: 'Verified',
            render: (row) => (
                <Tag color={row?.attendance?.is_verified === 0 || row?.attendance?.is_verified === null ? "red" : "green"}>{row?.attendance?.is_verified === 0 || row?.attendance?.is_verified === null ? 'Not Verified' : 'Verified'}</Tag>
            ),
            key: 'verified',
            align: 'center'
        },
        {
            title: 'Description',
            render: (row) => (
                <Tag color={row?.attendance?.description === 'In' ? 'green' : 'red'}>
                    {row?.attendance?.description}
                </Tag>
            ),
            key: 'description',
            align: 'center'
        }
    ]

    return (
        <Card className="content-container">
            <PageTitle title={'Dashboard Log Attendance'} breadcrumbs={[['Dashboard Log Attendance']]}></PageTitle>
            <Card title={
                <Row justify='end'>
                    <Col>
                        <Space size="large">
                            <Select
                                showSearch
                                placeholder="Select Division"
                                options={divisions}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                style={{ width: "200px" }}
                                onChange={(v) => handleChangeDivision(v)}
                            />
                            <DatePicker onChange={(v) => setDefaultDate(v)} defaultValue={moment(defaultDate)} />
                            <Button disabled={selectedDivision === 0 ? true : false} type='primary' onClick={fetchDashboardData}>
                                Show
                            </Button>
                        </Space>
                    </Col>
                </Row>
            }>
                {
                    loaded ?
                        <div>
                            <Row className='mb-5' justify='space-between'>
                                <Col span={5}>
                                    <Card>
                                        <Statistic
                                            title="Verified"
                                            value={cardData?.verified ?? 0}
                                        />
                                    </Card>
                                </Col>
                                <Col span={5}>
                                    <Card>
                                        <Statistic
                                            title="Not Verified"
                                            value={cardData?.notVerified ?? 0}
                                        />
                                    </Card>
                                </Col>
                                <Col span={5}>
                                    <Card>
                                        <Statistic
                                            title="Leave"
                                            value={cardData?.leave ?? 0}
                                        />
                                    </Card>
                                </Col>
                                <Col span={5}>
                                    <Card>
                                        <Statistic
                                            title="Alpha"
                                            value={cardData?.alpha ?? 0}
                                        />
                                    </Card>
                                </Col>
                            </Row>

                            <DataTable dataSource={dashboardData} columns={columns} search={false} />
                        </div>
                        : <Spinner />
                }
            </Card>
        </Card>
    )
}

export default LogAttendance