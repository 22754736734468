import React, {useEffect, useState} from 'react';
import {Card, Form, Typography, Row, Col, Input, Descriptions, message} from 'antd'
import {PageTitle, Spinner, SubmitButton} from '../../../components'
import {useParams} from 'react-router-dom';
import {SentralModel} from '../../../models/SentralModel'
import { toFullDate } from '../../../utils/dateHandler';
import authData from '../../../helpers/authData'
import {useHistory} from 'react-router-dom'
import moment from 'moment'

const {Text} = Typography
const {TextArea} = Input;

const Effectivity = () => {
    const {trainingId} = useParams();
    const [form] = Form.useForm();
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [training, setTraining] = useState([]);
    const [trainingParticipant, setTrainingParticipant] = useState(null);
    const [questions, setQuestions] = useState([]);

    const fetchTraining = (trainingId) => {
        setLoaded(false)
        SentralModel.get('Training', {}, trainingId).then(res => {
            setTraining(res.data)
        }).finally(() => {
            setLoaded(true)
        })
    }

    const fetchQuestion = () => {
        SentralModel.list('TrainingEffectivityQuestion', {filter: [['is_active', 1]]}).then((res) => {
            setLoaded(false);
            setQuestions(res.data);
        }).finally(() => {
            setLoaded(true);
        })
    }

    const fetchParticipant = (trainingId) => {
        SentralModel.action('Training', 'getTrainingParticipant', { training_id: trainingId, employee_code: authData.employee_code }, 0).then((res) => {
            setTrainingParticipant(res.data);
        });
    }

    useEffect(() => {
        fetchTraining(trainingId);
        fetchQuestion();
        fetchParticipant(trainingId);
    }, [trainingId]);

    const saveEvaluation = (v) => {
        setSaving(true);

        let data = {
            training_id: trainingId,
            training_participant_id: trainingParticipant.training_participant_id,
        }

        questions.forEach(element => {
            data[element.question] = v[element.question];
        });

        SentralModel.action('Training', 'saveEffectivity', data, trainingId).then((res) => {
            message.destroy();
            message.success('Effectivity Saved', 1);
            setSaving(false);
            setTimeout(() => {
                history.push('/request-training');
            }, 1000);
        }).catch(() => {
            message.destroy();
            message.error('Oops, something went wrong', 2);
            setSaving(false);
        });
    }

    return (
        <Card className="content-container">
            <PageTitle title="Evaluasi Efektivitas Training" breadcrumbs={[['Training and Certification', '/request-training'], ['Evaluasi Efektivitas Training']]}></PageTitle>
            <Card>
                {
                    (loaded) ?
                        <div>
                            <Row justify='space-between'>
                                <Descriptions className="mb-3" layout="horizontal" column={1} bordered size="small" style={{width:"60%"}}> 
                                    <Descriptions.Item label="Judul">{training.title}</Descriptions.Item>
                                    <Descriptions.Item label="Tanggal">{toFullDate(training.training_date)}</Descriptions.Item>
                                    <Descriptions.Item label="Waktu">{moment(training.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(training.end_time, 'HH:mm:ss').format('HH:mm')}</Descriptions.Item>
                                    <Descriptions.Item label="Trainer">
                                        <ol style={{ paddingLeft: 20 }}>
                                            {
                                                training.trainers?.map((el, key) => (
                                                    <li key={key}>{el.name}</li>
                                                ))
                                            }
                                        </ol>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Row>

                            <Form size="middle" form={form} onFinish={saveEvaluation}>
                                {
                                    questions?.map((ques, j) => (
                                        <>
                                            <Row style={{marginBottom: '5px'}}>
                                                <Text style={{display: 'block', color: '#ed5b56'}}>*</Text>
                                                <Text style={{display: 'block', marginLeft: '5px'}}>{ques.question}</Text>
                                            </Row>
                                            <Form.Item key={'question_'+j} name={ques.question} labelAlign="left" rules={[{required: true}]}>
                                                {
                                                    ques.multiple_choice === 'Y/N' ?
                                                        <fieldset id={"group_"+j}>
                                                            <input type="radio" value="Y" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Ya</label><br />
                                                            <input type="radio" value="N" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Tidak</label><br />
                                                        </fieldset> :
                                                    ques.multiple_choice === 'NUMBER6' ?
                                                        <fieldset id={"group_"+j}>
                                                            <input type="radio" value="0" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Tidak Pernah</label><br />
                                                            <input type="radio" value="1" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Setiap Tahun</label><br />
                                                            <input type="radio" value="2" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Setiap 6 Bulan</label><br />
                                                            <input type="radio" value="3" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Setiap 3 Bulan</label><br />
                                                            <input type="radio" value="4" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Setiap Bulan</label><br />
                                                            <input type="radio" value="5" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Setiap Minggu</label><br />
                                                            <input type="radio" value="6" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Setiap Hari</label><br />
                                                        </fieldset> :
                                                    ques.multiple_choice === 'NUMBER4' ?
                                                        <fieldset id={"group_"+j}>
                                                            <input type="radio" value="0" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Tidak Penting Sama Sekali</label><br />
                                                            <input type="radio" value="1" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Tidak Penting</label><br />
                                                            <input type="radio" value="2" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Rata-rata</label><br />
                                                            <input type="radio" value="3" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Penting</label><br />
                                                            <input type="radio" value="4" name={"group_"+j} />
                                                            <label style={{marginLeft: 20}}>Sangat Penting</label><br />
                                                        </fieldset> :
                                                        <TextArea rows={3} placeholder="Answer..." />
                                                }
                                            </Form.Item>
                                        </>
                                    ))
                                }
                                <Row justify="space-around">
                                    <Col span={24} className="text-right">
                                        <SubmitButton loading={saving}/>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    : <Spinner />
                }
            </Card>
        </Card>
    )
}

export default Effectivity;
