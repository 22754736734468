import { useDispatch, useSelector } from 'react-redux'
import { FormModal } from '../../../../../../components'
import { DatePicker, Form, Input, message } from 'antd'
import {
    showModalExtend,
} from '../../../../../../redux/slices/Dashboard/myTaskSlice'
import { SentralModel } from '../../../../../../models/SentralModel'
import { useState } from 'react'

const ModalExtend = () => {
    const dispatch = useDispatch()
    const [loaded, setLoaded] = useState(false);
    const modalExtend = useSelector((state) => state.myTask.modalExtend)
    const extendDataSend = useSelector((state) => state.myTask.extendDataSend)

    const onFinish = (v) => {
        setLoaded(true)
        let payload = {
            all_app_task_id: extendDataSend.id,
            extended_date: v.extended_date,
            reason_extending: v.reason_extending
        }

        SentralModel.action('ExtendedTask', 'createExtend', payload, 0).then((res) => {
            message.success('Success Add Request Extend')
            dispatch(showModalExtend({ status: false, data: null }))
            setLoaded(false)
        }).catch((err) => {
            message.warning('Failed Add Request Extend')
            setLoaded(false)
        })
    }

    return (
        <FormModal showModal={modalExtend} title="Extended Task" submitForm={(v) => onFinish(v)} width={500} onCloseModal={() => dispatch(showModalExtend({ status: false, data: null }))} saving={loaded}>
            <Form.Item label="Extended Date" name="extended_date" required rules={[{ required: true }]}>
                <DatePicker />
            </Form.Item>
            <Form.Item label="Reason Extending" name="reason_extending" required rules={[{ required: true }]}>
                <Input.TextArea placeholder='Reason Extending' rows={3} />
            </Form.Item>
        </FormModal >
    )
}

export default ModalExtend