import { useDispatch, useSelector } from 'react-redux'
import { FormModal, Uploader } from '../../../../../../components'
import { Form, Input, message } from 'antd'
import {
    changeRemark,
    changeUploading,
    changeModalConfirm,
    fileUploadFinish,
    saveFinishTask,
    fetchMyTask,
    fetchListProgressTask
} from '../../../../../../redux/slices/Dashboard/myTaskSlice'

const ModalConfirm = () => {
    const dispatch = useDispatch()
    const saveDataTask = useSelector((state) => state.myTask.saveDataTask)
    const modalConfirm = useSelector((state) => state.myTask.modalConfirm)
    const remarksFinished = useSelector((state) => state.myTask.remarksFinished)
    const taskProgressData = useSelector((state) => state.myTask.taskProgressData)
    const attachmentFile = useSelector((state) => state.myTask.attachmentFile)
    const attachmentConfirm = useSelector((state) => state.myTask.attachmentConfirm)
    const saving = useSelector((state) => state.myTask.saving)
    const uploading = useSelector((state) => state.myTask.uploading)

    const onFinish = () => {
        let payload = {
            ...saveDataTask,
            remark_finished: remarksFinished,
            daily_progress: taskProgressData,
            attachment: attachmentFile,
            finished_attachment: attachmentConfirm
        }

        if (saveDataTask.confirm_finished === "confirm_with_attachment" && attachmentConfirm === null) {
            return message.warning('Attachment cannot be empty')
        } else {
            dispatch(saveFinishTask(payload)).then(() => {
                dispatch(fetchMyTask())
                dispatch(fetchListProgressTask())
            })
        }
    }

    return (
        <FormModal showModal={modalConfirm} title="Confirm Finished" submitForm={(v) => onFinish()} width={500} onCloseModal={() => dispatch(changeModalConfirm(false))} saving={uploading || saving}>
            {
                saveDataTask.confirm_finished === "confirm" ?
                    <Form.Item label="Remark Finished" name="remark_finished" onChange={(v) => dispatch(changeRemark(v.target.value))}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    :
                    <Form.Item label="Upload Attachment" name="attachment">
                        <Uploader dragger folder="attachment-daily-activity" onUploaded={(v) => dispatch(fileUploadFinish(v))} isUploading={(v) => dispatch(changeUploading(v))} view={true} />
                    </Form.Item>
            }
        </FormModal>
    )
}

export default ModalConfirm