import React, {useEffect, useState} from 'react';
import {Card, Typography, Form, Input, Select, message, Space, DatePicker} from 'antd'
import {EditButton, PageTitle, Spinner, FormModal, DataTable} from '../../../components'
import {SentralModel} from '../../../models/SentralModel'
import { toFullDate } from '../../../utils/dateHandler';
import moment from 'moment';

const {Text} = Typography
const {Option} = Select;

const Index = () => {
    const [employeeStatus, setEmployeeStatus] = useState([])
    const [employeeSuperiors, setEmployeeSuperiors] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [formModal, setFormModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})
    const [saving, setSaving] = useState(false)
    const [modalTitle, setModalTitle]= useState(null)
    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [exitDate, setExitDate] = useState(false);

    const fetchEmployeeStatus = () =>{
        SentralModel.action('HumanResource', 'getEmployeeStatus').then(res=>{
            setEmployeeStatus(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchEmployeeSuperior = () =>{
        SentralModel.action('HumanResource', 'getEmployeeSuperior').then(res=>{
            setLoaded(false)
            setEmployeeSuperiors(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    const fetchStatus = () => {
        SentralModel.list('GeneralDetail', {filter: [['general_code', 'ES']], orderBy: 'order_key', order: 'asc'}).then(res=>{
            setLoaded(false)
            setStatus(res.data)
        }).finally(()=>{
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchEmployeeStatus()
        fetchEmployeeSuperior();
        fetchStatus()
    }, []);
    
    const dataSource = [
        ...employeeStatus.map( el => {
            let info = '';
            if (el.employee_status === 'ESRS' && el.exit_date) {
                info = 'Exit Date : ' +toFullDate(el.exit_date)
            } else if (el.employee_status === 'ESFR' && el.exit_date) {
                info = 'Fired Date : ' +toFullDate(el.exit_date)
            } else if (el.employee_status === 'ESPB' && el.probation) {
                info = 'Probation Date : ' + toFullDate(el.probation.probation_start) + ' - ' + toFullDate(el.probation.probation_end)
            } else if (el.employee_status === 'ESKN' && el.contract) {
                info = 'Contract Date : ' +toFullDate(el.contract.contract_start) + ' - ' + toFullDate(el.contract.contract_end)
            }
            return {
                superior_id: el.superior_id,
                employee_code: el.employee_code,
                name: el.name,
                employee_status: el.employee_status,
                information: info,
                exit_date: el.exit_date,
                status_descsription: el.employee_status_detail ? el.employee_status_detail.description : '',
            }
        })
    ]

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            width:'70px',
            key: 'name',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status_descsription',
            key: 'status_descsription',
        },
        {
            title: 'Information',
            dataIndex: 'information',
            key: 'information',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editEmployeeStatus(row)}/>
                    </Space>
                </div>
            )
        },
    ];

    const editEmployeeStatus = (v) => {
        setModalTitle('Edit Employee Status')
        setDefaultValues({
            employee_code: v.employee_code,
            superior_id: v.superior_id,
            name: v.name,
            employee_status: v.employee_status,
            exit_date: v.exit_date ? moment(v.exit_date) : null
        })
        setSelectedStatus(v.employee_status)
        setFormModal(true)
        changeStatus(v.employee_status)
    }

    const saveEmployeeStatus = (v) => {
        setSaving(true)
        let data={
            superior_id: v.superior_id || 0,
            employee_code: v.employee_code,
            employee_status: selectedStatus,
            exit_date: moment(v.exit_date).format('YYYY-MM-DD')
        }
        SentralModel.action('HumanResource', 'saveEmployeeStatus', data, v.employee_code).then((res)=>{
            setFormModal(false)
            fetchEmployeeStatus();
            message.success('Employee status saved',2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const changeStatus = e => {
        setSelectedStatus(e)
        if(e === 'ESRS' || e === 'ESFR') setExitDate(true)
        else setExitDate(false)
    }

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Employee Status" breadcrumbs={[['Employee Status']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Employee List</Text>
                    </div> }>

                    {
                        (loaded) ?
                        <DataTable size='small' bordered dataSource={dataSource} columns={columns}/>
                        :
                        <Spinner/>
                    }
                </Card>
                
                {
                    (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v)=>saveEmployeeStatus(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="employee_code" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Employee Name" name="name" required rules={[{required:true}]}>
                            <Input placeholder="Employee Name" readOnly/>
                        </Form.Item>
                        <Form.Item label="Superior" name="superior_id">
                            <Select placeholder="Select Superior" style={{ width: "100%" }}>
                                {
                                    employeeSuperiors.map((el, key) => (
                                    <Option key={key} value={el.employee_code}>{el.name} - {el.position_name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Status" name="employee_status" required rules={[{required:true}]}>
                            <Select placeholder="Select status" style={{ width: "100%" }} onChange={changeStatus}>
                                {
                                    status.map((el, key) => (
                                        <Option key={key} value={el.detail_code}>{el.description}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        { exitDate ? 
                            <Form.Item label="Exit Date" name="exit_date" required rules={[{required:true}]}>
                                <DatePicker placeholder="Exit Date" style={{ width: "100%" }} />
                            </Form.Item>
                            : null
                        }
                    </FormModal>
                    : null
                }
            </Card>
        </div>
    );
}

export default Index;
