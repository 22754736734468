import { Button, Descriptions, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { changeShowInfoTicket } from '../../../../../../../redux/slices/Dashboard/karajoTicketSlice'
import moment from 'moment'

const InfoModalPresales = () => {
    const dispatch = useDispatch()
    const showModalInfoTicket = useSelector((state) => state.karajoTicket.showModalInfoTicket)
    const info = useSelector((state) => state.karajoTicket.info)

    return (
        <Modal visible={showModalInfoTicket} onCancel={() => dispatch(changeShowInfoTicket(false))} title="Ticket Info" width={950}
            footer={
                <Button onClick={() => dispatch(changeShowInfoTicket(false))}>
                    Close
                </Button>
            }>
            <Descriptions title="Presales Ticket Info" bordered>
                <Descriptions.Item label="Company Name">{info?.company_name ?? "-"}</Descriptions.Item>
                <Descriptions.Item label="SLA Level">{info?.sla_level ?? "-"}</Descriptions.Item>
                <Descriptions.Item label="Category Request">{info?.category_request ?? "-"}</Descriptions.Item>
                <Descriptions.Item label="Aanwijzing Date">{moment(info?.aanwijzing_date).format("DD MMMM YYYY")}</Descriptions.Item>
                <Descriptions.Item label="Procurement Name">{info?.procurement_name ?? "-"}</Descriptions.Item>
                <Descriptions.Item label="Presales">{info?.multiple_task_to[0]?.task_to?.name ?? "-"}</Descriptions.Item>
                <Descriptions.Item span={3} label="Description">{info?.description ?? "-"}</Descriptions.Item>
                <Descriptions.Item span={3} label="Remark Presales">{
                    info.progress === null ? "-" : info?.progress[0]?.job_description
                }</Descriptions.Item>
            </Descriptions>
        </Modal>
    )
}

export default InfoModalPresales