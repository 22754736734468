import React, { useState } from 'react';
import { Card, message } from 'antd';
import { PageTitle } from '../../../components'
import RequestForm from './RequestForm'
import { SentralModel } from '../../../models/SentralModel'
import { useHistory } from 'react-router-dom'

const Create = () => {
    const history = useHistory()
    const [saving, setSaving] = useState()

    const submitCashAdvance = (v) => {
        setSaving(true)
        SentralModel.action('Submission', 'saveCashAdvance', v, 0).then((res) => {
            // if (res.message === 'exists') {
            //     setSaving(false)
            //     message.destroy()
            //     message.error("Can't apply for cash advance on the same day", 2)
            // } else {
                message.destroy()
                message.success('Cash advance submitted', 1)
                setSaving(false)
                setTimeout(() => {
                    history.push('/cash-advance');
                }, 1000);
            // }
        }).catch(() => {
            message.destroy()
            message.error('Oops, something went wrong', 2)
            setSaving(false)
        })
    }
    return (
        <Card className="content-container">
            <PageTitle title="Cash Advance" breadcrumbs={[['Cash Advance', '/cash-advance'], ['Create']]}></PageTitle>
            <RequestForm onSubmit={(v) => submitCashAdvance(v)} saving={saving} />
        </Card>
    );
}

export default Create;
