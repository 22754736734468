import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, Form, Input, Typography, Space, message, InputNumber} from 'antd'
import {EditButton, DeleteButton, FormModal, AddButton, DataTable} from '../../../../components'
import {SentralModel} from '../../../../models/SentralModel'

const {Text} = Typography
const Index = (props) => {
    const {resignId} = useParams();
    const [fileForm] = Form.useForm()
    const [title, setTitle]= useState(null)
    const [formModal, setFormModal] = useState(false)
    const [saving, setSaving] = useState(false)

    const dataSource = [
        ...props.data.map( el => {
            return {
                id: el.sub_resign_file_id,
                sub_resign_id: el.sub_resign_id,
                file: el.file,
                qty: el.qty,
                directory: el.directory,
                receiver: el.receiver,
            }
        })
    ];

    const dataColumn = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
            width: '10%'
        },
        {
            title: 'File',
            dataIndex: 'file',
            key: 'file',
        },
        {
            title: 'Quantity',
            dataIndex: 'qty',
            key: 'qty',
        },
        {
            title: 'Directory',
            dataIndex: 'directory',
            key: 'directory',
        },
        {
            title: 'Receiver',
            dataIndex: 'receiver',
            key: 'receiver',
        },
        {
            title: 'Action',
            width:120,
            render:(row) =>(
                <div className="text-center">
                    <Space>
                        <EditButton onEdit={() => editData(row)} />
                        <DeleteButton onConfirm={() => deleteData(row.id)} />
                    </Space>
                </div>
            )
        }
    ];

    const addData = () => {
        setTitle('Add File')
        fileForm.setFieldsValue({
            sub_resign_file_id: 0,
            sub_resign_id: resignId,
            file: '',
            qty: '',
            directory: '',
            receiver: '',
        })
        setFormModal(true)
    }

    const editData = (v) => {
        setTitle('Edit File')
        fileForm.setFieldsValue({
            sub_resign_file_id: v.id,
            sub_resign_id: resignId,
            file: v.file,
            qty: v.qty,
            directory: v.directory,
            receiver: v.receiver,
        })
        setFormModal(true)
    }

    const saveData = (v) => {
        setSaving(true)
        let payload = {
            sub_resign_id: v.sub_resign_id,
            file: v.file,
            qty: v.qty,
            directory: v.directory,
            receiver: v.receiver,
        }
        SentralModel.save('SubmissionResignFile', payload, v.sub_resign_file_id).then((res) => {
            setFormModal(false)
            props.onFetch();
            message.success('File saved', 2);
        }).finally(() => {
            setSaving(false)
        })
    }

    const deleteData = (id) =>{
        message.loading('Deleting file')
        SentralModel.deleteById('SubmissionResignFile', id).then((res) => {
            message.destroy();
            message.success('File deleted', 2);
            props.onFetch();
        })
    }

    return (
        <div>
            <Card title={
                <div className="full-width">
                    <Text strong style={{float:'left'}}>File List</Text>
                    <AddButton right onAdd={addData} title="New File"/>
                </div> }>

                <DataTable size="small" columns={dataColumn} dataSource={dataSource} bordered/>
            </Card>

            {
                (formModal) ?
                <FormModal form={fileForm} title={title} submitForm={(v)=>saveData(v)} width={400} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                    <Form.Item name="sub_resign_file_id" hidden>
                        <Input placeholder="File ID" />
                    </Form.Item>
                    <Form.Item name="sub_resign_id" hidden>
                        <Input placeholder="Resign ID" />
                    </Form.Item>
                    <Form.Item label="File" name="file" rules={[{required:true}]}>
                        <Input placeholder="File" />
                    </Form.Item>
                    <Form.Item label="Quantity" name="qty" rules={[{required:true}]}>
                        <InputNumber placeholder="Quantity" style={{width: '100%'}}/>
                    </Form.Item>
                    <Form.Item label="Directory" name="directory" rules={[{required:true}]}>
                        <Input placeholder="Directory" />
                    </Form.Item>
                    <Form.Item label="Receiver" name="receiver" rules={[{required:true}]}>
                        <Input placeholder="Receiver" />
                    </Form.Item>
                </FormModal>
                : null
            }
        </div>
    );
}

export default Index;
