import React from 'react';
import {Card, Tabs} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import {DataTable, ShowButton} from '../../../components'
import {toFullDate} from '../../../utils/dateHandler'
import moment from 'moment'

const {TabPane} = Tabs;

const Index = (props) => {
    const trainingColumn = [{
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'name',
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name'
        },{
            title: 'Judul Training',
            dataIndex: 'title',
            key: 'title',
        },{
            title: 'Tanggal Training',
            render : (row) =>(
                <div className="text-center">
                    {toFullDate(row.training_date)}, <span>{moment(row.start_time, 'HH:mm:ss').format('HH:mm')} - {moment(row.end_time, 'HH:mm:ss').format('HH:mm')}</span>
                </div>
            )
        },{
            title:'Action',
            width:'10%',
            render:(row) => (
                <div className="text-center">
                    <ShowButton link to={'/submission-list/training/'+row.id} />
                </div>
            )
        }
    ];

    const trainingData = [
        ...props.data.map((el) => {
            return{
                id:el.request_id,
                name:el.name,
                title: el.title,
                training_date: el.training_date,
                start_time: el.start_time,
                end_time: el.end_time,
                status: el.status,
                submitted_at: el.submitted_at
            }
        })
    ]

    return (
        <Card>
            <Tabs defaultActiveKey="waiting">
                <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                    <DataTable size="small" columns={trainingColumn} dataSource={trainingData.filter(el => el.status==='WAITING')} bordered/>
                </TabPane>
                <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                    <DataTable size="small" columns={trainingColumn} dataSource={trainingData.filter(el => el.status==='APPROVED')} bordered/>
                </TabPane>
                <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                    <DataTable size="small" columns={trainingColumn} dataSource={trainingData.filter(el => el.status==='REJECTED')} bordered/>
                </TabPane>
            </Tabs>
        </Card>
    );
}

export default Index;
