import React, {useState} from 'react';
import {Card, message} from 'antd';
import {PageTitle} from '../../../components'
import RealizationForm from './RealizationForm'
import {SentralModel} from '../../../models/SentralModel'
import {useHistory, useParams} from 'react-router-dom'

const CreateRealization = () => {
    const history = useHistory()
    const {cashId} = useParams()
    const [saving, setSaving] = useState()

    const submitCashAdvance = (v) => {
        setSaving(true)
        SentralModel.action('Submission', 'saveCashAdvanceRealization', v, cashId).then((res) => {
            message.destroy()
            message.success('Cash advance realization submitted', 1)
            setSaving(false)
            setTimeout(() => {
                history.push('/cash-advance');
            }, 1000);
        }).catch((err) => {
            message.destroy()
            message.error('Oops, something went wrong', 2)
            setSaving(false)
        })
    }
    
    return (
        <Card className="content-container">
            <PageTitle title="Cash Advance Realization" breadcrumbs={[['Cash Advance', '/cash-advance'], ['Realization']]}></PageTitle>
            <RealizationForm onSubmit={(v) => submitCashAdvance(v)} saving={saving}/>
        </Card>
    );
}

export default CreateRealization;
