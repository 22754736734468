import { useState, useEffect } from 'react'
import { Button, Card, Col, Row, Space, Tag, Typography } from 'antd'
import { DataTable, EditButton, NewButton, PageTitle, ShowButton } from '../../../components'
import { useHistory } from 'react-router-dom'
import { SentralModel } from '../../../models/SentralModel'
import moment from 'moment'

const { Text } = Typography

const Index = () => {
    const history = useHistory()
    const [loaded, setLoaded] = useState(false)
    const [incidentData, setIncidentData] = useState([])

    const fetchIncidentReport = () => {
        setLoaded(false)
        SentralModel.action("ItInfra", "getIncidentReport", null, null).then((res) => {
            setIncidentData(res.data)
            setLoaded(true)
        })
    }

    useEffect(() => {
        fetchIncidentReport()

    }, [])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            align: 'center',
            key: 'name',
            width: '5%'
        },
        {
            title: 'Incident Date',
            render: (row) => (
                moment(row.incident_date).format("DD MMMM YYYY")
            ),
            key: 'incident_date',
            align: 'center',
            width: '15%'
        },
        {
            title: 'Incident Time',
            render: (row) => (
                moment(row.incident_time, "hh:mm").format("hh:mm")
            ),
            key: 'incident_time',
            align: 'center',
            width: '10%'
        },
        {
            title: 'Incident Description',
            dataIndex: 'incident_description',
            key: 'incident_description',
        },
        {
            title: 'Status',
            render: (row) => (
                row.pic_status === "WAITING" || row.pm_status === "WAITING" ?
                    <Tag color='blue'>WAITING</Tag> :
                    <Tag color='green'>APPROVE</Tag>
            ),
            key: 'status',
            align: 'center',
            width: '10%'
        },
        {
            title: 'Action',
            render: (row) => (
                <Space>
                    <ShowButton onShow={() => history.push(`/it-infra/incident-report/detail/${row.report_incident_id}`)} />
                    <EditButton onEdit={() => history.push(`/it-infra/incident-report/create/${row.report_incident_id}`)} />
                </Space>
            ),
            align: 'center',
            width: '10%'
        }
    ]

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Incident Report" breadcrumbs={[['Incident Report']]}></PageTitle>
                <Card title={
                    <Row justify='space-between'>
                        <Col span={11}>
                            <Text strong style={{ float: 'left' }}>Incident Report</Text>
                        </Col>
                        <Col>
                            <Space>
                                <NewButton right to="/it-infra/incident-report/create/0" title="Create Incident Report" />
                                <Button type='primary' onClick={() => history.push("/it-infra/incident-report/approval")}>
                                    Set Approval
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                }>
                    {
                        loaded &&
                        <DataTable size="small" columns={columns} dataSource={incidentData} bordered />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default Index