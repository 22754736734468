import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Tabs, Typography, Descriptions, Space} from 'antd'
import {FieldTimeOutlined,CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { PageTitle, NewButton, DataTable, Spinner, ShowButton} from '../../../components'
import authData from '../../../helpers/authData'
import {SentralModel} from '../../../models/SentralModel'
import {toFullDate} from '../../../utils/dateHandler'
// import moment from 'moment'

const {Text} = Typography
const {TabPane} = Tabs;

const Index = () => {
    const [reports, setReports] = useState(null)
    const [reportInfo, setReportInfo] = useState(null)
    const [instruction, setInstruction] = useState('')
    const [available, setAvailable] = useState(false)

    const fetchMonthlyReport = () => {
        let payload = {
            filter: [["employee_code", authData.employee_code]]
        }
        SentralModel.list('SubmissionMonthlyReport', payload).then(res => {
            setReports(res.data)
        })
    }

    const fetchMonthlyReportInfo = () => {
        SentralModel.action('Submission', 'getMonthlyReport', null, null).then((res) => {
            setReportInfo(res.data[0])
        })
    }

    const fetchInstruction = () => {
        SentralModel.list('Instruction', {filter:[['code','SBMR']]}, null).then((res) => {
            setInstruction(res.data.length ? res.data[0].content : '')
        })
    }

    const checkApprovalSchema = () => {
        SentralModel.action('Submission', 'checkSchema', {category: 'MONTHLYREPORT', division_id: authData.division_id}).then((res) => {
            setAvailable(res.message)
        })
    }

    useEffect(() => {
        fetchMonthlyReport()
        fetchMonthlyReportInfo()
        fetchInstruction()
        checkApprovalSchema()
    }, []);
    
    const listData = (reports) ? [
        ...reports.map(el => {
            return {
                id: el.sub_monthly_report_id,
                title: el.title,
                type: el.report_type.description,
                submit_date: toFullDate(el.submit_date),
                description: el.description,
                status: el.status,
                approver: el.approver,
                created_at: el.created_at
            }
        })
    ] : [];

    const rptColumns = [
        {
            title: 'No',
            dataIndex: 'no',
            align:'center',
            key: 'no',
        },{
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },{
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },{
            title: 'Submit Date',
            dataIndex: 'submit_date',
            key: 'submit_date'
        },{
            title: 'Action',
            align:'center',
            render : (row) => (
                <Space>
                    <ShowButton link to={'/monthly-report/' + row.id} />
                </Space>
            )
        }
    ];

    return (
        <div>
            <Card className="content-container">
                <PageTitle title="Monthly Report" breadcrumbs={[['Monthly Report']]}></PageTitle>
                <Card title={
                    <div className="full-width">
                        <Text strong style={{float:'left'}}>Request Monthly Report</Text>
                        <NewButton disabled={!available} right to="/monthly-report/create" title={(available) ? "Request Monthly Report" : 'Approval not available'}/>
                    </div>
                }>
                    <Row justify="space-between">
                        <Col span={4}>
                            <Descriptions size="middle" column={1} bordered layout="vertical">
                                <Descriptions.Item label={<Text strong>Report This Month</Text>}>
                                    {
                                        (reportInfo) ? reportInfo.total_submission: '0'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={19} className="instruction-box">
                            <div dangerouslySetInnerHTML={{ __html: instruction }}></div>
                        </Col>
                    </Row>
                </Card>
                <br/>
                {
                    (reports) ? 
                        <Tabs defaultActiveKey="waiting" type="card">
                            <TabPane tab={<span><FieldTimeOutlined style={{color:'#4ca3ff'}}/> Waiting Approval</span>} key="waiting">
                                <DataTable size="small" columns={rptColumns} dataSource={listData.filter(el => el.status === 'WAITING')} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CheckCircleOutlined style={{color:'green'}}/> Approved</span>} key="approved">
                                <DataTable size="small" columns={rptColumns} dataSource={listData.filter(el => el.status === 'APPROVED')} bordered/>
                            </TabPane>
                            <TabPane tab={<span><CloseCircleOutlined style={{color:'red'}}/> Rejected</span>} key="rejected">
                                <DataTable size="small" columns={rptColumns} dataSource={listData.filter(el => el.status === 'REJECTED')} bordered/>
                            </TabPane>
                        </Tabs> : 
                        <Card>
                            <Spinner/>
                        </Card>
                }
            </Card>
        </div>
    );
}

export default Index;
