import { useEffect, useState } from 'react';
import { Row, Col, Select, Form, Input, TimePicker, DatePicker, message, Space, Button, Divider, Spin } from 'antd'
import { DeleteOutlined, CheckCircleTwoTone, ReloadOutlined, LoadingOutlined, CloseCircleTwoTone, EnvironmentTwoTone } from '@ant-design/icons'
import { EditButton, Spinner, DataTable, FormModal, DeleteButton, BasicModal } from '../../../components'
import { SentralModel } from '../../../models/SentralModel'
import { useDispatch, useSelector } from 'react-redux';
import authData from '../../../helpers/authData';
import dayjs from 'dayjs';
import {
    changeMeasurement,
    changeModalCompany,
    changeModalWorkingLocation,
    deleteWorkingLocation,
    fetchCompany,
    fetchMeasurement,
    fetchWorkingLocationByDate,
    saveCompany,
    saveWorkingLocation,
    showAddWorkingLocation,
    showModalCompany
} from '../../../redux/slices/Dashboard/workingLocationSlice';

const { Option } = Select;
const { RangePicker } = TimePicker

const Attendance = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [loaded, setLoaded] = useState(true);
    const [periods, setPeriods] = useState([]);
    const [stagings, setStagings] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [formModal, setFormModal] = useState(false);
    const [defaultValues, setDefaultValues] = useState({ job_id: 0, finished: false });
    const [saving, setSaving] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [limitDays, setLimitDays] = useState(0)
    const [loadedSync, setLoadedSync] = useState(true)
    const listWorkingLocationByDate = useSelector((state) => state.workingLocation.listWorkingLocationByDate)
    const companies = useSelector((state) => state.workingLocation.companies)
    const measurements = useSelector((state) => state.workingLocation.measurements)
    const measurement = useSelector((state) => state.workingLocation.measurement)
    const modalAddWorkingLocation = useSelector((state) => state.workingLocation.modalAddWorkingLocation)
    const modalWorkingLocationTitle = useSelector((state) => state.workingLocation.modalWorkingLocationTitle)
    const defaultValuesWorking = useSelector((state) => state.workingLocation.defaultValues)
    const modalCompany = useSelector((state) => state.workingLocation.modalCompany)
    const date = useSelector((state) => state.workingLocation.date)
    const itDivision = [13, 24, 25, 33, 34, 36]

    useEffect(() => {
        dispatch(fetchCompany())
        dispatch(fetchMeasurement())

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchWorkingLocation = (v) => {
        let payload = {
            employee_code: authData.employee_code,
            date: v
        }

        dispatch(fetchWorkingLocationByDate(payload))
    }

    const addCompany = (v) => {
        let payload = {
            company_name: v.company_name,
            address: v.address,
            email: v.email,
            phone_no: v.phone_no,
            phone_no_2: v.phone_no_2
        }
        dispatch(saveCompany(payload)).then(() => {
            form.resetFields()
            dispatch(fetchCompany())
        })
    }

    const onDelete = (v) => {
        dispatch(deleteWorkingLocation(v.working_location_id))
    }

    const submitWorkingLocation = (v) => {
        let payload = {
            ...v,
            working_location_id: 0,
            date: date,
            time_start: dayjs(v.time[0]).format("HH:mm"),
            time_end: dayjs(v.time[1]).format("HH:mm")
        }
        dispatch(saveWorkingLocation(payload)).then(() => {
            fetchWorkingLocation(date)
            if (selectedPeriod) {
                getLog(selectedPeriod);
            }
        })
    }

    const fetchPeriods = (year) => {
        setLoaded(false)
        SentralModel.list('PayrollPeriod', { filter: [['year', year]] }, { orderBy: 'from_date', order: 'desc' }).then(res => {
            setLoaded(true)
            setPeriods(res.data)
        })
    }

    const fetchSettingData = () => {
        SentralModel.action("ManualAttendanceSetting", "getOne", null, null).then((res) => {
            if (res.data === null) {
                setLimitDays(0)
            } else {
                setLimitDays(res?.data?.limit_days)
            }
        })
    }

    const onChangeYear = (v) => {
        fetchSettingData()
        let year = dayjs(v).format('YYYY');
        setPeriods([])
        fetchPeriods(year)
    }

    const changePeriod = (value) => {
        setLoaded(false);
        SentralModel.action('Attendance', 'logAttendance', { attendance_id: authData.attendance_id, division_id: authData.division_id, period_id: value }, 0).then(res => {
            setLoaded(true)
            setSelectedPeriod(value);
            getLog(value);
        })
    }

    const getLog = (value) => {
        setLoaded(false)
        SentralModel.action('Attendance', 'getLog', { attendance_id: authData.attendance_id, period_id: value }).then(res => {
            setStagings(res.data)
            setLoaded(true)
        })
    }

    const disabledDate = (current) => {
        let currentMonth = dayjs(Date.now()).format('MM');
        let addYear = 1;

        if (currentMonth === '12') {
            addYear = 2;
        }

        let customDate = dayjs(new Date()).add(addYear, 'y').format('YYYY');
        return current && current > dayjs(customDate, "YYYY");
    };

    const dataSource = [
        ...stagings.map(el => {
            return {
                attendance_id: el.attendance_id,
                date: el.date,
                in_time: el.in_time,
                out_time: el.out_time,
                late_time: el.late_time,
                effective_time: el.effective_time,
                in_description: el.in_description,
                out_description: el.out_description,
                description: el.description,
                status: el.status,
                in_verified: el.in_verified,
                out_verified: el.out_verified,
                is_verified: el.is_verified,
                remark_verified: el.remark_verified

            }
        })
    ];

    const columns = [
        {
            title: 'Date',
            align: 'center',
            render: (row) => (
                <div className="text-center">
                    {dayjs(row.date).format('DD MMM YYYY')}
                </div>
            )
        },
        {
            title: 'In',
            align: 'center',
            render: (row) => {
                let backgroundText;
                if (row.description === 'In') {
                    backgroundText = '#2efe64';
                } else if (row.description === 'Alpha') {
                    backgroundText = '#000000';
                } else if (row.description === 'WFH') {
                    backgroundText = '#000000';
                } else if (row.description === 'Leave') {
                    backgroundText = '#e74c3c'
                } else {
                    backgroundText = '#bdbdbd';
                }

                let inTime;
                if (row.description !== 'In') {
                    inTime = '';
                } else {
                    inTime = row.in_time;
                }

                return {
                    props: {
                        style: { background: backgroundText }
                    },
                    children: <span>{inTime}</span>
                }
            }
        },
        {
            title: 'Out',
            align: 'center',
            render: (row) => {
                let backgroundText;
                if (row.description === 'In') {
                    backgroundText = '#2efe64';
                } else if (row.description === 'Alpha') {
                    backgroundText = '#000000';
                } else if (row.description === 'WFH') {
                    backgroundText = '#000000';
                } else if (row.description === 'Leave') {
                    backgroundText = '#e74c3c'
                } else {
                    backgroundText = '#bdbdbd';
                }

                let colorText;
                if (row.description === 'Alpha') {
                    colorText = '#bf0900';
                } else if (row.description === 'WFH') {
                    colorText = '#ffffff'
                } else if (row.description === 'Leave') {
                    colorText = '#060606'
                } else {
                    colorText = '#000';
                }

                let outTime;
                if (row.description === 'In' && row.out_time === '00:00:00') {
                    outTime = 'Not Absent';
                } else if (row.description === 'In') {
                    outTime = row.out_time;
                } else if (row.description === 'WFH') {
                    outTime = 'WFH';
                } else if (row.description === 'Alpha') {
                    outTime = 'Not Absent';
                } else if (row.description === 'Leave') {
                    outTime = row.status;
                } else {
                    outTime = '';
                }

                return {
                    props: {
                        style: { background: backgroundText, color: colorText }
                    },
                    children: <span>{outTime}</span>
                }
            }
        },
        {
            title: 'Late',
            align: 'center',
            render: (row) => {
                let backgroundText;
                if (row.description === 'In') {
                    row.late_time === '00:00:00' ? backgroundText = '#2efe64' : backgroundText = '#f78181';
                } else if (row.description === 'Alpha') {
                    backgroundText = '#000000';
                } else if (row.description === 'WFH') {
                    backgroundText = '#000000';
                } else if (row.description === 'Leave') {
                    backgroundText = '#e74c3c'
                } else {
                    backgroundText = '#bdbdbd';
                }

                let lateTime;
                if (row.description !== 'In') {
                    lateTime = '';
                } else {
                    lateTime = row.late_time;
                }

                return {
                    props: {
                        style: { background: backgroundText }
                    },
                    children: <span>{lateTime === '00:00:00' ? '' : lateTime}</span>
                }
            }
        },
        {
            title: 'Effective',
            align: 'center',
            render: (row) => {
                let effectiveTime;
                if (row.description !== 'In') {
                    effectiveTime = '';
                } else {
                    effectiveTime = row.effective_time;
                }

                return (
                    <div className="text-center">
                        {effectiveTime}
                    </div>
                )
            }
        },
        {
            title: 'Source In',
            align: 'center',
            render: (row) => {
                let remarksIn;
                row.description === 'Weekend' ? remarksIn = null : remarksIn = row.in_description;

                return (
                    <div className="text-center">
                        {remarksIn}
                    </div>
                )
            }
        },
        {
            title: 'Source Out',
            align: 'center',
            render: (row) => {
                let remarksOut;
                row.description === 'Weekend' ? remarksOut = null : remarksOut = row.out_description;

                return (
                    <div className="text-center">
                        {remarksOut}
                    </div>
                )
            }
        },
        {
            title: 'Description',
            align: 'center',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            align: 'center',
            render: (row) => (
                <Space>
                    {
                        (authData.division_id === 18) ?
                            <div className="text-center">
                                <EditButton size="small" onEdit={() => editAttendance(row.attendance_id, row.date)} />
                            </div>
                            :
                            // itDivision.includes(authData.division_id) ?
                            //     new Date(dayjs(row.date).format("YYYY-MM-DD")).getTime() === new Date(dayjs(Date.now()).format("YYYY-MM-DD")).getTime() ?
                            //         <Space>
                            //             <EditButton size="small" onEdit={() => editAttendance(row.attendance_id, row.date)} />
                            //         </Space>
                            //         : null
                            //     :
                            dayjs(row.date, 'YYYY-MM-DD') < dayjs(Date.now()).subtract(limitDays === 0 ? 7 : limitDays, 'days') || authData.employee_code === 'SKI20220627018' || authData.employee_code === 'SKI20220609017' ?
                                <div className="text-center"></div>
                                :
                                <div className="text-center">
                                    <EditButton size="small" onEdit={() => editAttendance(row.attendance_id, row.date)} />
                                </div>
                    }
                    {
                        itDivision.includes(authData.division_id) || authData.employee_code === "SMI20200803205" ?
                            row?.description === "In" || row?.description === 'Alpha' ?
                                <Button type='link' onClick={() => onShowModalWorkingLocation(row?.date)} icon={<EnvironmentTwoTone style={{ fontSize: 14 }} />} /> : null
                            : null
                    }
                </Space>
            )
        },
    ];

    if (itDivision.includes(authData.division_id)) {
        columns.splice(4, 0, {
            title: 'Verified',
            align: 'center',
            children: [
                {
                    title: 'In',
                    key: 'in',
                    align: 'center',
                    render: (row) => (
                        row?.description === 'Weekend' || row?.description === 'Holiday' ?
                            null :
                            row?.in_verified === 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#ef0404" />
                    )
                },
                {
                    title: 'Out',
                    key: 'in',
                    align: 'center',
                    render: (row) => (
                        row?.description === 'Weekend' || row?.description === 'Holiday' ?
                            null :
                            row?.out_verified === 1 ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#ef0404" />
                    )
                },
            ]
        },)
    }

    const columnLocations = [
        {
            title: "Company Name",
            key: "company_name",
            render: (row) => (
                row?.company?.company_name
            ),
            align: "center"
        },
        {
            title: "Time",
            key: "time",
            render: (row) => (
                `${row.time_start} - ${row.time_end}`
            )
        },
        {
            title: "Action",
            key: "action",
            render: (row) => (
                row.index === 0 || dayjs(Date.now()).format("HH:mm") > "09:00" ?
                    <Space>
                        <Button disabled type='link' icon={<DeleteOutlined />} />
                    </Space>
                    :
                    <Space>
                        <DeleteButton onConfirm={() => onDelete(row)} />
                    </Space>
            ),
            align: "center",
        }
    ];

    const editAttendance = (attendance_id, date) => {
        setModalTitle('Manual Attendance')
        setDefaultValues({
            emp_attendance_id: 0,
            attendance_id: attendance_id,
            attendance_date: date
        })
        setFormModal(true)
    }

    const saveAttendance = (v) => {
        setSaving(true)
        let payload = {
            attendance_id: v.attendance_id,
            source: 'Manual',
            attendance_date: v.attendance_date,
            attendance_time: dayjs(v.time).format('HH:mm:ss'),
            information: v.type,
            remarks: v.remarks,
            latitude: 0,
            longitude: 0,
        }
        SentralModel.action('Area', 'takeAbsenceManual', payload, 0).then(() => {
            setFormModal(false)
            setLoaded(false)
            if (selectedPeriod) {
                getLog(selectedPeriod);
            }
            setSaving(false)
            message.success('Absence has been updated', 2);
        })
    }

    const onShowModalWorkingLocation = (v) => {
        dispatch(showAddWorkingLocation(v))
        fetchWorkingLocation(v)
    }

    const syncAttendance = () => {
        setLoadedSync(false)
        SentralModel.action("Sync", "syncEmployeeMachine", { period_id: selectedPeriod }, 0)
            .then((res) => {
                message.success("Success Sync")
                setLoadedSync(true)
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }).catch((err) => {
                setLoadedSync(true)
                message.warning("Failed Sync")
            })
    }


    return (
        <div style={{ maxHeight: 600, overflowY: "auto" }}>
            <Row justify="space-between" className="mb-3">
                <Col>
                    {
                        selectedPeriod === null ?
                            <Button type='primary' disabled icon={<ReloadOutlined />}>
                                Sync Attendance
                            </Button> :
                            <Button disabled={!loadedSync ? true : false} type='primary' onClick={() => syncAttendance()} icon={loadedSync ? <ReloadOutlined /> :
                                <Spin size="small" indicator={
                                    <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
                            } >Sync Attendance</Button>
                    }
                </Col>
                <Col span={18}>
                    <span style={{ float: 'right' }}>
                        <Select placeholder="Select Period" style={{ width: 200, marginRight: 10 }} onChange={changePeriod}>
                            {
                                periods.map((el, key) => (
                                    <Option key={key} value={el.payroll_period_id}>{el.period}</Option>
                                ))
                            }
                        </Select>
                    </span>
                    <span style={{ float: 'right', marginRight: '20px' }}>
                        <DatePicker picker="year" onChange={onChangeYear} disabledDate={disabledDate} placeholder="Select Year" />
                    </span>
                </Col>
            </Row>

            {
                loaded ?
                    <DataTable columns={columns} dataSource={dataSource} bordered={false} search={false} size="small" />
                    : <Spinner />
            }
            {
                (formModal) ?
                    <FormModal defaultValues={defaultValues} title={modalTitle} submitForm={(v) => saveAttendance(v)} width={500} showModal={formModal} onCloseModal={() => setFormModal(false)} saving={saving}>
                        <Form.Item name="emp_attendance_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item name="attendance_id" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item name="attendance_date" hidden>
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                            <Select placeholder="Select Type">
                                {/* {
                                    itDivision.includes(authData.division_id) ?
                                        dayjs(Date.now()).format("HH:mm") < "09:00" ?
                                            <Option key={'In'} value={'In'}>Clock In</Option>
                                            :
                                            null
                                        :
                                        <Option key={'In'} value={'In'}>Clock In</Option>
                                } */}
                                <Option key={'In'} value={'In'}>Clock In</Option>
                                <Option key={'Out'} value={'Out'}>Clock Out</Option>
                            </Select >
                        </Form.Item >
                        <Form.Item label="Remarks" name="remarks" rules={[{ required: true }]}>
                            <Input placeholder="Remarks" />
                        </Form.Item>
                        <Form.Item label="Time" name="time" rules={[{ required: true }]}>
                            <TimePicker format="HH:mm" style={{ width: '100%' }} placeholder="Select Time" />
                        </Form.Item>
                    </FormModal > :
                    null
            }

            {
                modalAddWorkingLocation ?
                    <BasicModal title={modalWorkingLocationTitle} width={800} showModal={modalAddWorkingLocation} onCloseModal={() => dispatch(changeModalWorkingLocation(false))}>
                        <div style={{ marginBottom: 10 }}>
                            <DataTable columns={columnLocations} dataSource={listWorkingLocationByDate} pagination={false} search={false} size="small" />
                        </div>
                        <Divider />
                        <Form layout='vertical' initialValues={defaultValuesWorking} onFinish={submitWorkingLocation}>
                            <Form.Item name="working_location_id" hidden>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Job Category" name="measurement_id" required rules={[{ required: true }]}>
                                <Select placeholder="Select category" style={{ width: "100%" }} onChange={(v) => dispatch(changeMeasurement(v))}>
                                    {
                                        measurements.map((el, key) => (
                                            <Option key={key} value={el.measurement_id}>{el.title}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            {
                                measurement === 1 || measurement === 6 ?
                                    <Row justify='space-between'>
                                        <Col span={17}>
                                            <Form.Item label="Company" name="company_id" required rules={[{ required: true }]}>
                                                <Select showSearch allowClear placeholder="Select company" style={{ width: "100%" }} optionFilterProp="children" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                    {
                                                        companies.map((el, key) => (
                                                            <Option key={key} value={el.company_id}>{el.company_name}</Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <div style={{ marginTop: 30 }}>
                                                <Button type='primary' onClick={() => dispatch(showModalCompany())}>Add Company</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    : null
                            }
                            <Form.Item label="Description" name="description" >
                                <Input.TextArea placeholder='Description' rows={4} />
                            </Form.Item>
                            <Form.Item label="Time" name="time" required rules={[{ required: true }]}>
                                <RangePicker format="HH:mm" style={{ width: '100%' }} />
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary' htmlType='submit'>
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </BasicModal>
                    : null
            }

            {
                modalCompany ?
                    <FormModal form={form} title="Add Company" submitForm={(v) => addCompany(v)} width={600} showModal={modalCompany} onCloseModal={() => dispatch(changeModalCompany(false))} >
                        <Form.Item label="Company Name" name="company_name" required rules={[{ required: true }]}>
                            <Input placeholder='Company Name' />
                        </Form.Item>
                        <Form.Item label="Company Email" name="email" required rules={[{ required: true }]}>
                            <Input type='email' placeholder='Company Email' />
                        </Form.Item>
                        <Row justify='space-between'>
                            <Col span={11}>
                                <Form.Item label="Phone 1" name="phone_no">
                                    <Input placeholder='Phone 1' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Phone 2" name="phone_no_2">
                                    <Input placeholder='Phone 2' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item label="Address" name="address" required rules={[{ required: true }]}>
                            <Input placeholder='Address' />
                        </Form.Item>
                    </FormModal>
                    : null
            }
        </div >
    );
}

export default Attendance
